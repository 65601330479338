<br>
<div class="container">
    <div class="text-center">
        <div class="nav price-tabs" role="tablist">
            <a class="nav-link" (click)="selectTab('monthly')" [class.active]="activeTab === 'monthly'" style="cursor: pointer;">{{'Monthly' | translate}}</a>
            <a class="nav-link" (click)="selectTab('yearly')" [class.active]="activeTab === 'yearly'" style="cursor: pointer;">{{'Yearly' | translate}}</a>
        </div>
    </div>
    <div class="tab-content wow fadeIn" style="visibility: visible; animation-name: fadeIn;" [ngSwitch]="activeTab">
        <div  id="yearly" *ngSwitchCase="'yearly'">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 mb-30">
                    <div class="price-item text-center">
                        <div class="price-top">
                            <h4>{{'Free' | translate}}</h4>
                            <h2 class="mb-0">0<sup>₺</sup></h2>
                            <span class="text-capitalize"></span>
                        </div>
                        <div class="price-content">
                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left" style="min-height: 337px;">
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Find Station' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Operator and National Tariffs' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Route Planning' | translate}}</span>
                                </li>
                            
                            </ul>
                            <a routerLink="/register" class="btn btn-custom">{{'Lets Start' | translate}} </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-30">
                    <div class="price-item text-center popular">
                        <div class="price-top">
                            <h4>Premium</h4>
                            <h2 class="mb-0">990<sup>₺</sup></h2>
                            <span class="text-capitalize">{{'Yearly' | translate}}</span>
                        </div>
                        <div class="price-content">
                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left" style="min-height: 313px;">
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Find Station' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Operator and National Tariffs' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Route Planning' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Total Cost Account' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'AI Based Personal Plan' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Wallet Discount % X' | translate}}</span>
                                </li>
                           
                            </ul>
                            <a routerLink="/register" class="btn btn-custom btn-light">{{'Buy Now' | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-30">
                    <div class="price-item text-center">
                        <div class="price-top">
                            <h4>Pro</h4>
                            <!-- <h2 class="mb-0"><sup>$</sup>399</h2>
                            <span class="text-capitalize">per year</span> -->
                        </div>
                        <div class="price-content">
                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Find Station' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Operator and National Tariffs' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Route Planning' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Total Cost Account' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'AI Based Personal Plan' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Wallet Discount % X' | translate}}</span>
                                </li>

                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Energy Supply' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Fleet Plan' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'YEK-G Integration' | translate}}</span>
                                </li>
                              
                            </ul>
                            <a routerLink="/iletisim" class="btn btn-custom">{{'Get in Touch' | translate}}</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  id="monthly" *ngSwitchCase="'monthly'">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 mb-30">
                    <div class="price-item text-center">
                        <div class="price-top">
                            <h4>{{'Free' | translate}}</h4>
                            <h2 class="mb-0">0<sup>₺</sup></h2>
                            <span class="text-capitalize"></span>
                        </div>
                        <div class="price-content">
                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left" style="min-height: 337px;">
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Find Station' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Operator and National Tariffs' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Route Planning' | translate}}</span>
                                </li>
                            
                            </ul>
                            <a routerLink="/register" class="btn btn-custom">{{'Lets Start' | translate}} </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-30">
                    <div class="price-item text-center popular">
                        <div class="price-top">
                            <h4>Premium</h4>
                            <h2 class="mb-0">99<sup>₺</sup></h2>
                            <span class="text-capitalize">aylık</span>
                        </div>
                        <div class="price-content">
                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left" style="min-height: 313px;">
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Find Station' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Operator and National Tariffs' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Route Planning' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Total Cost Account' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'AI Based Personal Plan' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Wallet Discount % X' | translate}}</span>
                                </li>
                            </ul>
                            <a routerLink="/register" class="btn btn-custom btn-light">{{'Buy Now' | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-30">
                    <div class="price-item text-center">
                        <div class="price-top">
                            <h4>Pro</h4>
                            <!-- <h2 class="mb-0"><sup>$</sup>99</h2>
                            <span class="text-capitalize">aylık</span> -->
                        </div>
                        <div class="price-content">
                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Find Station' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Operator and National Tariffs' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Route Planning' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Total Cost Account' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'AI Based Personal Plan' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Wallet Discount % X' | translate}}</span>
                                </li>

                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Energy Supply' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'Fleet Plan' | translate}}</span>
                                </li>
                                <li>
                                    <i class="zmdi zmdi-check mr-2"></i>
                                    <span class="c-black">{{'YEK-G Integration' | translate}}</span>
                                </li>
                           
                            </ul>
                            <a routerLink="/iletisim" class="btn btn-custom">{{'Get in Touch' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>