<section id="loginSection">

    <div style="
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;">
        <div id="formBox">
            <div id="log-form-box">

                <form action="" style="width: 100%;" [formGroup]="loginForm" (ngSubmit)="login()">

                    <div class="formTitle">
                        <!-- <h2 ><a href="/home"><img src="assets/images/home-01/ss-logo.webp" style="width: 220px; height: 55px;"></a></h2> -->
                        <span> {{'Welcome To Login' | translate}}</span>
                    </div>

                    <div class="formInputBox">
                        <label for="email">{{'Email' | translate}} <span>*</span></label>
                        <input type="email" id="email" name="email" maxlength="50" placeholder="example@mail.com" 
                        formControlName="email" required />
                    </div>

                    <div class="formInputBox">
                        <label for="password">{{'Password' | translate}} <span>*</span></label>
                        <input type="password" id="password" name="Password" maxlength="20" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        formControlName="password" required />
                    </div>

                    <div class="logButtonBox">
                        <button id="logButton" type="submit" [disabled]="loginFormAnimation">
                            <span *ngIf="!loginFormAnimation">{{'Login' | translate}}</span>
                            <div class="lds-ring" *ngIf="loginFormAnimation" ><div></div><div></div><div></div><div></div></div>
                        </button>
                        </div>
                        

                    <div class="formBottom">
                        {{'Not Have Account' | translate}} <span routerLink="/register">{{'Register' | translate}}</span>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <div *ngIf="isLangTr()" id="log-img-box-tr"></div>
    <div *ngIf="!isLangTr()" id="log-img-box-en"></div>
</section>