import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
class Language{
  constructor(public code:string,public flagUrl:string){}
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {
  languages : Language[] = [
    new Language('tr', 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg'),
    new Language('en', 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg'),
  ];


  constructor(
    public router: Router,
    private translate: TranslateService
  ){}

  isAuthenticated():boolean{
    if(localStorage.getItem("token")){
      return true;
    }
    else{
      return false;
    }
  }

  logOut():void{
    
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      this.router.navigateByUrl("/anasayfa");
    
  }

  changeLang(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang', language);
  }

  getSelectedFlagUrl(language: string): string {
    const selectedLanguage = this.languages.find(lang => lang.code === language);
    return selectedLanguage ? selectedLanguage.flagUrl : '';
  }
  getLanguageCode(): string {
    return this.translate.currentLang;
  }

  getBannerHeader(): string{
    return this.router.url.split("/")[1];
  }
}
