import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/environments/environment';
import { Observable, Observer, catchError, throwError } from 'rxjs';
import { HistoryModel } from '../models/history/history-all-model';
@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private http: HttpClient) { }

  
   //apiUrl = 'https://localhost:7071/api/History/';
  apiUrl = APP_CONFIG.apiUrl + 'api/History/';

  getAllHistories() : Observable<HistoryModel[]>{
    return this.http.get<HistoryModel[]>(this.apiUrl+'getHistories');
  }
  getDetailHistroy(id:number):Observable<HistoryModel>{
    return this.http.get<HistoryModel>(this.apiUrl+`getDetailByHistoryId?histroyId=${id}`);
}
}