import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-investor',
  templateUrl: './investor.component.html',
  styleUrls: ['./investor.component.css']
})
export class InvestorComponent implements OnInit {

  pdfDownloaded: boolean = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.downloadPDF();
  }

  downloadPDF(): void {
    const pdfUrl = 'assets/documents/sarjsepeti_yatirimci.pdf';
    const pdfName = 'sarjsepeti_yatirimci.pdf';

    const pdfUrlone = 'assets/documents/sarjsepeti_onepager.pdf';
    const pdfNameone = 'sarjsepeti_onepager.pdf';

    this.http.get(pdfUrl, { responseType: 'blob' }).subscribe((response: Blob) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfName;
      a.click();
      window.URL.revokeObjectURL(url);
      this.pdfDownloaded = true;
    });
    this.http.get(pdfUrlone, { responseType: 'blob' }).subscribe((response: Blob) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfNameone;
      a.click();
      window.URL.revokeObjectURL(url);
      this.pdfDownloaded = true;
    });
  }
}
