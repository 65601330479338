<div class="vh-100 d-flex justify-content-center" >
  <aside class="sidebar"[ngClass]="open ? 'open-sidebar':' '">
    <div class="form-group">
      <div class="menu-toggle-wrap">
        <button class="menu-toggle" (click)="ToggleMenu()" >
          <i class="fa fa-caret-left"  aria-hidden="true"></i>
        </button>
      </div>
      <div *ngIf="data">
          <img *ngIf="!open" [src]="getImageSrc(data.stationName)" alt="">
          <div *ngIf="!open" class="top">
            <div>
              <p class="route-text" *ngIf="!open">{{data.name}} </p>
              <p class="brand">{{data.stationName}}</p>
              <p class="typeofService">{{data.typeOfService}}</p>
            </div>
            <div class="maps">
              <a href="{{data.maps}}" class="goToLocation" target="_blank">
                <i class="fa fa-share" aria-hidden="true"></i>
              </a>
              <p class="button-down">Yol tarifi</p>
            </div>
          </div>
        <hr>
        <div *ngIf="!open" class="information">
          <p><i class="fa fa-location-arrow" aria-hidden="true"></i><span>{{data.address}}</span></p>
          <div class="flex-container">
            <p *ngIf="data.totalStationAC > 0"><i class="fa fa-bolt text-secondary" aria-hidden="true"></i><span>{{data.totalStationAC}}  AC</span></p>
            <p *ngIf="data.totalStationDC > 0"><i class="fa fa-bolt" aria-hidden="true"></i><span>{{data.totalStationDC}}  DC</span></p>
          </div>
        </div>
      </div>
    </div>
      

  </aside>
  <div class="map" [ngClass]="loading ? '':'open-map '">
    <div id="map" >
    </div>      
  </div>    
  <!-- <div class="filter">
    <div id="filter" >
      <button (click)="openPopup()">
        <i class="fa fa-filter" aria-hidden="true"></i>
      </button>
    </div>      
  </div>      -->
</div>


<app-loading></app-loading>
<!-- <app-filter *ngIf="showPopup" (close)="showPopup = false"></app-filter> -->