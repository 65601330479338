import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';

import { LoginGuard } from 'src/app/guards/login.guard';
import { ContactComponent } from './contact.component';

const routes: Routes = [
  {
    path: 'iletisim',
    component: ContactComponent
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule {}
