export class CostManagementResultModel{
totalMinute:string;
totalPrice:string;
electricType:string;
timeUnits:TimeUnit[];
kmOfCharge:string;
kmOfPrice:string;
message:string;
status:boolean;
}

export class TimeUnit{
    name: string;
    startTime:string;
    endTime:string;
    price:string;
}