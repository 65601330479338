import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { BlogCategoryModel, BlogModel } from 'src/app/models/blog/blog-model';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent {

  allBlogs: BlogModel[] = [];
  filteredBlogs : BlogModel[] = [];
  categories : BlogCategoryModel[] = [];
  searchText = '';

  private searchSubject = new Subject<string>();
  private searchObservable$: Observable<string>;

  constructor(private router: Router, private service: BlogService) {

    this.filteredBlogs = this.allBlogs;

    this.searchObservable$ = this.searchSubject.asObservable().pipe(
        debounceTime(2000), // 1 saniye bekleme süresi
        distinctUntilChanged()
    );

    this.searchObservable$.subscribe(searchText => {
        this.searchText = searchText;
        this.filterBlogs();
    });
   }

  ngOnInit(): void {
    this.getBlogList();
    this.getCategoryList();
  }

  getBlogList(): void {
    this.service
      .getAll()
      .subscribe((response: BlogModel[]) => {
        this.allBlogs = response;
        this.allBlogs.forEach(element => {
          element.dateTime=element.dateTime.split('T')[0];
        });
        this.filteredBlogs = this.allBlogs;
        console.log(this.allBlogs);
      });
  }

  getCategoryList():void {
    this.service
      .getCategories()
      .subscribe((response: BlogCategoryModel[]) => {
        this.categories = response;

        console.log(this.allBlogs);
      });
  }

  filterBlogs() {
    this.filteredBlogs = this.allBlogs.filter(blog =>
        blog.title.toLowerCase().includes(this.searchText.toLowerCase())
    );

    console.log(this.filteredBlogs);
  }

  onSearchChange(searchText: string) {
      this.searchSubject.next(searchText);
  }
}