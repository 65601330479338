import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddressesModel, CityModel, DistrictModel } from 'src/app/models/register/addresses-model';
import { AuthService } from 'src/app/services/auth.service';
import { RegisterModel } from 'src/app/models/register/register-model';
import { ElectricVehicleModel } from 'src/app/models/register/electric-vehicle-model';
import { Router } from '@angular/router';
import { CityService } from 'src/app/services/city.service';
import { ElectricTypeModel } from 'src/app/models/register/electric-type-model';
import { ElectricGroupModel } from 'src/app/models/register/electric-group-model';




@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  cities: any[] = [];
  selectedCityId : number | null = null;
  districts: any[] = [];
  registerForm: FormGroup;
  showSelect = false;
  addresses: AddressesModel = new AddressesModel();
  electricVehicles: ElectricVehicleModel[] = [];
  electricTypes:ElectricTypeModel[] = [];
  electricGroups:ElectricGroupModel[] = [];

  //selectedCity: CityModel = new CityModel();
  //selectedDistrict: DistrictModel = new DistrictModel();

  registerModel: RegisterModel = new RegisterModel();

  constructor(private toastr: ToastrService, 
    private formBuilder: FormBuilder, 
    private service: AuthService,private router: Router,
    private cityService:CityService) { }

  ngOnInit(): void {
 
    this.loadCities();
    this.getElectricVehicles();
    this.loadElectricGroups();
    this.loadElectricTypes();
  
  }
  loadCities() {
    this.cityService.getCities().subscribe(data => {
      this.cities = data; // Assuming your API returns an array of cities
    });
  }
  loadElectricTypes() {
    this.service.getElectricTypes().subscribe(data => {
      this.electricTypes = data; // Assuming your API returns an array of cities
    });
  }
  loadElectricGroups() {
    this.service.getElectricGroups().subscribe(data => {
      this.electricGroups = data; // Assuming your API returns an array of cities
    });
  }
  onCitySelected(event: any) {
    const selectedCityId = event.target.value;
    if (selectedCityId) {
      this.selectedCityId = +selectedCityId;
      this.loadDistricts(this.selectedCityId);
    } else {
      this.selectedCityId = null;
      this.districts = [];
    }
  }
  
  loadDistricts(cityId: number) {
    this.cityService.getDistricts(cityId).subscribe(data => {
      this.districts = data; // Assuming your API returns an array of districts
    });
  }
  onDistrictSelected(event: any) {
    this.registerModel.districtId = +event.target.value;    // Burada yapılması gereken işlemleri gerçekleştirin.
  }

  onElectricTypeSelected(event: any) {
   this.registerModel.electricTypeKey  = event.target.value;   // Burada yapılması gereken işlemleri gerçekleştirin.
  }

  onElectricGroupSelected(event: any) {
    this.registerModel.electricGroupKey = event.target.value;     
  }

  onElectricVehicleSelected(event: any) {
    this.registerModel.electricVehicleId = +event.target.value;  
  }

  getElectricVehicles(): void {
    this.service
      .getElectricVehicles()
      .subscribe((response: ElectricVehicleModel[]) => {
        this.electricVehicles = response;
        console.log(this.addresses.cities);
      });
  }


 submitForm(): void {
  console.log(this.registerModel);
  
  if (!this.selectedCityId) {
    this.toastr.warning("Lütfen il seçiniz.");
  }
  else if (!this.registerModel.districtId) {
    this.toastr.warning("Lütfen ilçe seçiniz.");
  }
  else if (this.registerModel.password !== this.registerModel.passwordConfirm) {
    this.toastr.warning("Girmiş olduğunuz şifre aynı olmalıdır.");
  }
  else {
    this.registerModel.cityId = this.selectedCityId;
    
    console.log(this.registerModel);
    this.service.register(this.registerModel).subscribe(
      (response) => {
        console.log(response);
        if (response.success) {
          this.toastr.success(response.message);
          setTimeout(() => {
            this.router.navigateByUrl("/anasayfa");
          }, 1000);
        } else {
          this.toastr.error(response.message);
        }
      },
      (responseError) => {
        this.toastr.error(responseError.error.message);
      }
    );
  }
}


}
