import { Component, EventEmitter , Output} from '@angular/core';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  
})
export class FilterComponent {  
  @Output() close = new EventEmitter<void>();  
  brandNameList: string[];
  selectedMinPrice: number = 0;
  selectedMaxPrice: number = 1000;
  startValue: number = 0;
  endValue: number = 15;
  constructor(private mapService: MapService) { }
  ngOnInit(): void {
    this.getAllBrandName();    
  }
  getAllBrandName(){
    this.mapService.getAllBrandName().subscribe((response: any) => {
      this.brandNameList = response;
    });
  }
  closePopup() {
    this.close.emit();
  }
  onBrandSelected(event: any) {
    const selectedCityId = event.target.value;
    console.log(selectedCityId);
    
  }
  
}
