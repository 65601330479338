<div class="text-center space-ptb">
  <img src="assets/images/home-03/electric_vs_fuelOil.webp" class="img-fluid w-50">
</div>

<section class="bg-overlay-black-1 electric-feature-section" style="background-image: url(assets/images/home-01/bg-01.webp); background-size:cover; background-repeat:no-repeat; ">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title left-divider text-center pb-4">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-12 col-lg-4">
          <div class="feature-item feature-info-style-04 feature-item-right mb-4 pb-4">  
            <div class="feature-icon me-lg-0 order-lg-2">
              <object type="image/svg+xml" data="assets/images/svg/charging-station.svg" class="icon"></object>
            </div>              
            <div class="feature-content text-lg-end order-lg-1">
              <h4 class="feature-title">{{'All Brands' | translate}}</h4>
              <p>{{'All Brands Content' | translate}}</p>
            </div>
          </div>
          <div class="feature-item feature-info-style-04 feature-item-right mb-4 pb-4">   
            <div class="feature-icon me-lg-0 order-lg-2">
              <object type="image/svg+xml" data="assets/images/svg/fast-charging.svg" class="icon"></object>
            </div>             
            <div class="feature-content text-lg-end order-lg-1">
              <h4 class="feature-title">{{'Battery Life' | translate}}</h4>
              <p>{{'Battery Life Content' | translate}}</p>
            </div>
            
          </div>
          <div class="feature-item feature-info-style-04 feature-item-right mb-5 mb-lg-0 pb-4"> 
            <div class="feature-icon me-lg-0 order-lg-2">
              <object type="image/svg+xml" data="assets/images/svg/charge-home.svg" class="icon"></object>
            </div>               
            <div class="feature-content text-lg-end order-lg-1">
              <h4 class="feature-title">{{'Charging at Home' | translate}}</h4>
              <p>{{'Charging at Home Content' | translate}}</p>
            </div>
            
          </div>
        </div>
        <div class="col-md-12 col-lg-4 mb-5 mb-lg-0">
          <div class="text-center">
            <img class="img-fluid" src="assets/images/home-01/car.webp" alt="#" style="height: 550px;">
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <div class="feature-item feature-info-style-04 mb-4 pb-4">
            <div class="feature-icon">
              <object type="image/svg+xml" data="assets/images/svg/rapid-charging.svg" class="icon"></object>
            </div>
            <div class="feature-content">
              <h4 class="feature-title">{{'Fast Charging' | translate}}</h4>
              <p>{{'Fast Charging Content' | translate}}</p>
            </div>
          </div>
          <div class="feature-item feature-info-style-04 mb-4 pb-4">
            <div class="feature-icon">
              <object type="image/svg+xml" data="assets/images/svg/charge-point-stations.svg" class="icon"></object>
            </div>
            <div class="feature-content">
              <h4 class="feature-title">{{'Station Compatibility' | translate}}</h4>
              <p>{{'Station Compatibility Content' | translate}}</p>
            </div>
          </div>
          <div class="feature-item feature-info-style-04 mb-4 mb-lg-0 pb-4">
            <div class="feature-icon">
              <object type="image/svg+xml" data="assets/images/svg/charging-timer.svg" class="icon"></object>
            </div>
            <div class="feature-content">
              <h4 class="feature-title">{{'Monthly/Yearly Usage' | translate}}</h4>
              <p>{{'Monthly/Yearly Usage Content' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-ptb"></div>
</section>