export class RegisterModel{
    email:string;
    password:string;
    passwordConfirm:string;
    isPersonal:boolean = true;
    firstName:string;
    lastName:string;
    companyName:string;
    taxNo:number;
    taxOffice:string;
    telNo:string;
    address:string;
    cityId:number;
    districtId:number | null;
    postCode:number;
    isHaveCar:boolean;
    electricVehicleId:number | null;
    electricTypeKey:string;
    electricGroupKey:string;
}