import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapRoutingModule } from './components/map/map-routing.module';
import { RegisterRoutingModule } from './components/register/register-routing.module';
import { LoginRoutingModule } from './components/login/login-routing.module';
import { BlogRoutingModule } from './components/blog/blog-routing.module';
import { HomeRoutingModule } from './components/home/home-routing.module';
import { HomeComponent } from './components/home/home.component';
import { BlogDetailRoutingModule } from './components/blog-detail/blog-detail-routing.module';
import { ContactRoutingModule } from './components/contact/contact-routing.module';
import { ComingSoonRoutingModule } from './components/coming-soon/coming-soon-routing.module';
import { CompareElectricFuelRoutingModule } from './components/compare-electric-fuel/compare-electric-fuel-routing.module';
import { RoutePlanningRoutingModule } from './components/route-planning/route-planning-routing.module';
import { CostManagementRoutingModule } from './components/cost-management/cost-management-routing.module';
import { CompetitionRoutingModule } from './components/competition/competition-routing.module';
import { HistoryRoutingModule} from './components/history/history-routing.module';
import { PrivacyRoutingModule } from './components/privacy/privacy-routing.module';
import { PlansRoutingModule } from './components/plans/plans-routing.module';
import { PopupRoutingModule } from './components/popup/popup-routing.module';
import { UserAgreementRoutingModule } from './components/user-agreement/user-agreement-routing.module';
import { TermOfUseRoutingModule } from './components/term-of-use/term-of-use-routing.module';
import { KVKKRoutingModule } from './components/kvkk/kvkk-routing.module';
import { DownloadRoutingModule } from './components/download/download-routing.module';
import { InvestorRoutingModule } from './components/investor/investor-routing.module';
import { FilterRoutingModule } from './components/filter/filter-routing.module';

const routes: Routes = [
   {
    path: '', component:HomeComponent
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
  DownloadRoutingModule,
  RegisterRoutingModule,
  LoginRoutingModule,
  MapRoutingModule,
  PopupRoutingModule,
  HomeRoutingModule,
  BlogRoutingModule,
  BlogDetailRoutingModule,
  ContactRoutingModule,
  ComingSoonRoutingModule,
  CompareElectricFuelRoutingModule,
  RoutePlanningRoutingModule,
  CostManagementRoutingModule,
  CompetitionRoutingModule,
  HistoryRoutingModule,
  PrivacyRoutingModule,
  //PlansRoutingModule,
  UserAgreementRoutingModule,
  TermOfUseRoutingModule,
  KVKKRoutingModule,
  InvestorRoutingModule,
  //FilterRoutingModule
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }
