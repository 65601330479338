import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private router: Router, private toastrService: ToastrService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem("token");
    let newRequest : HttpRequest<any>;
    newRequest = request.clone({
      headers: request.headers.set("Authorization","Bearer " + token)
    });
    return next.handle(newRequest).pipe(
      catchError((err: any) => {
        // Hata durumunda yapılacak işlemler
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          console.log(err.status);
          console.log(err.statusText);
          // Kullanıcının JWT süresi dolmuştur.
          if (err.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");

            if (refreshToken) {
              return this.authService.refreshToken(refreshToken).pipe(
                switchMap((data) => {
                  localStorage.setItem("token", data.data.token);
                  localStorage.setItem("refreshToken", data.data.refreshToken);
  
                  // Retry original request with new token
                  newRequest = request.clone({
                    headers: request.headers.set("Authorization", "Bearer " + data.data.token)
                  });
                  return next.handle(newRequest);
                }),
                catchError((refreshErr) => {
                  // Handle errors on refresh failure
                  localStorage.removeItem("token");
                  localStorage.removeItem("refreshToken");
                  this.router.navigate(["giris"]);
                  this.toastrService.error("Session expired, please log in again.");
                  return throwError(refreshErr); 
                })
              );
            } else {  
              // No refresh token - force logout
              localStorage.removeItem("token"); 
              this.router.navigate(["giris"]);
              this.toastrService.info("Your session has expired, please log in again.");
              return throwError(err); // Re-throw original error
            }
          }
        }
        return throwError(err);
      })
    ) as any;
  }
}
