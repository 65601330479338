<section class="space-ptb">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
         <div class="service-detail">
           <div class="service-img mb-3 mb-lg-5 mb-md-4">
             <img class="img-fluid" src="images/service/bg-02.jpg" alt="">
           </div>
          <div class="service-content">
            <h2>{{'Route Planning' | translate}}</h2>
            <p class="pt-3 mb-3 mb-lg-5 mb-md-4">{{'Route Planning Content' | translate}}</p>
            <div class="row mt-3 mt-lg-5 mt-md-4">
             <div class="col-lg-12">
               <div class="list">
                 <ul class="list-unstyled">
                   <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Route Planning li-one' | translate}}</li>
                   <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Route Planning li-two' | translate}}</li>
                   <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Route Planning li-three' | translate}}</li>
                   <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Route Planning li-four' | translate}}</li>
                 </ul>
               </div>
             </div>
            </div>
            <div class="row mt-3 mt-lg-5 mt-md-4 align-items-center">
             <div class="col-lg-6">
                <img class="img-fluid" src="assets/images/home-03/route-planning.webp" alt="">
             </div>
             <div class="col-lg-6">
               <div class="service-detail-content">
                 <h5 class="fw-bold text-primary mb-3">{{'Features' | translate}}</h5>
                 <div class="service-info-detail mb-3 mb-lg-5 mb-md-4">
                   <h5 class="fw-bold">{{'Favorite Routes' | translate}}</h5>
                   <p>{{'Favorite Routes Content' | translate}}</p>
                 </div>
                 <div class="service-info-detail mb-3 mb-lg-5 mb-md-4">
                   <h5 class="fw-bold">{{'Go Urgently' | translate}}</h5>
                   <p>{{'Go Urgently Content' | translate}}</p>
                 </div>
                 <div class="service-info-detail">
                   <h5 class="fw-bold">{{'Station Finder' | translate}}</h5>
                   <p class="mb-0">{{'Station Finder Content' | translate}}</p>
                 </div>
               </div>
             </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</section>