<body class="my-login-page">

    <section class="h-100">
        <div class="container h-100">
            <div class="row justify-content-md-center h-100">
                <div class="card-wrapper">
                    <div class="brand" style="display: flex; justify-content: center; align-items: center;">
                        <img src="assets/images/home-01/ss-logo.webp" alt="Newest Smart Cafe" style="width: 150px !important;">
                    </div>


                    <div class="card text-center">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary" style="color: #1d3149 !important">Şarjsepeti
                                <span style=" color:#858796;"> - Uygulama İndir</span></h6>
                        </div>


                        <div class="card-body">
                            <div
                                style="display: flex; justify-content: center; flex-direction: column; align-items: center;">

                                <div style="font-weight: bold;">
	                                    Şarjsepeti Uygulamasını indir, istasyonları görüntüle ve maliyetini düşür.
                                </div>
                                <br>
                                <div id="buttonsContent" style="display: flex; justify-content: center; align-items: center;">
                                    <div id="buttonDiv"
                                        style="display: flex; justify-content: center; align-items: center;  background-color: rgb(236, 236, 236); border-radius: 5px; padding: 10px; margin-right: 10px;">
                                        <div style="margin-right: 5px;">
                                            <i class="fa-brands fa-apple" style="font-size: 38px; color: black;"></i>
                                        </div>
                                        <div>
                                            <a href="https://apps.apple.com/tr/app/%C5%9Farj-sepeti/id6479734875?l=tr" target="_blank" class="market-btn apple-btn" role="button" style="display: flex; flex-direction: column; justify-content: center; align-items: center; color: black;">
                                                <span class="market-button-subtitle">{{'Download on the' | translate}}</span>
                                                <span class="market-button-title">App Store</span>
                                            </a>
                                        </div>
                                    </div>

                                    <div id="buttonDiv"
                                        style="display: flex; justify-content: center; align-items: center;  background-color: rgb(236, 236, 236); border-radius: 5px; padding: 10px;">
                                        <div style="margin-right: 5px;">
                                            <i class="fa-brands fa-google-play"
                                                style="font-size: 38px;"></i>
                                        </div>

                                        <div>
                                            <a href="https://play.google.com/store/apps/details?id=com.uniteds.sarjsepeti.gp" target="_blank" class="market-btn google-btn" role="button" style="display: flex; flex-direction: column; justify-content: center; align-items: center;color: black;">
                                                <span class="market-button-subtitle">{{'Download on the' | translate}}</span>
                                                <span class="market-button-title">Google Play</span>
                                              </a>
                                        </div>
                                    </div>

                                </div>
                                <br>
                                <div style="background-color: #507c77; color: #ffffff; padding: 10px; border-radius: 5px; cursor:pointer" routerLink="/">
                                    Ana Menüye Dön
                                </div>


                            </div>

                        </div>
                    </div>
                    <div class="footer">
                        Copyright &copy; 2023 &mdash; Şarjsepeti
                    </div>
                </div>
            </div>
        </div>
    </section>
</body>