import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PopupComponent } from './popup.component';

const routes:Routes =[
    {
        path:'popup',
        component:PopupComponent,
    }
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class PopupRoutingModule {}