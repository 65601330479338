<header class="header header-sticky default" style="position: relative;">
  <nav class="navbar navbar-static-top navbar-expand-xl">
    <div class="container-fluid main-header position-relative">
      <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"><i
          class="fas fa-align-left" style="color: #383632;"></i></button>
      <a class="navbar-brand" routerLink="/">
        <img class="logo img-fluid" src="assets/images/home-01/ss-logo.webp" alt="logo">
        <img class="sticky-logo img-fluid" src="assets/images/home-01/ss-logo.webp" alt="logo">
      </a>
      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/">{{'Home' | translate}}</a>
          </li>


          <li class="dropdown nav-item">
            <a class="nav-link" href="javascript:void(0)" data-bs-toggle="dropdown">{{'Features' | translate}}<i
                class="fas fa-chevron-down fa-xs"></i></a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="/maliyet">{{'Cost Managament' | translate}}</a></li>
              <li><a class="dropdown-item" routerLink="/rota-planlama">{{'Route Planning' | translate}}</a></li>
            </ul>
          </li>
          <!-- <li class="nav-item">
                <a class="nav-link" routerLink="/cost">{{'Cost Managament' | translate}}
                </a>
              </li> -->

          <!-- <li class="nav-item">
                <a class="nav-link" routerLink="/route-planning">{{'Route Planning' | translate}}
                </a>
              </li> -->

          <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/plans" routerLinkActive="active">{{'Membership' | translate}}</a>
          </li> -->

          <li class="nav-item">
            <a class="nav-link" routerLink="/blog" routerLinkActive="active">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/harita" routerLinkActive="active">{{'Station Map' | translate}}</a>
          </li>
          <!-- <li class="nav-item" >
            <a class="nav-link" routerLink="/coming-soon" >{{'Map' | translate}}</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" routerLink="/iletisim" routerLinkActive="active">{{'Contact us' | translate}}</a>
          </li>
        </ul>
      </div>
      <div class="add-listing">
        <!-- <div class="need-help">
          <div class="icon">
            <img src="images/svg/phone-01.svg" alt="#">
          </div>
          <div class="help-info">
            <p>Need help? Talk to an expert </p>
            <span>+126-632-2345</span>
          </div>
        </div>
        <div class="woo-action">
          <ul class="list-unstyled">
            <li class="cart dropdown">
              <a href="#" data-bs-toggle="offcanvas" data-bs-target="#cartoffcanvasRight" aria-controls="offcanvasRight">
                <span class="cart-icon"><i class="fa-brands fa-opencart"></i></span>
                <span class="cart-count">3</span></a>
              </li>
            </ul>
          </div>
          <div class="side-menu">
            <a href="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <img src="images/svg/menu.svg" alt="#">
              <img class="menu-dark" src="images/svg/menu-dark.svg" alt="#">
            </a>
          </div> -->


        <li class="nav-item dropdown" style="list-style: none; margin-right: 2rem;">
          <button type="button" class="btn btn-outline-primary" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="accountLink"><i class="fa-solid fa-user" ></i></button>
          <div class="dropdown-menu dropdown-menu-right dropdown-default fb-scroll-v" aria-labelledby="navbarDropdownMenuLink">
            <div *ngIf="isAuthenticated()">
              <!-- <a class="dropdown-item" routerLink="/login">
                <span>
                  {{'Edit profile' | translate}}
                </span>
              </a> -->
              <a class="dropdown-item"  routerLink="/gecmis">
                <span>
                  {{'Transaction history' | translate}}
                </span>
              </a>
              <a class="dropdown-item"  routerLink="/kayit" (click)="logOut()">
                <span >
                  {{'Logout' | translate}}
                </span>
              </a>
            </div> 
            <div *ngIf="!isAuthenticated()">
              <a class="dropdown-item" routerLink="/giris">
                <span>
                  {{'Login' | translate}}
                </span>
              </a>
              <a class="dropdown-item"  routerLink="/kayit">
                <span>
                  {{'Register' | translate}}
                </span>
              </a>
            </div> 
          </div>
        </li>

        <li class="nav-item list-item-top-nav dropdown langs-dd-desk-li mt-1 mr-3"
          style="list-style: none; margin-right: 7rem;">
          <a class="langs-dd langs-dd-desk waves-effect waves-light" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" id="selectedFlag">
            <span><img [src]="getSelectedFlagUrl(getLanguageCode())" alt="{{ getLanguageCode() }}"
                style="width: 35px; height: 35px;"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-default fb-scroll-v"
            aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item waves-effect waves-light myLang" href="javascript:;" (click)="changeLang('en')"
              data-lang="en" id="languageENbutton">
              <span>
                <img class="nav-lang-img" [src]="getSelectedFlagUrl('en')" alt="uk"
                  style="width: 20px; height: 20px; display: inline;">
                English
              </span>
            </a>
            <a class="dropdown-item waves-effect waves-light myLang" href="javascript:;" (click)="changeLang('tr')"
              data-lang="tr" id="languageTRbutton">
              <span>
                <img class="nav-lang-img" [src]="getSelectedFlagUrl('tr')" alt="tr"
                  style="width: 20px; height: 20px; display: inline;">
                Türkçe / Turkish
              </span>
            </a>
          </div>
        </li>

      </div>
    </div>
  </nav>
</header>

<!--cart menu-->
<!-- <div class="cart-side-menu offcanvas offcanvas-end" tabindex="-1" id="cartoffcanvasRight">
  <div class="offcanvas-header">
    <h3 class="mb-0">{{'Shopping Cart' | translate}}</h3>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="dropdown-menu-right">
      <ul class="cart-list">
        <li class="d-flex">
          <a class="remove-item" href="#"><i class="fas fa-times"></i></a>
          <img class="img-fluid me-3" src="images/product/01.png" alt="">
          <div class="cart-info">
            <a href="#">2019 Tesla Model x</a>
            <span class="d-block">$95,000.00</span>
          </div>
        </li>
        <li class="d-flex">
          <a class="remove-item" href="#"><i class="fas fa-times"></i></a>
          <img class="img-fluid me-3" src="images/product/05.png" alt="">
          <div class="cart-info">
            <a href="#">BMW i3</a>
            <span class="d-block">$46,980.00</span>
          </div>
        </li>
        <li class="d-flex">
          <a class="remove-item" href="#"><i class="fas fa-times"></i></a>
          <img class="img-fluid me-3" src="images/product/03.png" alt="">
          <div class="cart-info">
            <a href="#">Nissan LEAF</a>
            <span class="d-block">$5,999.00</span>
          </div>
        </li>
      </ul>
      <div class="cart-footer">
        <div class="d-flex mb-3">
          <b class="me-auto text-dark">{{'Subtotal' | translate}}:</b>
          <span>$1,47,979</span>
        </div>
        <div class="d-inline-block d-sm-flex">
          <a class="col btn btn-secondary btn-sm me-2 px-4" href="shop-cart.html">{{'View Cart' | translate}}</a>
          <a class="col btn btn-sm btn-primary ms-0 mt-1 mt-sm-0 ms-sm-2 px-4" href="shop-checkout.html">{{'Checkout' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!--Right menu -->
<!-- <div class="offcanvas offcanvas-end offcanvas-sidebar-menu" tabindex="-1" id="offcanvasRight">
  <div class="offcanvas-header text-end justify-content-end p-4">
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i
        class="fa-solid fa-xmark"></i></button>
  </div>
  <div class="offcanvas-body p-4 p-sm-5 d-flex align-content-between flex-wrap justify-content-center">
    <div class="sidebar-menu">
      <div class="sidebar-logo">
        <a href="index.html">
          <img class="logo img-fluid" src="images/logo-white.svg" alt="logo">
        </a>
      </div>
      <div class="section-title mt-5">
        <h3 class="title text-white">{{'About us' | translate}}</h3>
        <p class="text-white">{{'About us content' | translate}}</p>
      </div>
      <div class="mt-5">
        <h3 class="mb-3 text-white">{{'Contact Info' | translate}}</h3>
        <p class="text-white">17504 Carlton Cuevas Rd,<br> Gulfport, MS, 39503</p>
        <h2 class="text-white">1-800-555-500</h2>
      </div>
      <div class="social-icon mt-5">
        <ul>
          <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
          <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
          <li><a href="#"><i class="fab fa-instagram"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</div> -->

<!-- Banner Home -->
<!-- <section *ngIf="router.url == '/home' || router.url == '/'"  class="banner banner-02">
  <div id="main-slider" class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide align-items-center d-flex slide-01header-position">
        <div class="banner-bg-image">
          <img src="assets/images/home-01/home-banner-bg.png" alt="#" data-swiper-animation="fadeInRight" data-duration="1.5s" data-delay="1.0s" style="width: 100% !important;background-size: cover; ">
        </div>
        <div class="container" style = "position: relative; right: 100px; bottom: 150px;">
          <div class="row">
            <div class="col-xl-9 col-lg-11 col-md-12 position-relative" style="color: black!important;">
              <h1 class="text-start" data-swiper-animation="fadeInUp" data-duration="1.5s" data-delay="2.0s">{{'Home Header' | translate}}</h1>
              <a routerLink="/coming-soon" class="btn btn-primary mt-4" data-swiper-animation="fadeInUp" data-duration="1.5s" data-delay="4.0s">{{'Get Charging' | translate}}</a>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</section> -->

<section *ngIf="router.url == '/anasayfa' || router.url == '/'" class="banner banner-02">
  <div id="main-slider" class="swiper-container" style="position: relative;">

    <div class="banner-bg-placeholder">
      <img src="{{'Home-bg' | translate}}" alt="Şarjsepeti" data-swiper-animation="fadeInRight" data-duration="1.5s" data-delay="1.0s" class="banner-bg-image" >
    </div>

    <div id="dwButtonCnt" class="container" style="position: absolute; top: 30%; left: 5%; transform: translateY(-50%);">
     
        <div class="col-xl-9 col-lg-11 col-md-12 position-relative" style="color: black!important;">

          <div id="buttonsDiv" style="display: flex; flex-direction: column; width: fit-content;">
         
            <a href="https://apps.apple.com/tr/app/%C5%9Farj-sepeti/id6479734875?l=tr" target="_blank" class="market-btn apple-btn" role="button">
            <span class="market-button-subtitle">{{'Download on the' | translate}}</span>
            <span class="market-button-title">App Store</span>
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.uniteds.sarjsepeti.gp" target="_blank" class="market-btn google-btn" role="button" style="margin-top: 10px;">
            <span class="market-button-subtitle">{{'Download on the' | translate}}</span>
            <span class="market-button-title">Google Play</span>
          </a>
        </div>
      </div>
    </div>

  </div>
</section>


