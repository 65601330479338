<section class="coming-soon-section bg-overlay-secondary-6 space-ptb vh-100 d-flex justify-content-center align-items-center" style="background: url(assets/images/coming-soon-bg.webp); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 my-4 text-center">
          <a class="coming-soon-logo mb-4" routerLink="/anasayfa">
            <img class="logo img-fluid" src="assets/images/home-01/ss-logo.webp" alt="logo">
          </a>
          <p class="lead mb-4 mb-md-5 px-3 text-white">We are working very hard on the new version of our site.It will bring a lot of new features.<br> Stay tuned!</p>
          <h1 class="text-white">Coming Soon....</h1>
             <!-- <div class="countdown-main my-5">
              <div class="countdown">
              <span class="days">00</span>
                <p class="days_ref">Days</p>
              </div>
              <div class="countdown">
                <span class="hours">00</span>
                <p class="hours_ref">Hours</p>
              </div>
              <div class="countdown">
                <span class="minutes">00</span>
                <p class="minutes_ref">Minutes</p>
              </div>
              <div class="countdown">
                <span class="seconds">00</span>
                <p class="seconds_ref">Seconds</p>
              </div>
             </div> -->
             <br>
          <a class="mt-5 text-white" routerLink="/anasayfa" >Provide your email address & we will notify you when site is ready:</a>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <form class="form-inline mt-3">
                <div class="mb-3">
                  <input type="text" class="form-control" placeholder="Your email address">
                </div>
                <button type="submit" class="btn btn-primary">Notify Me</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>