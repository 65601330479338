<section class="space-ptb">
    <div class="container">
      <div class="row">
        <div class="col-lg-8" *ngIf="filteredBlogs">
          <!-- Blog 1 -->
          <div  class="blog-post blog-style-02 mb-4 mb-md-5" *ngFor="let blog of filteredBlogs">
            <div class="blog-img">
              <img class="img-fluid" src="{{blog.image}}" alt="{{blog.title}}">
            </div>
            <div class="blog-info">
              <div class="blog-comment d-flex">
                <span class="pe-5"> {{blog.dateTime}}</span>
                <a href="#">0 {{'Comments' | translate}}</a>
              </div>
              <h4 class="blog-tittle"><a [routerLink]="['/blog-detay', blog.id]" href="javascript:;">{{blog.key+"-title" | translate}}</a></h4>
              <p>{{blog.key+"-description" | translate}}</p>
              <a class="blog-link" [routerLink]="['/blog-detay', blog.id]" href="javascript:;">{{'Read More' | translate}}<i class="fa-solid fa-arrow-right-long ps-2"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="sidebar">
            <div class="widget">
              <div class="widget-title">
                <h5 class="fw-600">{{'Search' | translate}}</h5>
              </div>
              <div class="search">
                <a href="#" class="btn btn-primary text-uppercase"><i class="fa-solid fa-magnifying-glass"></i></a>
                <input type="text" class="form-control" [(ngModel)]="searchText" (ngModelChange)="filterBlogs()" placeholder="Search">
              </div>
            </div>

            <div class="widget">

              <h3 class="widget-title fw-600">{{'Recent Post' | translate}}</h3>

              <div class="recent-post" *ngFor="let blog of allBlogs">
                <div class="post-img">
                  <img class="img-fluid" src="{{blog.image}}" alt="{{blog.title}}">
                </div>

                <div class="post-info">
                  <span class="post-date"><i class="fa-solid fa-calendar-days"></i>{{blog.dateTime}}</span>
                  <h6 class="post-title"><a [routerLink]="['/blog-detay', blog.id]" href="javascript:;">{{blog.key+"-title" | translate}}</a></h6>
                </div>
              </div>

            </div>
            <div class="widget">
              <div class="widget-title">
                <h3 class="fw-600">{{'Categories' | translate}}</h3>
              </div>
              <div class="widget-categories">
                <ul class="list-unstyled list-style list-style-underline mb-0">
                  <li *ngFor="let item of categories" ><a >{{item.name | translate}}<span><i class="fa-solid fa-chevron-right"></i></span></a></li>
                  <!-- <li><a href="#">EV-charging points<span><i class="fa-solid fa-chevron-right"></i></span></a></li> -->
                  <!-- <li><a href="#">Charging Station<span><i class="fa-solid fa-chevron-right"></i></span></a></li> -->
                  <!-- <li><a href="#">Cars Rental<span><i class="fa-solid fa-chevron-right"></i></span></a></li> -->
                  <!-- <li><a href="#">Battery Electric Vehicles<span><i class="fa-solid fa-chevron-right"></i></span></a></li> -->
                </ul>
              </div>
            </div>

            <div class="widget">
              <h3 class="widget-title fw-600">{{'Tags' | translate}}</h3>
              <div class="widget-content">
                <div class="popular-tag">
                  <ul class="list-unstyled">
                    <li><a href="#">EV</a></li>
                    <li><a href="#">{{'Charging' | translate}}</a></li>
                    <li><a href="#">{{'Car' | translate}}</a></li>
                    <li><a href="#">{{'Brands' | translate}}</a></li>
                    <li><a href="#">{{'Electric' | translate}}</a></li>
                    <li><a href="#">{{'Future' | translate}}</a></li>
                    <li><a href="#">{{'Auto' | translate}}</a></li>
                    <li><a href="#">{{'Electric Vehicle' | translate}}</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- <div class="widget mb-0">
              <h3 class="widget-title fw-600">{{'Subscribe & Follow' | translate}}</h3>
              <div class="widget-content">
                <div class="social">
                  <ul class="list-unstyled mb-0">
                    <li class="facebook">
                      <a href="#"> <i class="fab fa-facebook-f me-3"></i>Facebook</a>
                      <a class="follow ms-auto" href="#">{{'Like' | translate}}</a>
                    </li>
                    <li class="twitter">
                      <a href="#"> <i class="fab fa-twitter me-3"></i>Twitter</a>
                      <a class="follow ms-auto" href="#">{{'Follow' | translate}} </a>
                    </li>
                    <li class="youtube">
                      <a href="#"> <i class="fab fa-youtube me-3"></i>Youtube</a>
                      <a class="follow ms-auto" href="#">{{'Subscribe' | translate}} </a>
                    </li>
                    <li class="instagram">
                      <a href="#"> <i class="fab fa-instagram me-3"></i>Instagram</a>
                      <a class="follow ms-auto" href="#">{{'Follow' | translate}} </a>
                    </li>
                    <li class="linkedIn mb-0">
                      <a href="#"> <i class="fab fa-linkedin-in me-3"></i>LinkedIn</a>
                      <a class="follow ms-auto" href="#">{{'Follow' | translate}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
</section>