import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';  
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NavComponent } from './components/nav/nav.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { RegisterComponent } from './components/register/register.component'
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginComponent } from './components/login/login.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FooterComponent } from './components/footer/footer/footer.component';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import { MapComponent } from './components/map/map.component';
import { HomeComponent } from './components/home/home.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { ContactComponent } from './components/contact/contact.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { CompareElectricFuelComponent } from './components/compare-electric-fuel/compare-electric-fuel.component';
import { RoutePlanningComponent } from './components/route-planning/route-planning.component';
import { CostManagementComponent } from './components/cost-management/cost-management.component';
import { CompetitionComponent } from './components/competition/competition.component';
import { HistoryComponent } from './components/history/history.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { PlansComponent } from './components/plans/plans.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PopupComponent } from './components/popup/popup.component';
import { UserAgreementComponent } from './components/user-agreement/user-agreement.component';
import { TermOfUseComponent } from './components/term-of-use/term-of-use.component';
import { KvkkComponent } from './components/kvkk/kvkk.component';
import { DownloadComponent } from './components/download/download.component';
import { InvestorComponent } from './components/investor/investor.component';
import { FilterComponent } from './components/filter/filter.component';
import {MatSliderModule} from '@angular/material/slider';

export function httpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    RegisterComponent,
    LoginComponent,
    MapComponent,
    HomeComponent,
    FooterComponent,
    BlogComponent,
    BlogDetailComponent,
    ContactComponent,
    ComingSoonComponent,
    CompareElectricFuelComponent,
    RoutePlanningComponent,
    CostManagementComponent,
    CompetitionComponent,
    HistoryComponent,
    PrivacyComponent,
    PlansComponent,
    LoadingComponent,
    PopupComponent,
    UserAgreementComponent,
    TermOfUseComponent,
    KvkkComponent,
    DownloadComponent,
    InvestorComponent,
    FilterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    GoogleMapsModule,
    NgSelectModule,
    MatSliderModule,
    ToastrModule.forRoot({positionClass:"toast-bottom-right"}),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    TranslateModule.forRoot(
      {
   loader: {
     provide: TranslateLoader,
    useFactory: httpLoaderFactory,
    deps: [HttpClient]
  }
 }
),
  ],
  providers: [
    {provide:HTTP_INTERCEPTORS, useClass:AuthInterceptor, multi:true},
    {provide:HTTP_INTERCEPTORS, useClass:LanguageInterceptor, multi:true},HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
