<!--=================================
App -->
<!--=================================
footer-->
<footer class="footer footer-style-02 space-pt bg-overlay-secondary-9 bg-holder" style="background-image: url(assets/images/home-03/footer-bg.webp);">
  <div class="container position-relative">
    <div class="row">
      <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
        <div class="footer-logo mb-3 mb-md-5">
          <img class="logo img-fluid" src="assets/images/home-01/ss-logo.webp" alt="logo">
        </div>
         <div class="call-center">
          <h3>+90 850 307 92 77</h3>
          <!-- <h3>{{'Toll free number' | translate}}</h3> -->
        </div> 
<br>
        <div class="col-xl-9 col-lg-11 col-md-12 position-relative" style="color: black!important;">

          <div id="buttonsDiv" style="display: flex; flex-direction: column; width: fit-content;">
         
            <a href="https://apps.apple.com/tr/app/%C5%9Farj-sepeti/id6479734875?l=tr" target="_blank" class="market-btn apple-btn" role="button">
            <span class="market-button-subtitle">{{'Download on the' | translate}}</span>
            <span class="market-button-title">App Store</span>
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.uniteds.sarjsepeti.gp" target="_blank" class="market-btn google-btn" role="button" style="margin-top: 10px;">
            <span class="market-button-subtitle">{{'Download on the' | translate}}</span>
            <span class="market-button-title">Google Play</span>
          </a>
        </div>
      </div>
      </div>
      <div class="col-sm-6 col-lg-2 mb-4 mb-lg-0">
        <div class="useful-links">
          <h5 class="text-white mb-3">{{'Quick links' | translate}}</h5>
          <ul class="list-unstyled mb-0">
            <li><a routerLink="/">{{'Home' | translate}}</a></li>
            <li><a routerLink="/blog">{{'Blog' | translate}}</a></li>
            <!-- <li><a routerLink="/coming-soon">{{'Map' | translate}}</a></li> -->
            <li><a routerLink="/iletisim">{{'Contact us' | translate}}</a></li>
            <li><a routerLink="/kullanici-sozlesmesi">{{'User Agreement' | translate}}</a></li>
            <li><a routerLink="/kullanim-kosullari">{{'Terms of Use' | translate}}</a></li>
            <li><a routerLink="/gizlilik">{{'Privacy Policy_Link' | translate}}</a></li>
            <li><a routerLink="/kvkk">{{'KVKK' | translate}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 mb-md-0 mb-4">
        <div class="useful-links">
          <h5 class="text-white mb-3">{{'Features' | translate}}</h5>
          <ul class="list-unstyled mb-0">
            <li><a routerLink="/rota-planlama">{{'Route Planning' | translate}}</a></li>
            <!-- <li><a routerLink="/compare">{{'Electric vs. Fuel Oil' | translate}}</a></li> -->
            <li><a routerLink="/maliyet">{{'Cost Managament' | translate}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <div class="footer-newsletter newsletter-style-02">
          <h3 class="text-white mb-3">{{'Newsletter' | translate}}</h3>
          <p class="me-5">{{'Newsletter Description' | translate}}</p>
          <form class="form-inline dark-form mb-4">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="{{'Enter email for newsletter' | translate}}">
            </div>
            <button type="submit" class="btn btn-primary">{{'Subscribe' | translate}}</button>
          </form>
          <div class="form-check">
            <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label text-white" for="flexCheckDefault">
              {{'Privacy Policy' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright position-relative">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-md-12 col-lg-6 justify-content-lg-start justify-content-md-center d-flex text-center justify-content-center">
          <p class="mb-0 text-white">{{'Copyright' | translate}} <a href="/">Şarjsepeti</a> {{'All Rights Reserved' | translate}}.</p>
        </div>
        <div class="col-md-12 col-lg-6 justify-content-lg-end justify-content-md-center justify-content-center d-flex mt-lg-0 mt-md-4 mt-3">
          <div class="footer-social-icon">
            <ul class="list-unstyled mb-0">
              <li><a href="https://www.instagram.com/sarj_sepeti/"><i class="fab fa-instagram"></i></a></li>
              <li><a href="https://twitter.com/sarj_sepeti"><i class="fab fa-twitter"></i></a></li>
              <li><a href="https://www.linkedin.com/company/sarj-sepeti"><i class="fab fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    <!--=================================
      footer-->
      <!--=================================
      Back To Top-->
      <div id="back-to-top" class="back-to-top">
        <a href="#"><i class="fa-solid fa-arrow-up"></i></a>
      </div>
      <!--=================================
      Back To Top-->