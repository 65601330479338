import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDTo } from 'src/app/models/auth/loginDTo';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginDto: LoginDTo = new LoginDTo();
  loginForm: FormGroup;

  loginFormAnimation: boolean = false;


  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl("", [
        Validators.required,
      ]),
      password: new FormControl("", [
        Validators.required,
      ]),
    });
  }

  get email() { return this.loginForm.get('email'); }

  get password() { return this.loginForm.get('password'); }

  login() {
    if (this.loginForm.valid) {
      this.loginFormAnimation = true;

      // var $this = $('#loginForm'),
      //   $state = $this.find('button > .state');
      // $this.addClass('loading');
      // $state.html('Authenticating');


      this.loginDto.email = this.loginForm.value.email;
      this.loginDto.password = this.loginForm.value.password;

      setTimeout(() => {
        this.authService.login(this.loginDto).subscribe(response => {
          if (response.success) {

            // $this.addClass('ok');
            // $state.html('Welcome back!');

            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);

            // Giriş yaptıktan sonra yönlendirilecek URL'yi alalım
            const redirectUrl = this.authService.getRedirectUrl();

            // Yönlendirilecek URL boş değilse yönlendirme yapalım

            setTimeout(() => {
              if (redirectUrl) {

                this.router.navigateByUrl(redirectUrl);

              } else {

                // Yönlendirilecek URL yoksa varsayılan bir sayfaya yönlendirebilirsiniz
                this.router.navigateByUrl("/anasayfa");
              }
            }, 1200);

          }
          else {
            // $state.html('Log in');
            // $this.removeClass('ok loading');
            this.loginFormAnimation = false;
            this.toastr.error(response.message);
          }
        }, responseError => {
          this.loginFormAnimation = false;
          // $state.html('Log in');
          // $this.removeClass('ok loading');
          this.toastr.error(responseError.error);
        })
      }, 1200);


    }
    else if (this.loginForm.value.email == "") {
      this.toastr.info("email required");
    }
    else if (this.loginForm.value.password == "") {
      this.toastr.info("password required");
    }
  }

  isLangTr():boolean{
    var lang = localStorage.getItem("lang");
    if(lang == "tr") return true;
    else return false;
  }

}
