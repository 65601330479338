<div class="container">
    <h3>Gizlilik Politikası</h3>
    <p>
        İşbu Gizlilik Politikası’nın amacı, UNITED YAZILIM TEKNOLOJİLERİ A.Ş. (“Şarjsepeti” veya “Şirket”) tarafından yönetilmekte olan sarjsepeti.com adresinde yer alan web sitesinin (“Portal”) kullanımına ilişkin koşul ve şartları tespit etmektir. İşbu Gizlilik Politikası’nda tanımlanmayan ifadelerin yorumlanmasında Kullanım Koşulları ve Hesap Sözleşmesi’ndeki tanımlar dikkate alınacaktır. Kullanıcılar, Hesap Sözleşmesi’ni kabul etmeleri üzerine işbu Gizlilik Politikası’nı da kabul etmiş sayılacaktır.
    </p>
    <p>
        “Kurumsal Hesap Sahibi”, davet edeceği Alt Kullanıcıların işbu Gizlilik Politikası hükümlerini bizzat onaylamaları gerektiğini kabul etmektedir. Bu doğrultuda Kurumsal Hesap sahibi belirleyeceği her Alt Kullanıcı’dan:
    </p>
    <ul>
        <li>Onay alınacağını,</li>
        <li>Onay alınmazsa, Alt Kullanıcı’nın Portal’ı kullanamayacağını,</li>
        <li>Her bir Alt Kullanıcı için ayrı ayrı onay alınacağını taahhüt eder.</li>
    </ul>
    <p>
        Şarjsepeti, teknolojiye yatırım yaparak yenilikçi ürün ve hizmet uygulamaları ile internet alanında Kullanıcılarına daha iyi hizmet vermek için sürekli kendisini yenilemekte ve en iyi hizmeti verebilmek için çalışmaktadır.
    </p>
    <p>
        Şarjsepeti, online davranışsal reklamcılık ve pazarlama yapılabilmesi amacıyla siteye gelen Kullanıcının “Hesap sahibi” olmasalar dahi sitedeki davranışlarını tarayıcıda bulunan bir çerez (cookie) ile ilişkilendirme ve görüntülenen sayfa sayısı, ziyaret süresi ve hedef tamamlama sayısı gibi metrikleri temel alan yeniden pazarlama listeleri tanımlama hakkına sahiptir. Daha sonra bu Kullanıcıya sitede ya da ağındaki diğer sitelerde, Kullanıcıların ilgi alanlarına göre hedefe yönelik reklam içeriği gösterilebilir.
    </p>
    <p>
        Google AFS reklamlarının Şarjsepeti’ne yönlendirilmesi esnasında Google kullanıcıların tarayıcısına çerez yerleştirebilir veya bunlarda yer alan çerezleri okuyabilir veya bilgi toplamak amacıyla web işaretçileri kullanabilir.
    </p>
    <h3>Google OAuth Kullanımı</h3>
    <p>
        Şarjsepeti, kullanıcıların Google hesabı ile giriş yapmalarına olanak tanımaktadır. Bu işlem sırasında, kullanıcı adı, e-posta adresi ve profil fotoğrafı gibi genel bilgileri almaktayız. Bu bilgiler, yalnızca kullanıcıların sisteme giriş yapmasını sağlamak amacıyla kullanılmakta olup, başka herhangi bir amaç için kullanılmamaktadır. Bu veriler, kullanıcının izni olmadan üçüncü şahıslarla paylaşılmamaktadır ve Şarjsepeti tarafından güvenli bir şekilde saklanmaktadır.
    </p>
    <h3>Gizlilik Politikası Üzerinde Gerçekleşecek Olan Değişiklikler</h3>
    <p>
        Şarjsepeti, işbu Gizlilik Politikası hükümlerini dilediği zaman Portal üzerinden yayımlamak suretiyle güncelleyebilir ve değiştirebilir. Şarjsepeti’nin Gizlilik Politikası’nda yaptığı güncelleme ve değişiklikler Portal’da yayınlandığı tarihten itibaren geçerli olacaktır.
    </p>
    <p>
        Kullanıcı, işbu Gizlilik Politikası’na konu bilgilerinin tam, doğru ve güncel olduğunu, bu bilgilerde herhangi bir değişiklik olması halinde bunları derhal sarjsepeti.com adresinden güncelleyeceğini taahhüt eder. Kullanıcının güncel bilgileri sağlamamış olması halinde Şarjsepeti’nin herhangi bir sorumluluğu olmayacaktır.
    </p>
</div>
