import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChargeFeatureModel } from 'src/app/models/cost-management/charge-feature-model';
import { CostManagementModel } from 'src/app/models/cost-management/cost-management-model';
import { CostManagementResultModel } from 'src/app/models/cost-management/cost-management-result-model';
import { ElectricGroupModel } from 'src/app/models/register/electric-group-model';
import { ElectricTypeModel } from 'src/app/models/register/electric-type-model';
import { ElectricVehicleModel } from 'src/app/models/register/electric-vehicle-model';
import { RegisterModel } from 'src/app/models/register/register-model';
import { AuthService } from 'src/app/services/auth.service';
import { CostManagementService } from 'src/app/services/cost-management.service';

@Component({
  selector: 'app-cost-management',
  templateUrl: './cost-management.component.html',
  styleUrls: ['./cost-management.component.css']
})
export class CostManagementComponent {

  electricVehicles: ElectricVehicleModel[] = [];
  chargeFeatures: ChargeFeatureModel[] = [];
  costManagementModel: CostManagementModel = new CostManagementModel();
  electricTypes: ElectricTypeModel[] = [];
  electricGroups: ElectricGroupModel[] = [];

  costManagementResult: CostManagementResultModel = new CostManagementResultModel();

  constructor(private toastr: ToastrService,
    private service: AuthService,
    private costMangementService: CostManagementService
  ) { }

  ngOnInit(): void {
    this.getElectricVehicles();
    this.getChargeFeatures();

    this.loadElectricGroups();
    this.loadElectricTypes();

    this.getUserData();
  }

  getElectricVehicles(): void {
    this.service
      .getElectricVehicles()
      .subscribe((response: ElectricVehicleModel[]) => {
        this.electricVehicles = response;
      });
  }
  getChargeFeatures(): void {
    this.costMangementService
      .getChargeFeautres()
      .subscribe((response: ChargeFeatureModel[]) => {
        this.chargeFeatures = response;
      });
  }
  loadElectricTypes() {
    this.service.getElectricTypes().subscribe(data => {
      this.electricTypes = data; // Assuming your API returns an array of cities
    });
  }
  loadElectricGroups() {
    this.service.getElectricGroups().subscribe(data => {
      this.electricGroups = data; // Assuming your API returns an array of cities
    });
  }

  onElectricTypeSelected(event: any) {
    this.costManagementModel.electricTypeKey = event.target.value;
  }

  onElectricGroupSelected(event: any) {
    this.costManagementModel.electricGroupKey = event.target.value;
  }

  onChargeFeatureSelected(event: any) {
    console.log(event.target.value);
    this.costManagementModel.chargeFeatureId = event.target.value;
    console.log(this.costManagementModel.chargeFeatureId);

  }

  onElectricVehicleSelected(event: any) {
    this.costManagementModel.electricVehicleId = +event.target.value;
  }

  getUserData() {
    if (this.service.isAuthenticated()) {
      this.costMangementService.getUserData().subscribe(data => {
        this.costManagementModel = data;
        console.log(this.costManagementModel);
      });
    }
  }


  submitForm(): void {
    // console.log(this.registerModel);
    console.log(this.costManagementModel);

    if (!this.costManagementModel.startTime) {
      this.toastr.warning("Lütfen başlangıç saatini seçiniz.");
    }
    else if (!this.costManagementModel.endTime) {
      this.toastr.warning("Lütfen bitiş saatini seçiniz.");
    }
    else if (!this.costManagementModel.electricVehicleId) {
      this.toastr.warning("Lütfen araç seçiniz.");
    }
    else if (!this.costManagementModel.electricGroupKey) {
      this.toastr.warning("Lütfen mesken seçiniz.");
    }
    else if (!this.costManagementModel.electricTypeKey) {
      this.toastr.warning("Lütfen tarife seçiniz.");
    }
    else if (!this.costManagementModel.chargeFeatureId) {
      this.toastr.warning("Lütfen şarj gücü seçiniz.");
    }
    else {
      this.costMangementService.calculateCost(this.costManagementModel).subscribe(
        (response) => {
          console.log(response);
          if (response.success) {
            this.costManagementResult = response.data;
            this.toastr.success(response.message);
            setTimeout(() => {
              // this.router.navigateByUrl("/home");
            }, 1000);
          } else {
            this.toastr.error(response.message);
          }
        },
        (responseError) => {
          this.toastr.error(responseError.error.message);
        }
      );

    }
  }

}
