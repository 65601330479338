import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, catchError, throwError } from 'rxjs';
import { APP_CONFIG } from 'src/environments/environment';
import { CostManagementModel } from '../models/cost-management/cost-management-model';
import { ResponseModel } from '../models/responseModel';
import { ChargeFeatureModel } from '../models/cost-management/charge-feature-model';
import { CostManagementResultModel } from '../models/cost-management/cost-management-result-model';
import { SingleResponseModel } from '../models/singleResponseModel';

@Injectable({
  providedIn: 'root'
})
export class CostManagementService {

  constructor(private http: HttpClient) { }
  apiUrl = APP_CONFIG.apiUrl + 'api/CostManagement/';

  getUserData(): Observable<CostManagementModel> {
    return this.http.get<CostManagementModel>(this.apiUrl + 'getUserDatas')
  }

  calculateCost(costManagementModel: CostManagementModel): Observable<SingleResponseModel<CostManagementResultModel>> {
    return this.http.post<SingleResponseModel<CostManagementResultModel>>(this.apiUrl + 'calculateCost', costManagementModel);
  }

  getChargeFeautres(): Observable<ChargeFeatureModel[]> {
    return this.http.get<ChargeFeatureModel[]>(this.apiUrl + 'getChargeFeatures')
  }
}
