<section class="space-ptb workplaces-product-list">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <div class="section-title left-divider text-center">
          <h2>{{'Transaction history' | translate}}</h2>
          <p>{{'Transaction Explain' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10">
        
        <div class="product-list mb-2" *ngFor="let history of allHistory">
          <div class="product-info bg-light">
            <div class="product-img d-flex justify-content-center">
              <img class="img-fluid" [src]="getImageSrc(history.chargingStationBrandName,history.electricGroupName)"  alt="image">
            </div>
            <div class="product-content">
              <p>{{history.processDate}} - {{history.startTime}} </p>
              <div *ngIf="history.electricGroupName != 'Istasyon'">
                <h4> {{history.electricGroupName}}</h4>
                <p>{{history.electricTypeName}} </p> 
              </div>
              <div *ngIf="history.electricGroupName == 'Istasyon'">
                <h4>{{history.chargingStationBrandName}}</h4>
                <p>{{history.chargingStationAddress}} </p>
              </div>
            </div>
            <div class="pricing-price">
              <span><sup>₺</sup><strong>{{history.price}}</strong></span>
            </div>
            <button type="submit" class="trans-button" (click)="openPopup(history.id)">{{'Transaction Details' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-popup *ngIf="showPopup" [id]="selectedId"  (close)="showPopup = false"></app-popup>