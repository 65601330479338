import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from 'src/environments/environment';
import { Observable, Observer, catchError, throwError } from 'rxjs';
import {StationForCardModel} from '../models/map/station-for-card-model';
@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) { }

  
  // apiUrl = 'https://localhost:7071/api/googlePlaces/';
  apiUrl = APP_CONFIG.apiUrl + 'api/ChargingStation/';

  getAll(latitudeNumber:number,longitudeNumber:number): Observable<StationForCardModel[]> {
    return this.http.get<StationForCardModel[]>(this.apiUrl+`getStationsForCard?latitude=${latitudeNumber}&longitude=${longitudeNumber}`);
  }
  getAllByRegion(latitude:number,longitude:number,latitudeDelta:number,longitudeDelta:number): Observable<StationForCardModel[]> {
    return this.http.get<StationForCardModel[]>(this.apiUrl+`getStationsInRegion?latitude=${latitude}&longitude=${longitude}&latitudeDelta=${latitudeDelta}&longitudeDelta=${longitudeDelta}`);
  }
  getAllBrandName(){
    return this.http.get(this.apiUrl+'getAllBrandNameList');
  }
}
