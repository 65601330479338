<!--=================================
service -->
<section style="padding: 25px 0;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title">
          <h2>{{'Cost Managament Header' | translate}}</h2>
          <p class="mb-0">{{'Cost Managament Content' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-6">
        <img class="img-fluid" src="assets/images/home-03/cost1.webp" alt="#">
      </div>
    </div>
  </div>
</section>
<!--=================================
service -->


<!--=================================
service -->
<section class="space-ptb bg-dark workplaces-service-box">
  <div class="container">
    <div class="row align-items-center mb-lg-5 mb-md-3 mb-4">
      <div class="col-lg-6">
        <div class="section-title left-divider mb-3 mb-lg-0">
          <span>{{'Manage Your Charging Cost' | translate}}</span>
          <h2 class="text-white">{{'SPECIAL PROFILES FOR YOU' | translate}}</h2>
        </div>
      </div>
      <div class="col-lg-6">
        <ul>
          <li class="mb-3 text-white">{{'Special Profile Content-One' | translate}}</li>
          <li class="mb-3 text-white">{{'Special Profile Content-Two' | translate}}</li>
          <li class="mb-3 text-white">{{'Special Profile Content-Three' | translate}}</li>
          <li class="mb-3 text-white">{{'Special Profile Content-Four' | translate}}</li>
          <li class="mb-0 text-white">{{'Special Profile Content-Five' | translate}}.</li>
        </ul>
        <!-- <p class="mb-3 text-white"></p> -->

      </div>
    </div>

    <div class="row mt-2 mt-lg-5">
      <div class="col-md-6 col-lg-6 col-xl-3">
        <div class="feature-item feature-info-style-01 feature-info-center" style="height: 100%;">
          <div class="feature-icon">
            <object type="image/svg+xml" data="assets/images/svg/charging-station.svg" class="icon "></object>
          </div>
          <div class="feature-content">
            <h4 class="feature-title">{{'Charging at Stations' | translate}}</h4>
            <p>
              {{'Charging at Stations Content' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-3">
        <div class="feature-item feature-info-style-01 feature-info-center active" style="height: 100%;">
          <div class="feature-icon">
            <object type="image/svg+xml" data="assets/images/svg/fast-charging.svg" class="icon "></object>
          </div>
          <div class="feature-content">
            <h4 class="feature-title">{{'Charging at Home' | translate}}</h4>
            <p>
              {{'Charging at Home Content2' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-3">
        <div class="feature-item feature-info-style-01 feature-info-center" style="height: 100%;">
          <div class="feature-icon">
            <object type="image/svg+xml" data="assets/images/svg/rapid-charging.svg" class="icon "></object>
          </div>
          <div class="feature-content">
            <h4 class="feature-title">{{'Fast Charging' | translate}}</h4>
            <p>
              {{'Fast Charging Content' | translate}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-3">
        <div class="feature-item feature-info-style-01 feature-info-center mb-0" style="height: 100%;">
          <div class="feature-icon">
            <object type="image/svg+xml" data="assets/images/svg/charge-point-stations.svg" class="icon "></object>
          </div>
          <div class="feature-content">
            <h4 class="feature-title">{{'Temporary Use' | translate}}</h4>
            <p>
              {{'Temporary Use Content' | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
service -->

<!--=================================
newslater -->
<!-- <section class="space-ptb workplaces-product-list">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-7">
        <div class="section-title left-divider text-center">
        <span>Product List</span>
        <h2>EV Charging Product List</h2>
        <p>Execution is the single biggest factor in achievement, so the faster and better your execution, the quicker you will get to the goals!</p>
        </div>
    </div>
    </div>
    <div class="row justify-content-center">
    <div class="col-md-10">
        <div class="product-list mb-2">
        <div class="product-info bg-light">
            <div class="product-img">
            <img class="img-fluid" src="images/product-list/01.jpg" alt="image">
            </div>
            <div class="product-content">
            <h6>Tarang Fast Charging</h6>
            <p>Commitment is something that comes from understanding that everything has its price and then willingness to pay that price.</p>
            <ul class="list-unstyled">
                <li class="me-2"><a href="#"><object type="image/svg+xml" data="images/svg/charging-01.svg" class="icon pe-1"></object>3 hour charging Range 405 mi</a></li>
                <li><a href="#"><object type="image/svg+xml" data="images/svg/charging-02.svg" class="icon pe-1"></object>Supercharging Max 250 kW</a></li>
            </ul>
            </div>
            <div class="pricing-price">
            <span><sup>$</sup><strong>99</strong> /day</span>
            </div>
            <button type="submit" class="btn btn-primary">Learn More</button>
        </div>
        </div>
        <div class="product-list mb-2">
        <div class="product-info bg-light">
            <div class="product-img">
            <img class="img-fluid" src="images/product-list/02.jpg" alt="image">
            </div>
            <div class="product-content">
            <h6>DC 001 EV Charger CCS</h6>
            <p>This is important because nobody wants to put significant effort into something, only to find out after that the price was too high.</p>
            <ul class="list-unstyled">
                <li class="me-2"><a href="#"><object type="image/svg+xml" data="images/svg/charging-01.svg" class="icon pe-1"></object>1 hour charging Range 405 mi</a></li>
                <li><a href="#"><object type="image/svg+xml" data="images/svg/charging-02.svg" class="icon pe-1"></object>Supercharging Max 380 kW</a></li>
            </ul>
            </div>
            <div class="pricing-price">
            <span><sup>$</sup><strong>89</strong> /day</span>
            </div>
            <button type="submit" class="btn btn-primary">Learn More</button>
        </div>
        </div>
        <div class="product-list">
        <div class="product-info bg-light">
            <div class="product-img">
            <img class="img-fluid" src="images/product-list/03.jpg" alt="image">
            </div>
            <div class="product-content">
            <h6>Rian Automatic Phase Changer</h6>
            <p>Execution is the single biggest factor in achievement, so the faster and better your execution, the quicker you will get to the goals!</p>
            <ul class="list-unstyled">
                <li class="me-2"><a href="#"><object type="image/svg+xml" data="images/svg/charging-01.svg" class="icon pe-1"></object>2 hour charging Range 405 mi</a></li>
                <li><a href="#"><object type="image/svg+xml" data="images/svg/charging-02.svg" class="icon pe-1"></object>Supercharging Max 230 kW</a></li>
            </ul>
            </div>
            <div class="pricing-price">
            <span><sup>$</sup><strong>79</strong> /day</span>
            </div>
            <button type="submit" class="btn btn-primary">Learn More</button>
        </div>
        </div>
    </div>
    </div>
</div>
</section> -->
<!--=================================
newslater -->

<!--=================================
accordion -->

<section class="space-ptb">
  <!-- <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title mb-4">
          <h3 class="title fw-600">Billing details</h3>
        </div>
        <div class="checkout checkout-form">
          <form class="row" (submit)="submitForm()">
            <div class="mb-3 col-sm-6">
              <label class="form-label">Başlangıç Saati*</label>
              <input class="form-control" type="time" name="startTime" [(ngModel)]="costManagementModel.startTime" />
            </div>
            <div class="mb-3 col-sm-6">
              <label class="form-label">Bitiş Saati*</label>
              <input class="form-control" type="time" name="endTime" [(ngModel)]="costManagementModel.endTime" />
            </div>
            <div class="mb-3 col-sm-6">
              <label class="form-label">Araç*</label>
              <select class="form-select" (change)="onElectricVehicleSelected($event)">
                <option selected disabled value="">Araç Seç</option>
                <option *ngFor="let option of electricVehicles" [value]="option.id"
                  [selected]="option.id === costManagementModel.electricVehicleId">{{ option.brand }} {{
                  option.model }}</option>
              </select>
            </div>

            <div class="mb-3 col-sm-6">
              <label class="form-label">Tarife*</label>
              <select class="form-select" (change)="onElectricTypeSelected($event)">
                <option selected disabled value="">Elektrik Tipi Seç</option>
                <option *ngFor="let option of electricTypes" [value]="option.electricTypeKey"
                  [selected]="option.electricTypeKey === costManagementModel.electricTypeKey">{{ option.name }}</option>

              </select>
            </div>

            <div class="mb-3 col-sm-6">
              <label class="form-label">Mesken*</label>
              <select class="form-select" (change)="onElectricGroupSelected($event)">
                <option selected disabled value="">Elektrik Grup Seç</option>
                <option *ngFor="let option of electricGroups" [value]="option.electricGroupKey"
                  [selected]="option.electricGroupKey === costManagementModel.electricGroupKey">{{ option.name }}
                </option>
              </select>
            </div>

            <div class="mb-3 col-sm-6">
              <label class="form-label">Şarj Gücü*</label>
              <select class="form-select" (change)="onChargeFeatureSelected($event)">
                <option selected disabled value="">Şarj Gücü Seç</option>
                <option *ngFor="let option of chargeFeatures" [value]="option.id"> {{option.setupType}} - <b> {{
                    option.chargePower }} kW </b></option>
              </select>
            </div>

            <div class="d-flex justify-content-end pt-3">
              <button type="submit" class="btn btn-success btn-lg ms-2">Hesapla</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Table -->
  <!--<div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title left-divider text-center">
          <span>Şarj Maliyeti Hesapla</span>
          <h2>Evde Şarj Maliyeti</h2>
        </div>
      </div>
      <div class="col-lg-8 mb-5 mb-lg-0">
        <div class="col-md-12">
           <div class="section-title mb-4">
            <h3 class="title fw-600">Billing details</h3>
          </div> 
          <div class="checkout checkout-form">
            <form class="row" (submit)="submitForm()">
              <div class="mb-3 col-sm-6">
                <label class="form-label">Başlangıç Saati*</label>
                <input class="form-control" type="time" name="startTime" [(ngModel)]="costManagementModel.startTime" />
              </div>
              <div class="mb-3 col-sm-6">
                <label class="form-label">Bitiş Saati*</label>
                <input class="form-control" type="time" name="endTime" [(ngModel)]="costManagementModel.endTime" />
              </div>
              <div class="mb-3 col-sm-6">
                <label class="form-label">Araç*</label>
                <select class="form-select" (change)="onElectricVehicleSelected($event)">
                  <option selected disabled value="">Araç Seç</option>
                  <option *ngFor="let option of electricVehicles" [value]="option.id"
                    [selected]="option.id === costManagementModel.electricVehicleId">{{ option.brand }} {{
                    option.model }}</option>
                </select>
              </div>

              <div class="mb-3 col-sm-6">
                <label class="form-label">Tarife*</label>
                <select class="form-select" (change)="onElectricTypeSelected($event)">
                  <option selected disabled value="">Elektrik Tipi Seç</option>
                  <option *ngFor="let option of electricTypes" [value]="option.electricTypeKey"
                    [selected]="option.electricTypeKey === costManagementModel.electricTypeKey">{{ option.name }}
                  </option>

                </select>
              </div>

              <div class="mb-3 col-sm-6">
                <label class="form-label">Mesken*</label>
                <select class="form-select" (change)="onElectricGroupSelected($event)">
                  <option selected disabled value="">Elektrik Grup Seç</option>
                  <option *ngFor="let option of electricGroups" [value]="option.electricGroupKey"
                    [selected]="option.electricGroupKey === costManagementModel.electricGroupKey">{{ option.name }}
                  </option>
                </select>
              </div>

              <div class="mb-3 col-sm-6">
                <label class="form-label">Şarj Gücü*</label>
                <select class="form-select" (change)="onChargeFeatureSelected($event)">
                  <option selected disabled value="">Şarj Gücü Seç</option>
                  <option *ngFor="let option of chargeFeatures" [value]="option.id"> <b> {{
                    option.chargePower }} kW </b> - {{option.setupType}} </option>
                </select>
              </div>

              <div class="d-flex justify-content-end update-cart mt-3 mt-md-0">
                <button type="submit" class="btn btn-primary">Hesapla</button>
                <div class="update-cart mt-3 mt-md-0"> <a class="btn btn-primary" href="#"> Update cart</a></div> 
              </div>
            </form>
          </div>
        </div>
       
        <br/>
          <div class="col-lg-12" *ngIf="costManagementResult.message">
            <p class="checkout-info bg-light mb-4">
              <span class="coupon-icon">
                <object type="image/svg+xml" data="images/svg/coupon.svg" class="icon "></object> 
                <i *ngIf="!costManagementResult.status" class="fa-solid fa-triangle-exclamation" style="font-size: 50px;color:#ffc107;"></i>
                <i *ngIf="costManagementResult.status" class="fa-regular fa-square-check" style="font-size: 50px;color:#28a745;"></i>
              </span>
             
              <span class="text-secondary" style="margin-left: 15px;">
                 {{costManagementResult.message}}
              </span>
            </p>
          </div>
      
      </div>
      <div class="col-lg-4">
        <div class="sidebar" *ngIf="costManagementResult.electricType">
          <div class="cart-totals widget mb-0">
            <h4 class="cart-totals-title fw-600">{{costManagementResult.electricType}}</h4>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <ng-container *ngFor="let item of costManagementResult.timeUnits">
                    <tr class="shipping" style="border-width: 0px !important;">
                      <th>{{item.name}} Başlangıç</th>
                      <td><span class="subtotal">{{item.startTime}}</span></td>
                    </tr>
                    <tr class="shipping" style="border-width: 0px !important;">
                      <th>{{item.name}} Bitiş</th>
                      <td><span class="subtotal">{{item.endTime}}</span></td>
                    </tr>

                    <tr class="shipping">
                      <th>{{item.name}} Ücret</th>
                      <td><span class="subtotal">{{item.price}} ₺</span></td>
                    </tr>

                  </ng-container>
                 
                  <tr class="shipping" style="border-width: 0px !important;">
                    <th>Toplam Süre</th>
                    <td><span class="subtotal">{{costManagementResult.totalMinute}}</span></td>
                  </tr>

                  <tr class="shipping" style="border-width: 0px !important;">
                    <th>Km Başı Maliyet</th>
                    <td><span class="subtotal">{{costManagementResult.kmOfPrice}} ₺</span></td>
                  </tr>

                  <tr class="shipping" style="border-width: 0px !important;">
                    <th>Kaç Km Şarj</th>
                    <td><span class="subtotal">{{costManagementResult.kmOfCharge}} Km</span></td>
                  </tr>
 
                  <tr class="order-total">
                    <th>Toplam Ücret</th>
                    <td>
                      <span class="amount">{{costManagementResult.totalPrice}} ₺</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center">
              <a href="shop-checkout.html" class="btn btn-success checkout-button">Şarj Asistanım Önerisi Al</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
-->
</section>

<section class="space-ptb bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title left-divider text-center">
          <span>{{'FAQ' | translate}}</span>
          <h2>{{'Frequently Asked Questions' | translate}}</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                {{'FAQ Q-1' | translate}}
              </button>
            </h3>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                {{'FAQ A-1' | translate}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                {{'FAQ Q-2' | translate}}
              </button>
            </h3>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                {{'FAQ A-2' | translate}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                {{'FAQ Q-3' | translate}}
              </button>
            </h3>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                {{'FAQ A-3' | translate}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                {{'FAQ Q-4' | translate}}
              </button>
            </h3>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                {{'FAQ A-4' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--=================================
accordion -->