import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register.component';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'kayit',
    component: RegisterComponent,
    canActivate: [LoggedInGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterRoutingModule {}
