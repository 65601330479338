import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogCategoryModel, BlogModel } from 'src/app/models/blog/blog-model';
import { BlogService } from 'src/app/services/blog.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent {

  blog: BlogModel;
  allBlogs: BlogModel[] = [];
  categories : BlogCategoryModel[] = [];
  filteredBlogs : BlogModel[] = [];
  searchText = '';

  jsonDataEn: any;
  jsonDataTr: any;

  constructor(private route: ActivatedRoute, private service: BlogService, private sanitizer: DomSanitizer, private http: HttpClient) { }

  ngOnInit(): void {

    this.http.get('../../../assets/i18n/en.json').subscribe((data) => {
      this.jsonDataEn = data;
      console.log(this.jsonDataEn);
    });

    this.http.get('../../../assets/i18n/tr.json').subscribe((data) => {
      this.jsonDataTr = data;
      console.log(this.jsonDataTr);
    });

    this.getBlog();

    this.getBlogList();
    this.getCategoryList();
  }
  

  getBlog() {
    const id = this.route.snapshot.paramMap.get('id');
    return this.service.getBlog(Number(id)).subscribe(data =>this.blog = {
      ...data,
      dateTime: data.dateTime.split('T')[0],
    });
  }

  getBlogList(): void {
    this.service
      .getAll()
      .subscribe((response: BlogModel[]) => {
        this.allBlogs = response;
        this.allBlogs.forEach(element => {
          element.dateTime=element.dateTime.split('T')[0];
        });
        console.log(this.allBlogs);
      });
  }

  getCategoryList():void {
    this.service
      .getCategories()
      .subscribe((response: BlogCategoryModel[]) => {
        this.categories = response;

        console.log(this.allBlogs);
      });
  }

  filterBlogs() {
    this.filteredBlogs = this.allBlogs.filter(blog =>
        blog.title.toLowerCase().includes(this.searchText.toLowerCase())
    );

    console.log(this.filteredBlogs);
  }

  getContent(blogKey:string):SafeHtml{
    const lang = localStorage.getItem('lang') || 'tr';

    if(lang == "tr"){
      return this.sanitizer.bypassSecurityTrustHtml(this.jsonDataTr[blogKey+"-content"]);
    }
    else{
      return this.sanitizer.bypassSecurityTrustHtml(this.jsonDataEn[blogKey+"-content"]);
    }
    
  }
}
