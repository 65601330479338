import { Component } from '@angular/core';
import { ContactModel } from 'src/app/models/contact/contact-model';
import { ContactService } from 'src/app/services/contact.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  contactModel:ContactModel = new ContactModel();
  constructor(private contactService:ContactService, private toastr: ToastrService,){}
  ngOnInit():void{
    this.sendMail();
  }
  sendMail(): void {
    // console.log(this.registerModel);
    console.log(this.contactModel);

    if (!this.contactModel.name) {
      this.toastr.warning("Lütfen isim alanımı doldurunuz");
    }
    else if (!this.contactModel.mail) {
      this.toastr.warning("Lütfen mail alanımı doldurunuz");
    }
    else if (!this.contactModel.message) {
      this.toastr.warning("Lütfen mesaj alanımı doldurunuz");
    }
    else if (!this.contactModel.phone) {
      this.toastr.warning("Lütfen telefon alanımı doldurunuz");
    }
    else {
      this.contactService.sendMail(this.contactModel).subscribe(
        (response) => {
          console.log(response);
          if (response.success) {
            this.toastr.success(response.message);
            setTimeout(() => {
              // this.router.navigateByUrl("/home");
            }, 1000);
          } else {
            this.toastr.error(response.message);
          }
        },
        (responseError) => {
          this.toastr.error(responseError.error.message);
        }
      );

    }
  }
}
