<div class="popup-overlay" (click)="closePopup()">
    <div class="popup-content" (click)="$event.stopPropagation()" >
    <button class="button-close" (click)="closePopup()"><i class="fa fa-times" aria-hidden="true"></i></button>
      <h2 class="text-center mt-3">{{'Transaction Details' | translate}}</h2>
      <div class="main">
      <p class="mt-3"><b>{{History.vehicleBrand}} - {{History.vehicleModel}}</b></p>
      <p>{{History.vehicleCapacity}}</p>
      <hr>
      
        <div class="station" *ngIf="History.electricGroupName == 'Istasyon'">
          <p><b>Tarih</b><span>{{History.processDate}}</span></p>
          <p><b>Başlangıç Saati</b><span>{{History.startTime}}</span></p>
          <p><b>Bitiş Saati</b> <span>{{History.endTime}}</span></p>
          <p><b>Toplam Süre</b><span>{{History.totalMinute}}</span></p>
          <p><b>Bağlantı Türü</b><span>{{History.socketType}} - {{History.socketPW}}kW</span></p>
          <p><b>Şarj Kilometresi</b><span>{{History.kmOfCharge}}</span></p>
          <p><b>Km Fiyat</b><span>{{History.kmOfPrice}}</span></p>
        </div>
        <div class="home"*ngIf="History.electricGroupName != 'Istasyon'">
          <p><b>Tarih</b><span>{{History.processDate}}</span></p>
          <p><b>Başlangıç Saati</b><span>{{History.startTime}}</span></p>
          <p><b>Bitiş Saati</b> <span>{{History.endTime}}</span></p>
          <p><b>Toplam Süre</b><span>{{History.totalMinute}}</span></p>
          <p><b>Bağlantı Türü</b><span>{{History.socketType}} - {{History.socketPW}}kW</span></p>
          <p><b>Şarj Kilometresi</b><span>{{History.kmOfCharge}}</span></p>
          <p><b>Km Fiyat</b><span>{{History.kmOfPrice}}</span></p>
        </div>
        
      
      
      <p class="mt-3 text-center">{{History.startMessage}}</p>
    </div>
      <p class="text-center">{{History.message}}</p>
      <p class="text-center"><b>Toplam Maliyet : </b>{{History.price}}</p>
    </div>
  </div>