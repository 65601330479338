<div class="container">
    <h3>Bireysel Hesap Sözleşmesi</h3>

    <h5>1. Taraflar</h5>
    <p>
        İşbu Bireysel Hesap Sözleşmesi (bundan böyle “Hesap Sözleşmesi” olarak anılacaktır); “Kavacık Mah. Öge Sk. No: 20/17 Beykoz / İstanbul” adresinde mukim UNITED YAZILIM TEKNOLOJİLERİ A.Ş. markası hizmet sağlayıcı (bundan böyle kısaca “ŞARJSEPETİ” olarak anılacaktır) ile www.sarjsepeti.com Portalda hesap açmak için işbu Hesap Sözleşmesi ile ayrılmaz parçası olan eklerine ve sarjsepeti.com Portalında yer alan şartlar ile kurallara onay veren “Hesap Sahibi” arasında elektronik ortamda akdedilerek yürürlüğe girmiştir.
    </p>

    <h5>2. Tanımlar</h5>
    <p>
        <strong>“Portal”:</strong> www.sarjsepeti.com isimli alan adından ve bu alan adına bağlı alt alan adlarından oluşan “ŞARJSEPETİ”’in hizmetlerini sunduğu internet sitesi ve/veya mobil uygulamalar.
    </p>
    <p>
        <strong>“Kullanıcı”:</strong> “Portal”a erişen her gerçek veya tüzel kişi.
    </p>
    <p>
        <strong>“Hesap Sahibi”:</strong> “Portal”da hesap açan ve "Portal" dahilinde sunulan hizmetlerden belirlenen koşullar dahilinde yararlanan “Kullanıcı”.
    </p>
    <p>
        <strong>“Bireysel Hesap Sahibi”:</strong> “Portal”da hesap oluşturan, mesleki veya ticari amaç gütmeksizin "Portal" dahilinde sunulan hizmetlerden belirlenen koşullar dahilinde yararlanan “Kullanıcı”.
    </p>
    <p>
        <strong>“Bireysel Hesap” (“Hesap”):</strong> “Bireysel Hesap” oluşturmak isteyen “Kullanıcı”nın, “Portal”daki Bireysel Hesap Formu’nu doğru ve gerçek bilgilerle eksiksiz olarak doldurması üzerine “ŞARJSEPETİ” tarafından yapılan bildirim ile kazanılan statüdür. Hesap işlemleri tamamlanmadan “Bireysel Hesap Sahibi” olma hak ve yetkisine sahip olunamaz. “Bireysel Hesap Sahibi”nin hak ve yükümlülükleri, başvuruda bulunana ait olan, kısmen veya tamamen herhangi bir üçüncü şahsa devredilemeyen hak ve yükümlülüklerdir. Hesap Oluşturma başvurusu herhangi bir sebep gösterilmeksizin “ŞARJSEPETİ” tarafından reddedilebilir veya ek şart ve koşullar talep edilebilir. “ŞARJSEPETİ” gerekli görmesi halinde “Bireysel Hesap Sahibi”nin hesabını kapatabilir, hesabı herhangi bir sebeple kapatılanın yapacağı hesap açma talebini kabul etmeyebilir.
    </p>
    <p>
        <strong>"Hesap Sözleşmesi”:</strong> “Portal”da yer alan şartlar ve koşullar ile eklerden oluşan, kullanıcının “Hesap Sözleşmesi”ni anladığına ve kabul ettiğine ilişkin elektronik olarak verdiği onay neticesinde elektronik ortamda akdedilen, ayrılmaz parçası olan ekler ile “Portal”da yer alan şart ve kurallarla birlikte bir bütün olan elektronik sözleşme.
    </p>
    <p>
        <strong>"ŞARJSEPETİ Hesabı”:</strong> “Hesap Sahibi”nin "Portal" içerisinde sunulan hizmetlerden yararlanmak için gerekli olan iş ve işlemleri gerçekleştirdiği, "Hesap” ile ilgili konularda "ŞARJSEPETİ”ne talepte bulunduğu, "Hesap bilgilerini güncelleyip, sunulan hizmetlerle ilgili raporlamaları görüntüleyebildiği, kendisinin belirlediği ve münhasıran kendisi tarafından kullanılacağını taahhüt ettiği "kullanıcı adı" ve "şifre" ile "Portal" üzerinden eriştiği "Hesap Sahibi"ne özel internet sayfaları bütünü.
    </p>
    <p>
        <strong>“ŞARJSEPETİ Hizmetleri” ("Hizmetler”):</strong> "Portal" içerisinde "Hesap Sahibi"nin işbu “Hesap Sözleşmesi” içerisinde tanımlı olan iş ve işlemlerini gerçekleştirmelerini sağlamak amacıyla “ŞARJSEPETİ” tarafından sunulan uygulamalardır. "ŞARJSEPETİ", "Portal" içerisinde sunulan "Hizmetler"inde dilediği zaman değişiklikler ve/veya uyarlamalar yapabilir. Yapılan değişiklikler ve/veya uyarlamalarla ilgili "Hesap Sahibi"nin uymakla yükümlü olduğu kural ve koşullar “Portal”dan "Hesap Sahibi"ne duyurulur, açıklanan şartlar ve koşullar “Portal”da yayımlandığı tarihte yürürlüğe girer.
    </p>
    <p>
        <strong>“İçerik”:</strong> “Portal”da yayınlanan ve erişimi mümkün olan her türlü bilgi, yazı, dosya, resim, video, rakam vb. görsel, yazımsal ve işitsel imgeler.
    </p>
    <p>
        <strong>"ŞARJSEPETİ Arayüzü”:</strong> ŞARJSEPETİ ve "Hesap Sahibi” tarafından oluşturulan içeriğin "Kullanıcı”lar tarafından görüntülenebilmesi ve "ŞARJSEPETİ Veri tabanı"ından sorgulanabilmesi amacıyla "Kullanıcı”lar tarafından kullanılan; 5846 Sayılı Fikir ve Sanat Eserleri Kanunu kapsamında korunan ve tüm fikri hakları “ŞARJSEPETİ”ne ait olan tasarımlar içerisinde “Portal” üzerinden yapılabilecek her türlü işlemin gerçekleştirilmesi için bilgisayar programına komut veren internet sayfaları ve mobil uygulama ekranları/sayfalarıdır.
    </p>
    <p>
        <strong>“ŞARJSEPETİ Veri Tabanı”:</strong> “Portal” dahilinde erişilen içeriklerin depolandığı, tasnif edildiği, sorgulanabildiği ve erişilebildiği “ŞARJSEPETİ”ne ait olan 5846 Sayılı Fikir ve Sanat Eserleri Kanunu gereğince korunan veri tabanıdır.
    </p>
    <p>
        <strong>“Bana Özel” sayfası:</strong> "Hesap Sahibi"nin "Portal"da yer alan çeşitli uygulamalardan ve "Hizmetler”den yararlanabilmesi için gerekli işlemleri gerçekleştirebildiği, kişisel bilgilerini, tercihlerini, uygulama bazında kendisinden talep eden bilgileri girdiği, sadece "Hesap Sahibi" tarafından belirlenen kullanıcı adı ve şifre ile erişilebilen "Hesap Sahibi"ne özel sayfa.
    </p>
    <p>
        <strong>“Kişisel Veri”:</strong> 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun’un (KVKK) 3/d maddesi uyarınca, kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi ile KVKK m.6/1’de sayılan özel nitelikli kişisel verilerdir.
    </p>
    <p>
        <strong>“Özel nitelikli kişisel veri”:</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.
    </p>

    <h5>3. “Hesap Sözleşmesi”nin Konusu ve Kapsamı</h5>
    <p>
        İşbu “Hesap Sözleşmesi”nin konusu, "Portal"da sunulan “Hizmetler”in, bu “Hizmetler”den yararlanma şartları ile tarafların hak ve yükümlülüklerinin tespitidir. “Hesap Sözleşmesi” ve ekleri ile "Portal" içerisinde yer alan kullanıma, hesaba ve “Hizmetler”e ilişkin “ŞARJSEPETİ” tarafından yapılan tüm uyarı, bildirim, uygulama ve açıklama gibi beyanlar kapsam dâhilindedir. "Kullanıcı", işbu “Hesap Sözleşmesi” hükümlerini kabul etmekle, "Portal" içinde yer alan kullanıma, hesap sahibi olmaya ve hizmetlere ilişkin “ŞARJSEPETİ” tarafından açıklanan her türlü beyana uygun davranacağını kabul ve taahhüt etmektedir.
    </p>

    <h5>4. Hesap Şartları</h5>
    <p>
        <strong>4.1</strong> "Portal"da “Hesap Sahibi” olabilmek için reşit olmak ve “ŞARJSEPETİ” tarafından işbu “Hesap Sözleşmesi” kapsamında hesabın geçici olarak durdurulmamış veya hesabın kapatılmamış olması gerekmektedir.
    </p>
    <p>
        <strong>4.2</strong> “ŞARJSEPETİ” herhangi bir zamanda gerekçe göstermeden, bildirimde bulunmadan, tazminat, ceza vb. sair yükümlülüğü bulunmaksızın derhal yürürlüğe girecek şekilde işbu “Hesap Sözleşmesi”ni tek taraflı olarak feshedebilir, "Hesap Sahibi"nin hesabını kapatabilir veya geçici olarak durdurabilir. "Portal"da belirtilen kurallara aykırılık halleri, “Hesap Sahibi”nin "ŞARJSEPETİ" bilgi güvenliği sistemine risk oluşturması halleri hesabının kapatılması veya hesabın geçici olarak durdurulma hallerindendir.
    </p>

    <h5>5. Tarafların Hak ve Yükümlülükleri</h5>

    <h3>5.1 "Hesap Sahibi"nin Hak ve Yükümlülükleri</h3>

    <p>
        <strong>5.1.1</strong> “Hesap Sahibi", “Portal"da belirtilen kurallara, beyanlara, yürürlükteki tüm mevzuata ve ahlak kurallarına uygun hareket edeceğini, “Hesap Sözleşmesi” hükümleri ile “Portal”daki tüm şart ve kuralları anladığını ve onayladığını kabul ve taahhüt etmektedir.
    </p>
    <p>
        <strong>5.1.2</strong> "Hesap Sahibi”, “ŞARJSEPETİ”nin yürürlükteki mevzuat hükümleri gereğince resmi makamlara açıklama yapmakla yükümlü olduğu durumlarda; “Hesap Sahibi”ne ait gizli/özel/kişisel veri-özel nitelikli kişisel veri/ticari bilgileri resmi makamlara açıklamaya yetkili olduğunu, bu sebeple “ŞARJSEPETİ”den her ne nam altında olursa olsun tazminat talep etmeyeceğini kabul ve taahhüt etmektedir. Bunun haricinde “Hesap Sahibi”nin “Portal” üzerinde verdiği ilanlarla ilgili olarak herhangi bir kişi ya da kurumun haklarının ihlal edildiği iddiası ile “ŞARJSEPETİ”ne bildirimde bulunması, yargı yoluna başvuracağını bildirmesi halinde; “Hesap Sahibi”nin kendisine bildirdiği ad-soyad bilgisini “ŞARJSEPETİ” ilgili tarafa verebilir.
    </p>
    <p>
        <strong>5.1.3</strong> "Hesap Sahibi”nin, “ŞARJSEPETİ Hesabı”na girişte kullandığı "kullanıcı adı" ve "şifre"nin güvenliğini sağlamak kendi sorumluluğunda olup, "kullanıcı adı" ve "şifre"sini münhasıran ve münferiden kendisi kullanması, üçüncü kişilerden saklaması gerekmektedir. Bu konuda ihmal veya kusurlarından dolayı diğer “Hesap Sahibi”nin ve/veya “ŞARJSEPETİ”nin ve/veya üçüncü kişilerin uğradığı veya uğrayabileceği maddi ve/veya manevi her tür zararlardan “Hesap Sahibi” sorumludur.
    </p>
    <p>
        <strong>5.1.4</strong> "Hesap Sahibi", "Portal" dahilinde kendisi tarafından sağlanan bilgilerin ve içeriklerin doğru ve hukuka uygun olduğunu, söz konusu bilgi ve içeriklerin "Portal" üzerinde yayınlanmasının veya bu içeriklerle bağlantılı ürünlerin satışının ve teşhirinin, yürürlükteki mevzuat karşısında herhangi bir hukuka aykırılık yaratmadığını kabul ve taahhüt etmektedir. "ŞARJSEPETİ", "Hesap Sahibi" tarafından "ŞARJSEPETİ”ne iletilen veya "Portal" üzerinden “Hesap Sahibi” tarafından yüklenen bilgilerin ve içeriklerin doğruluğunu araştırmakla yükümlü ve sorumlu olmadığı gibi, söz konusu bilgi ve içeriklerin yanlış veya hatalı olmasından veya yayınlanmasından dolayı ortaya çıkacak hiçbir zarardan sorumlu tutulamaz.
    </p>
    <p>
        <strong>5.1.5</strong> "Hesap Sahibi", "ŞARJSEPETİ”nin yazılı onayı olmadan işbu “Hesap Sözleşmesi”ni veya bu “Hesap Sözleşmesi”nin kapsamındaki hak ve yükümlülüklerini kısmen veya tamamen herhangi bir üçüncü kişiye devredemez.
    </p>
    <p>
        <strong>5.1.6</strong> “Hesap Sahibi” hukuka uygun amaçlarla "Portal" üzerinde işlem yapabilir. "Hesap Sahibi"nin, "Portal" dahilinde yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendisine aittir.  "Hesap Sahibi", "ŞARJSEPETİ”nin ve/veya başka bir üçüncü şahsın ayni veya şahsi haklarına, malvarlığına, kişisel verilerine tecavüz teşkil edecek nitelikteki "Portal" dâhilinde bulunan resimleri, metinleri, görsel ve işitsel imgeleri, video klipleri, dosyaları, veri tabanları, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini, başka veri tabanına aktarmayacağını veya bu nitelikte sonuçlar doğurabilecek şekilde "Portal"a yüklemeyeceğini; bu tür eylemler gerçekleştirerek herhangi bir ticari faaliyette bulunmayacağını; gerek bu eylemleri ile gerekse de başka yollarla doğrudan ve/veya dolaylı olarak haksız rekabet teşkil eden davranış ve işlemler gerçekleştirmeyeceğini kabul ve taahhüt etmektedir. "Hesap Sahibi"nin işbu “Hesap Sözleşmesi” hükümlerine ve hukuka aykırı olarak gerçekleştirdikleri "Portal" üzerindeki faaliyetler nedeniyle üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan dolayı "ŞARJSEPETİ" doğrudan ve/veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.
    </p>
    <p>
        <strong>5.1.7</strong> “ŞARJSEPETİ”, “Hesap Sahibi”nin sadece ilgili ilanların içeriklerini öğrenme amacıyla ilanları görüntülemesine ve “ŞARJSEPETİ Arayüzü”nü kullanmasına izin vermekte olup, bunun dışında bir amaçla veri tabanı üzerinden belirli bir sayıda veya bütününe yönelik olarak ilanlara ulaşılmaya çalışılması, ilanların kısmen veya tamamen kopyalanması, bunların başka mecralarda doğrudan veya dolaylı olarak yayınlanması, derlenmesi, işlenmesi, başka veri tabanlarına aktarılması, bu veri tabanından üçüncü kişilerin erişimine ve kullanımına açılması, “ŞARJSEPETİ” üzerindeki ilanlara link verilmesi de dahil olmak üzere benzer fiillerin işlenmesine “ŞARJSEPETİ” tarafından izin verilmemekte ve rıza gösterilmemektedir. Bu tür fiiller hukuka aykırı olup; "ŞARJSEPETİ’in gerekli talep, dava ve takip hakları saklıdır.
    </p>
    <p>
        <strong>5.1.8</strong> “Hesap Sahibi”, üçüncü şahıslardan aldığı mal ve hizmetlerdeki ayıplarla ilgili “ŞARJSEPETİ”nin herhangi bir sorumluluğu bulunmadığını, Tüketicinin Korunması Hakkındaki Kanun ve ilgili sair mevzuat kapsamındaki her türlü talep ve sorumluluğun muhatabının ilgili mal ve hizmetin satıcısına ait olduğunu ve bunlara ilişkin olarak her tür sorumluluk ve yükümlülükten “ŞARJSEPETİ”i ibra ettiğini kabul ve taahhüt etmektedir. 
    </p>
    <p>
        <strong>5.1.9</strong> “Hesap Sahibi”, Hesap oluşturma aşamasında sunduğu bilgilerin değişmesi halinde; yeni ve güncel bilgileri “ŞARJSEPETİ”ne gecikmeksizin bildirmekle yükümlüdür. Bildirmemesi halinde “ŞARJSEPETİ”nin uğrayacağı tüm doğrudan ve dolaylı tüm zarar için “Hesap Sahibi”ne rücu etme hakkı saklıdır.
    </p>
    <p>
        <strong>5.1.10</strong> “Hesap Sahibi”nin “Portal” veya “ŞARJSEPETİ” sistemlerinin güvenliğini tehdit edebilecek ve/veya  diğer kullanıcılara zarar verebilecek eylemlerde bulunması, “Portal”ın ve “Portal”a ait diğer yazılımların çalışmasını veya diğer kullanıcıların “Portal”dan faydalanmasını engelleyebilecek herhangi bir girişimde bulunması, bu sonuçları verecek şekilde sisteme veya “Portal”a orantısız yük bindirmesi; “Portal”ın kaynak kodlarına veya “ŞARJSEPETİ” sistemlerine yetkisiz bir şekilde erişmesi, bu bilgileri kopyalaması, silmesi, değiştirmesi ya da bu yönde denemeler yapması, “Portal”ın çalışmasını engelleyecek yazılımlar kullanması, kullanmaya çalışması veya her türlü yazılım, donanım ve sunucuların çalışmasını aksatması, bozulmasına yol açması, tersine mühendislik yapması, saldırılar düzenlemesi, meşgul etmesi veya bunlara sair surette müdahale etmesi, “ŞARJSEPETİ” sunucularına erişim sağlamaya çalışılması kesinlikle yasaktır.
    </p>

    <h3>5.2 "ŞARJSEPETİ”nin Hak ve Yükümlülükleri</h3>
    <p>
        <strong>5.2.1</strong> "ŞARJSEPETİ", işbu “Hesap Sözleşmesi”nde bahsi geçen hizmetleri; ilgili hizmetlerin sunumuyla ilgili "ŞARJSEPETİ Hesabı" içerisinde belirtilen açıklamalar ve işbu “Hesap Sözleşmesi”nde belirtilen koşullar dâhilinde yerine getirmeyi, işbu “Hesap Sözleşmesi” kapsamında belirtilen hizmetlerin sunulabilmesi için gerekli olan teknolojik altyapıyı tesis etmeyi ve işletmeyi kabul, beyan ve taahhüt eder. İşbu madde içerisinde belirtilen teknolojik altyapı tesis etme yükümlülüğü, sınırsız ve eksiksiz bir hizmet taahhüdü anlamına gelmemektedir; “ŞARJSEPETİ” her zaman herhangi bir bildirimde bulunmadan işbu “Hesap Sözleşmesi” ile belirlenen hizmetlerini ve teknolojik altyapısını durdurabilir veya son verebilir.
    </p>
    <p>
        <strong>5.2.2</strong> "ŞARJSEPETİ", "Portal"da sunulan hizmetleri ve içerikleri her zaman değiştirebilme; "Hesap Sahibi”nin sisteme yüklediği bilgileri ve içerikleri "Portal" kullanıcıları da dâhil olmak üzere üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır. "ŞARJSEPETİ", bu hakkını hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir. "Hesap Sahibi”, "ŞARJSEPETİ”nin talep ettiği değişiklik ve/veya düzeltmeleri ivedi olarak yerine getirmek zorundadırlar. Değişiklik ve/veya düzeltmeleri gerekli görüldüğü takdirde "ŞARJSEPETİ" yapabilir. "ŞARJSEPETİ" tarafından talep edilen değişiklik ve/veya düzeltme taleplerinin, kullanıcılar tarafından zamanında yerine getirilmemesi sebebiyle doğan veya doğabilecek zararlar, hukuki ve cezai sorumluluklar tamamen kullanıcılara aittir.
    </p>
    <p>
        <strong>5.2.3</strong> "Portal" üzerinden, "Satıcı"lar, sağlayıcılar ve başkaca üçüncü kişilerin sahip olduğu ve işlettiği başka internet sitelerine ve/veya portallara, dosyalara veya içeriklere link verilebilir. Bu linkler "Hesap Sahibi” tarafından veya sadece referans kolaylığı açısından "ŞARJSEPETİ" tarafından sağlanmış olabilir. Ancak linkin yöneldiği internet sitesini veya işleten kişisini veya içerdiği bilgileri desteklemek veya herhangi bir açıdan doğrulama veya garanti etme amacı taşımamaktadır. "Portal" üzerindeki linkler vasıtasıyla erişilen portallar, internet siteleri, dosyalar ve içerikler, bu linkler vasıtasıyla erişilen portallar veya internet sitelerinden sunulan hizmetler veya ürünler veya bunların içeriği hakkında "ŞARJSEPETİ”nin herhangi bir sorumluluğu yoktur.
    </p>
    <p>
        <strong>5.2.4</strong> "ŞARJSEPETİ", "Portal"ın işleyişine, hukuka, mevzuata, başkalarının haklarına, “Hesap Sözleşmesi” koşullarına, kişisel verilerin korunmasına, genel ahlak kurallarına aykırı olan mesajları, içerikleri istediği zaman ve şekilde erişimden kaldırabilir; "ŞARJSEPETİ" bu mesaj ve içeriği giren "Hesap Sahibi"nin hesabına herhangi bir bildirim yapmadan son verebilir. “ŞARJSEPETİ”, mevzuatta meydana gelen değişikliklere bağlı olarak “Hesap Sahibi”ne ek yükümlülükler getirebilir, söz konusu yükümlülükler “Portal”da yayımlandığı tarihte yürürlüğe girer.
    </p>
    <p>
        <strong>5.2.5</strong> "ŞARJSEPETİ”nin , "ŞARJSEPETİ" çalışanlarının ve yöneticilerinin, “Portal”da “Hesap Sahibi” ve “Kullanıcı”lar tarafından sağlanan içeriklerin hukuka uygun olup olmadığını, gerçekliğini, doğruluğunu araştırmak ve kontrol etmek yükümlülüğü bulunmamaktadır.
    </p>
    <p>
        <strong>5.2.6</strong> "ŞARJSEPETİ", 5651 sayılı "İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun" kapsamında "Yer Sağlayıcı" olarak faaliyet göstermektedir. ŞARJSEPETİ”i Sözleşme’nin ifası sonucunda kendisi tarafından belirli sonuçların üretileceğini garanti etmemektedir. “ŞARJSEPETİ”, fikri mülkiyet haklarının ihlali, ilan içeriklerinin doğruluğu dâhil ancak bunlarla sınırlı olmamak üzere; “Portal”ın kullanılması ile bağlantılı herhangi bir beyan ve taahhütte bulunmamaktadır. ŞARJSEPETİ ayrıca bakım, onarım çalışmaları, teknik aksaklıklar dahil ancak bunlarla sınırlı olmaksızın herhangi bir sebepten ötürü Sözleşme kapsamındaki hizmetlerin geç verilmesi, kesintiye uğraması veya hizmetin verilmesi ile ilgili “ŞARJSEPETİ” herhangi bir sorumluluk üstlenmemektedir.
    </p>
    <p>
        <strong>5.2.7</strong> ”ŞARJSEPETİ” Portal’ı ve “Hizmetler”i; “Olduğu Gibi” sağlamakta olup, “Portal” ve “Hizmetler”in hatasız, kusursuz, kesintisiz, mükemmel olduğu veya “Hesap Sahibi”nin kendine özel ihtiyaçlarını karşılayacağı şeklinde bir iddia veya taahhütte bulunmamaktadır. “ŞARJSEPETİ” “Portal” üzerinde her türlü değişiklik, güncelleme ve benzeri çalışmalar yapabilir ve “Hesap Sahibi” Portal’ı ve “Hizmetler”i kullanmaya devam ettiği sürece, “Portal”i ve “Hizmetler”i kullandığı haliyle (“Olduğu Gibi”) kabul etmiş sayılır. “ŞARJSEPETİ”:
    </p>
    <ul>
        <li>(i) “Hesap Sahibi”’nin “Portal” ve “Hizmetler”i kullanması, kullanmaması, yanlış kullanması veya “Hesap Sahibi”nin “Hesap Sözleşmesi”nde belirtilen taahhüt ve yükümlülüklerini yerine getirmemesinden;</li>
        <li>(ii) tüm donanım, sistem yazılımı/diğer yazılımlar ve ağ ilişkili işlevden ve bu nedenle ortaya çıkacak arızalardan;</li>
        <li>(iii) iletişim ağı (network), internet bağlantısı, bağlantı hatalarından;</li>
        <li>(iv) “Portal” üzerinde gerçekleştirilecek her türlü değişiklik, güncelleme ve benzeri çalışmalar sonucunda oluşabilecek hata ve arızalardan;</li>
        <li>(v) voltaj dalgalanmalarından, batarya ve elektrik kesilmelerinden, virüs bulaşmasından ve benzeri faktörlerden;</li>
        <li>(vi) işletim sistemi uyumsuzluklarından;</li>
        <li>(vii) yukarıda sayılanlarla sınırlı olmaksızın “Hesap Sahibi”nin “Portal”ı kullanmasını engelleyen uyumsuzlukların veya hataların ortaya çıkmasından kaynaklanan doğrudan veya dolaylı zararlardan, her türlü kar kaybı, veri kaybı, iş kaybı, itibar kaybı, beklenen tasarrufun kaybı, işin kesintiye uğraması gibi her türlü maddi, manevi ve mali zararlardan, bu kapsamda “Hesap Sahibi” veya üçüncü bir tarafça yapılabilecek tazminat taleplerinden sorumlu değildir.</li>
    </ul>
    <p>
        <strong>5.2.8</strong> “Hesap Sahibi”nin başvurusu sırasında verilen bilgilerin yeterli, doğru veya güncel olmadığının yahut yanıltıcı ve kötü maksatlı olduğunun tespit edilmesi, hesap başvurusunda bulunan kişinin başvurusunun önceden reddedilmiş olması, hesabının devam ettiği süreçte “Hesap Sahibi” hakkında şikâyetler veya olumsuz yorumlar alınması ve benzeri sebeplerle; “ŞARJSEPETİ” herhangi bir bildirimde bulunmadan, herhangi bir tazminat yükümlülüğü altında bulunmaksızın işbu sözleşmeyi feshederek “Hesap Sahibi”nin hesabını kapatabilir. Hesabı kapatmadan önce “ŞARJSEPETİ” yetkilileri tarafından gerekli incelemelerin yapılabilmesi için “ŞARJSEPETİ” uygun gördüğü tedbirleri uygulayabilir. Hesabın kapatılması, geçici olarak durdurulması yahut Sözleşme’nin herhangi bir sebeple feshedilmesinin ardından “ŞARJSEPETİ”, kendi takdirine bağlı olarak, “Hesap Sahibi”nin Portal üzerinden paylaşmış olduğu içerikler veya diğer paylaşımların akıbeti konusunda münhasıran karar verme yetkisine sahiptir. 
    </p>

    <h5>6. Gizlilik Politikası</h5>
    <p>
        <strong>6.1</strong> “ŞARJSEPETİ”, "Portal"da "Hesap Sahibi” ile ilgili bilgileri; işbu “Hesap Sözleşmesi” ve işbu “Hesap Sözleşmesi”nin eklerinin biri ve ayrılmaz parçası olan EK-2’teki Gizlilik Politikası kapsamında kullanabilir. “ŞARJSEPETİ” "Hesap Sahibi”ne ait gizli bilgileri ancak Gizlilik Politikası’nda belirtilen koşullar dâhilinde üçüncü kişilere açıklayabilir veya kullanabilir. “Hesap Sahibi”, ilanla ilgili olarak Satıcı ile Alıcı arasında mesajlaşmanın yapılabilmesi için Alıcı sıfatıyla hareket ettiği durumlarda hesap girişi yapmayı ve Alıcı sıfatıyla kendisinin telefon numarasının Satıcı ile paylaşılmasını beyan, kabul ve taahhüt etmektedir.
    </p>
    <p>
        <strong>6.2</strong> “ŞARJSEPETİ”, “Hesap Sözleşmesi” ile belirlenen amaç ve kapsam dışında da “Hesap Sahibi” olma aşamasında talep edilen ad-soyad, telefon numarası, e-posta adresi gibi bilgileri; yürürlükte bulunan mevzuatta öngörülen koşullara tabi şekilde ve gerektiğinde ek onayları ve açık rızaları almak suretiyle sms, e-posta, site içi bilgilendirmeler ve benzeri yöntemlerle, tanıtım ve bilgilendirme amaçlı iletişim faaliyetleri, araştırma, pazarlama faaliyetleri ve istatistikî analizler yapmak amacıyla veya gerektiğinde “Hesap Sahibi” ile temas kurmak için kullanabilir. Yine yürürlükte bulunan mevzuatta öngörülen koşullara tabi şekilde ve gerektiğinde ek onayları ve açık rızaları almak suretiyle kişisel veriler aynı zamanda sarjsepeti.com’un süreçlerini iyileştirme amaçlı araştırmalar yapmak, veri tabanı oluşturmak ve pazar araştırmaları yapmak için “ŞARJSEPETİ”nin işbirliği içinde olduğu firmalara aktarılabilir, bu firmalar tarafından işlenebilir ve kullanılabilir.
    </p>
    <p>
        <strong>6.3</strong> "Hesap Sahibi”nin kişisel veri teşkil edebilecek ve kendisi tarafından Portal’a girilen verileri “ŞARJSEPETİ” tarafından “Hesap Sözleşmesi” kapsamında; “Portal”ın fonksiyonlarının kullandırılması, hizmetlerin ve içeriklerin sağlanması ve yukarıda sayılan amaçlarla toplanmakta ve işlenmektedir. Kişisel Verilerin Korunması Hakkında detaylı bilgi için <a href="https://www.sarjsepeti.com/sozlesmeler/kisisel-verilerin-korunmasi-ve-islenmesi-hakkinda-bilgilendirme-58">https://www.sarjsepeti.com/sozlesmeler/kisisel-verilerin-korunmasi-ve-islenmesi-hakkinda-bilgilendirme-58</a> sayfası ziyaret edilmelidir. “ŞARJSEPETİ” Kişisel Verilerin Korunması Politikası’nda ve sair kişisel veriler ile ilgili politika, prosedür, sözleşme maddeleri ve kullanım koşullarında istediği zaman değişiklik yapabilir. Değişiklikler “Portal”da yayınlandığı anda yürürlüğe girer. “Hesap Sahibi” bu değişiklikleri takip etmekle yükümlü olup, bu değişikliklerle ilgili “ŞARJSEPETİ”den herhangi bir talepte veya zarar ziyan iddiasında bulunamaz.
    </p>
    <p>
        <strong>6.4</strong> ”Hesap Sahibi”, “Portal” dahilinde eriştiği bilgileri; bu bilgileri ifşa eden “Hesap Sahibi”nin veya "ŞARJSEPETİ”nin ifşa ettiği amaçlara uygun olarak kullanmakla yükümlü olup, elde ettiği Kullanıcılara veya üçüncü kişilere ait Kişisel Verilerin korunmasından, işlenmesinden, aktarılmasından ve KVKK kapsamındaki tüm yükümlülüklerden kendisinin sorumlu olduğunu, ŞARJSEPETİ’in kendi sistemlerinde internet sitesinin kullanımı için zorunlu olarak topladığı kişisel veriler dışındaki hiçbir kişisel veri için KVKK kapsamında herhangi bir yükümlülüğünün ve sorumluluğunun olmadığını kabul, beyan ve taahhüt eder. “Hesap Sahibi”; kişisel verilerin korunması yükümlülüğüne aykırı hareket etmesi veya kişisel verileri işlemesi, aktarması veya sair surette bir işleme konu etmesi ve bu kullanımın bir ihlal meydana getirmesi durumunda, Kişisel Verileri Koruma Kurulu’nun veya idari makamların veya mahkemelerin kişisel verilerle ilgili olarak verdikleri kararlar neticesinde ŞARJSEPETİ’in bir zarara uğraması durumunda bu zararı ilk talepte nakden ve defaten tazmin edeceğini kabul, beyan ve taahhüt eder.
    </p>
    <p>
        <strong>6.5</strong> “Hesap Sahibi”, bir Avrupa Birliği mukimi gerçek kişi olması durumunda kendi kişisel verileri ile ilgili olarak 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun ve ikincil düzenlemeleri ile Kişisel Verileri Koruma Kurulu kararlarının ve düzenlemelerinin uygulanacağını, çıkabilecek tüm hukuki sorunlarda kanunlar ihtilafı kurallarına bakılmadan Türk iç hukukunun uygulanacağını, 95/46/EC sayılı Avrupa Birliği Direktifi’nin ve Mayıs 2018 tarihinde Avrupa Birliği’nde yürürlüğe giren Genel Veri Koruma Regülasyonu’nun (GDPR) uygulanmayacağını, Türk Milletlerarası Özel Hukuk ve Usul Hukuku Hakkında Kanun’un kanunlar ihtilafı kaidelerinin uygulanmasını talep etmekten feragat ettiğini kabul, beyan ve taahhüt eder.
    </p>
    <p>
        <strong>6.6</strong> “Hesap Sahibi”, “ŞARJSEPETİ” hesabı ve/veya yayınladığı ilanlar nedeniyle alenileşmiş olan kişisel verilerinin Google veya diğer arama motorları veya benzer verileri indeksleyen sistemler tarafından kaydedilmesi, işlenmesi, arama sonuçlarında çıkarılması gibi durumlar için “ŞARJSEPETİ”nin herhangi bir dahlinin, katkısının olmadığını ve kişisel verilerinin indesklenmek veya sair yöntemlerle aramaya mahsus yapılandırılmış sistemlerde herhangi bir şekil ve surette işlenmesi veya aktarılmasından dolayı “ŞARJSEPETİ”nin herhangi bir sorumluluğunun olmadığını, veri sahibi olarak yapacağı başvuru taleplerini başta indekslemeyle ilgili süreçlerde veri sorumlusu sıfatını haiz olan Google olmak üzere diğer arama motorları veya arama sistemleri, indeskleme sistemleri işleten veri sorumlusu gerçek ve/veya tüzel kişilere yöneltmeyi kabul, beyan ve taahhüt eder.
    </p>
    <p>
        <strong>6.7</strong> “Hesap Sahibi” ilan verirken veya “ŞARJSEPETİ” Portal’ı kullandığı esnada özel nitelikli kişisel verilerini ifşa etmeyeceğini, ifşa etmesi durumunda ise bu verilerin işlenmesiyle ilgili olarak “ŞARJSEPETİ”’in herhangi bir sorumluluğunun bulunmadığını bildiğini kabul, beyan ve taahhüt eder.
    </p>

    <h5>7. Fikri Mülkiyet Hakları</h5>
    <p>
        “Portal” dahilinde erişilen veya hukuka uygun olarak kullanıcılar tarafından sağlanan bilgiler ve bu “Portal”ın (sınırlı olmamak kaydıyla "ŞARJSEPETİ Veri Tabanı", "ŞARJSEPETİ Arayüzü", tasarım, metin, imge, html kodu ve diğer kodlar) tüm elemanları (hepsi birlikte "ŞARJSEPETİ”nin telif haklarına tabi çalışmaları” olarak anılacaktır) "ŞARJSEPETİ”ne aittir ve/veya "ŞARJSEPETİ" tarafından üçüncü bir kişiden lisans altında alınmıştır "Hesap Sahibi”, "ŞARJSEPETİ" hizmetlerini, "ŞARJSEPETİ" bilgilerini ve "ŞARJSEPETİ”nin  fikri haklarına ve telif haklarına tabi çalışmalarını satmak, işlemek, paylaşmak, dağıtmak, sergilemek veya başkasının "ŞARJSEPETİ”nin hizmetlerine erişmesi veya kullanmasına izin vermek hakkına sahip değildirler. İşbu "Portal Kullanım Koşulları" dâhilinde "ŞARJSEPETİ" tarafından açıkça izin verilen durumlar haricinde "ŞARJSEPETİ”nin telif haklarına tabi çalışmalarını çoğaltamaz, işleyemez, dağıtamaz veya bunlardan türemiş çalışmalar yapamaz. İşbu “Hesap Sözleşmesi”nin akdi ile “ŞARJSEPETİ”, “Portal”ın “Hesap Sözleşmesi” koşulları çerçevesinde ve “Hizmetler”den faydalanılması amacıyla sınırlı olarak kullanılmasına ilişkin “Hesap Sahibi”ne münhasır olmayan ve devredilemez bir kullanım hakkı (basit ruhsat) tesis etmektedir.
    </p>

    <h5>8. “Hesap Sözleşmesi” Değişiklikleri</h5>
    <p>
        "ŞARJSEPETİ", tamamen kendi takdirine bağlı ve tek taraflı olarak işbu “Hesap Sözleşmesi”ni uygun göreceği herhangi bir zamanda "Portal"da yayınlayarak değiştirebilir. İşbu “Hesap Sözleşmesi”nin değişen hükümleri, “Portal”da yayınlandığı tarihte geçerlilik kazanarak yürürlüğe girecek, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu “Hesap Sözleşmesi”, "Hesap Sahibi"nin tek taraflı beyanları ile değiştirilemez.
    </p>

    <h5>9. Mücbir Sebepler</h5>
    <p>
        Hukuken mücbir sebep sayılan tüm durumlarda, "ŞARJSEPETİ" işbu “Hesap Sözleşmesi” ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu ve bunun gibi durumlar, "ŞARJSEPETİ" için, gecikme, eksik ifa etme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için "ŞARJSEPETİ"den herhangi bir ad altında tazminat talep edilemeyecektir. Mücbir sebep terimi; doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları ve bu sebeple meydana gelebilecek arızalar, elektrik kesintisi ve kötü hava koşulları da dâhil ve fakat bunlarla sınırlı olmamak kaydıyla ilgili tarafın makul kontrolü haricinde ve "ŞARJSEPETİ”nin gerekli özeni göstermesine rağmen önleyemediği kaçınılamayacak olaylar olarak yorumlanacaktır.
    </p>

    <h5>10. Uygulanacak Hukuk ve Yetki</h5>
    <p>
        İşbu “Hesap Sözleşmesi”nin uygulanmasında, yorumlanmasında ve işbu “Hesap Sözleşmesi” dâhilinde doğan hukuki ilişkilerin yönetiminde yabancılık unsuru bulunması durumunda Türk kanunlar ihtilafı kuralları hariç olmak üzere doğrudan Türk İç Hukuku uygulanacaktır. İşbu “Hesap Sözleşmesi”nden dolayı doğan veya doğabilecek her türlü ihtilafın hallinde İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkilidir.
    </p>

    <h5>11. Yürürlük</h5>
    <p>
        İşbu “Hesap Sözleşmesi” ve “Hesap Sözleşmesi”yle atıfta bulunulan ve “Hesap Sözleşmesi”nin ayrılmaz bir parçası olan ekler ile “Portal”da yer alan kurallar ve şartlar, "Hesap Sahibi"nin elektronik olarak onay vermesi ile elektronik ortamda akdedilerek yürürlüğe girmiştir. “Hesap Sözleşmesi”nin herhangi bir hükmünün geçersizliği, mevzuata aykırılığı veya uygulanabilir olmaması sözleşmenin geri kalan hükümlerinin yürürlüğünü etkilemeyecektir. 
    </p>
</div>