import { Component, EventEmitter, Output ,Input } from '@angular/core';
import { HistoryModel } from 'src/app/models/history/history-all-model';
import { HistoryService } from 'src/app/services/history.service';
import * as moment from 'moment';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {
  @Input() id: number; 
  @Output() close = new EventEmitter<void>();
  History:HistoryModel;
  constructor(private historyService:HistoryService){}
  closePopup() {
    this.close.emit();
  }
  ngOnInit(): void {
    this.getDetailHistory();
  }
  formatDate(dateString: string): string {
    return moment(dateString).locale('tr').format('DD MMMM YY');
  }
  getDetailHistory():void{
    this.historyService.getDetailHistroy(this.id).subscribe((response:HistoryModel)=>{
      this.History=response;
      this.History.processDate=this.formatDate(this.History.processDate)      
    })
  }
}