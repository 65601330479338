<!-- 
<section class="section-body" >
  <section class="section-content">
    <app-nav [style.visibility]="router.url !='/login' && router.url!='/register' ? 'visible':'hidden'"></app-nav>
    <router-outlet></router-outlet>
    <app-footer [style.visibility]="router.url !='/login' &&  router.url !='/map' && router.url!='/register' ?  'visible':'hidden'"></app-footer>
  </section>
</section> -->

<app-nav></app-nav>
<router-outlet></router-outlet>
<app-footer></app-footer>


