import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, catchError, throwError } from 'rxjs';
import { APP_CONFIG } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private http: HttpClient) { }
  apiUrl = APP_CONFIG.apiUrl + 'api/CityAndDistricts/';


  getCities(){
    return this.http.get<any>(this.apiUrl+'getCities');

  }

  getDistricts(cityId:any){
    return this.http.get<any>(`${this.apiUrl}getDistricts?cityId=${cityId}`)
.pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error occurred:', error);
        return throwError('An error occurred. Please try again later.');
      })
    );
  }

}
