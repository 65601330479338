import { Component } from '@angular/core';
import { HistoryModel } from 'src/app/models/history/history-all-model';
import { HistoryService } from 'src/app/services/history.service';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import * as moment from 'moment';
import chargingStationImages from 'src/app/models/chargingStationImages/chargingStationImages';
import { ChargingStationImages, ChargingStationKey } from 'src/app/models/chargingStationImages/chargingStationImages';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent {
  allHistory:HistoryModel[]=[];
  images: ChargingStationImages = chargingStationImages;
  showPopup=false;
  selectedId: number;
  constructor(private HistoryService: HistoryService) { }
  ngOnInit(): void {
    this.getAllHistory();    
  }
  formatDate(dateString: string): string {
    return moment(dateString).locale('tr').format('DD MMMM YYYY');
  }
  getAllHistory(): void {
    this.HistoryService.getAllHistories().subscribe((response:HistoryModel[])=>{
      this.allHistory=response;
      this.allHistory.forEach(x=>{
        x.processDate=this.formatDate(x.processDate)
        this.images=chargingStationImages        
      })
    })
  }
  openPopup(id: number) {
    this.selectedId = id;
    this.showPopup = true;
  }
  getImageSrc(brand:string,group:string): string {
    if(group == 'Mesken'){
      return this.images['MESKEN']
    }
    else if(group == 'Ticarethane'){
      return this.images['TICARETHANE']
    }
      return this.images[brand] || this.images['default'];
  }
}
