import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';

import { LoginGuard } from 'src/app/guards/login.guard';
import { BlogDetailComponent } from './blog-detail.component';

const routes: Routes = [
  {
    path: 'blog-detay/:id',
    component: BlogDetailComponent
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogDetailRoutingModule {}
