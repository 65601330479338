import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private authService: AuthService, private toastrService: ToastrService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    if (this.authService.isAuthenticated()) {
    
      return true;
    
    }
    else {

      // Kullanıcının yönlendirilmek istediği sayfanın URL'sini kaydedelim
      this.authService.setRedirectUrl(state.url);

      this.router.navigate(["giris"])
      this.toastrService.info("You must login to the system");
      return false;
    }

    
  }

}
