import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'sarj-sepeti';

  constructor(
    public router: Router,
    public translate: TranslateService,
  ){
    this.translate.setDefaultLang('tr');
    this.translate.use(localStorage.getItem('lang') || 'tr');

  }



}
