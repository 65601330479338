   <!--=================================
      Banner -->
      <!--=================================
      Services -->
      <!-- <section class="space-pt">
        <div class="container">
          <div class="row d-flex align-items-center mb-lg-5 mb-md-4 mb-4">
            <div class="col-lg-6">
              <div class="section-title left-divider mb-3 mb-lg-0">
                <span>{{'COST MANAGEMENT' | translate}}</span>
                <h2>{{'Services Header' | translate}}</h2>
              </div>
            </div>
            <div class="col-lg-6">
              <p class="mb-3">{{'Services Paragraph One' | translate}}</p>
              <p class="mb-0">{{'Services Paragraph Two' | translate}}</p>
            </div>
          </div>
          <div class="row mt-lg-5 mt-3">
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="feature-item feature-info-style-01 feature-info-center" style="height: 100%; ">
                <div class="feature-icon">
                  <object type="image/svg+xml" data="assets/images/svg/charging-station.svg" class="icon "></object>
                </div>
                <div class="feature-content">
                  <h4 class="feature-title">{{'Services Feature One Header' | translate}}</h4>
                  <p>{{'Services Feature One Content' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="feature-item feature-info-style-01 feature-info-center active" style="height: 100%;">
                <div class="feature-icon">        
                  <object type="image/svg+xml" data="assets/images/svg/charge-point-stations.svg" class="icon "></object>
                </div>
                <div class="feature-content">
                  <h4 class="feature-title">{{'Services Feature Two Header' | translate}}</h4>
                  <p>{{'Services Feature Two Content' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="feature-item feature-info-style-01 feature-info-center mb-4 mb-md-0" style="height: 100%;">
                <div class="feature-icon">
                  <object type="image/svg+xml" data="assets/images/svg/fast-charging.svg" class="icon "></object>
                </div>
                <div class="feature-content">
                  <h4 class="feature-title">{{'Services Feature Three Header' | translate}}</h4>
                  <p>{{'Services Feature Three Content' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="feature-item feature-info-style-01 feature-info-center mb-0" style="height: 100%;">
                <div class="feature-icon">
                  <object type="image/svg+xml" data="assets/images/svg/rapid-charging.svg" class="icon "></object>
                </div>
                <div class="feature-content">
                  <h4 class="feature-title">{{'Services Feature Four Header' | translate}}</h4>
                  <p>{{'Services Feature Four Content' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <!-- Test  -->
      <section class="space-ptb bg-dark workplaces-service-box">
        <div class="container">
            <div class="row align-items-center mb-lg-5 mb-md-3 mb-4">
                <div class="col-lg-6">
                    <div class="section-title left-divider mb-3 mb-lg-0">
                      <span>{{'COST MANAGEMENT' | translate}}</span>
                      <h2>{{'Services Header' | translate}}</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                  <p class="mb-3 text-white">{{'Services Paragraph One' | translate}}</p>
                  <p class="mb-0 text-white">{{'Services Paragraph Two' | translate}}</p>
                </div>
            </div>
            
            <div class="row mt-2 mt-lg-5">
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="feature-item feature-info-style-01 feature-info-center" style="height: 100%;">
                    <div class="feature-icon">
                        <object type="image/svg+xml" data="assets/images/svg/charging-station.svg" class="icon "></object>
                    </div>
                    <div class="feature-content">
                        <h4 class="feature-title">{{'Charging at Stations' | translate}}</h4>
                        <p>
                            {{'Charging at Stations Content' | translate}}
                        </p>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="feature-item feature-info-style-01 feature-info-center active" style="height: 100%;">
                    <div class="feature-icon">
                        <object type="image/svg+xml" data="assets/images/svg/fast-charging.svg" class="icon "></object>
                    </div>
                    <div class="feature-content">
                        <h4 class="feature-title">{{'Charging at Home' | translate}}</h4>
                        <p>
                            {{'Charging at Home Content2' | translate}}
                        </p>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="feature-item feature-info-style-01 feature-info-center" style="height: 100%;">
                    <div class="feature-icon">
                        <object type="image/svg+xml" data="assets/images/svg/rapid-charging.svg" class="icon "></object>
                    </div>
                    <div class="feature-content">
                        <h4 class="feature-title">{{'Fast Charging' | translate}}</h4>
                        <p>
                            {{'Fast Charging Content' | translate}}
                        </p>
                    </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="feature-item feature-info-style-01 feature-info-center mb-0" style="height: 100%;">
                    <div class="feature-icon">
                        <object type="image/svg+xml" data="assets/images/svg/charge-point-stations.svg" class="icon "></object>
                    </div>
                    <div class="feature-content">
                        <h4 class="feature-title">{{'Temporary Use' | translate}}</h4>
                        <p>
                            {{'Temporary Use Content' | translate}}
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- TEst -->
      <!--=================================
      Services -->
      <!--=================================
      portfolio-->
      <section class="space-pt portfolio overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="section-title left-divider">
                <span>{{'Portfolio Header' | translate}}</span>
                <h2>{{'Portfolio Content' | translate}}</h2>
              </div>
            </div>
            <div class="col-lg-6"></div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="portfolio-slider popup-gallery full-screen">
                <div class="owl-carousel owl-nav-bottom-center ps-0" data-nav-dots="false" data-nav-arrow="true" data-items="2" data-lg-items="2" data-md-items="1" data-sm-items="1"  data-space="30" data-autoheight="true">
                  <div class="item">
                    <div class="portfolio-item">
                      <div class="portfolio-box">
                        <img class="img-fluid" src="assets/images/home-03/slide1.webp" alt="">
                      </div>
                      <!-- <div class="portfolio-info">
                        <div class="portfolio-tag">
                          <a href="#" class="category-title">Photo Gallery</a>
                          <a href="portfolio-details.html" class="title">Drivers Know</a>
                        </div>
                        <a class="portfolio-img" href=""><i class="fa-solid fa-expand"></i></a>                        
                      </div> -->
                    </div>
                  </div>
                  <div class="item">
                    <div class="portfolio-item">
                      <div class="portfolio-box">
                        <img class="img-fluid" src="assets/images/home-03/slide5.webp" alt="">
                      </div>
                      <!-- <div class="portfolio-info">
                        <div class="portfolio-tag">
                          <a href="#" class="category-title">Automobile</a>
                          <a href="portfolio-details.html" class="title">Driving Emotion</a>
                        </div>
                        <a class="portfolio-img" href=""><i class="fa-solid fa-expand"></i></a>
                      </div> -->
                    </div>
                  </div>
                  <div class="item">
                    <div class="portfolio-item">
                      <div class="portfolio-box">
                        <img class="img-fluid" src="assets/images/home-03/slide3.webp" alt="">
                      </div>
                      <!-- <div class="portfolio-info">
                        <div class="portfolio-tag">
                          <a href="#" class="category-title">Electric Vehicle</a>
                          <a href="portfolio-details.html" class="title">Find Your Drive</a>
                        </div>
                        <a class="portfolio-img" href=""><i class="fa-solid fa-expand"></i></a>
                      </div>                      -->
                    </div>
                  </div>
                  <div class="item">
                    <div class="portfolio-item">
                      <div class="portfolio-box">
                        <img class="img-fluid" src="assets/images/home-03/slide4.webp" alt="">
                      </div>
                      <!-- <div class="portfolio-info">
                        <div class="portfolio-tag">
                          <a href="#" class="category-title">News</a>
                          <a href="portfolio-details.html" class="title">Trust It to Us</a>
                        </div>
                        <a class="portfolio-img" href=""><i class="fa-solid fa-expand"></i></a>
                      </div> -->
                    </div>
                  </div>
                  <div class="item">
                    <div class="portfolio-item">
                      <div class="portfolio-box">
                        <img class="img-fluid" src="assets/images/home-03/slide2.webp" alt="">
                      </div>
                      <!-- <div class="portfolio-info">
                        <div class="portfolio-tag">
                          <a href="#" class="category-title">News</a>
                          <a href="portfolio-details.html" class="title">Trust It to Us</a>
                        </div>
                        <a class="portfolio-img" href=""><i class="fa-solid fa-expand"></i></a>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--=================================
      portfolio-->
      <!--=================================
      Get Charging-->
      <section class="bg-secondary get-charging">
        <div class="space-ptb bg-holder" style="background-image: url(./images/home-02/bg-pattern.png);" style="padding: 50px 0 !important;">
        <!-- <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-xxl-5 col-xl-6 col-lg-7 mb-5 mb-lg-0">
              <div class="section-title left-divider">
                <span>LET'S GROW YOUR BUSINESS</span>
                <h2 class="text-white">Building the future together</h2>
                 <p class="text-white">Use a past defeat as a motivator. Remind yourself you have nowhere to go except up as you have already been at the bottom.</p>
              </div>
              <div class="row mt-xxl-5 mt-md-4 mt-3">
                <div class="col-lg-6 mb-3 mb-lg-0">
                  <div class="user-score pie-chart  pie-chart-percentage">
                  <div class="round-chart" data-percent="76" data-color="#2673f8" data-trackcolor="rgb(255 255 255 / 30%)"> <span class="percent"></span></div>
                  <h6 class="chart-title text-white mb-0">Resources Saved</h6>
                </div>
                </div>
                <div class="col-lg-6">
                  <div class="user-score pie-chart  pie-chart-percentage">
                  <div class="round-chart" data-percent="80" data-color="#ffffff" data-trackcolor="rgb(255 255 255 / 30%)"> <span class="percent"></span></div>
                  <h6 class="chart-title text-white mb-0">Emissions Reduced</h6>
                </div>
                </div>
            </div>
            </div>
            <div class="col-xxl-6 col-xl-5 col-lg-4 position-relative text-end offset-xxl-1 offset-xl-1 offset-lg-1">
              <img class="img-fluid position-absolute top-50 start50 translate-middle middle-img" src="images/home-02/02.jpg" alt="">
              <img class="img-fluid" src="images/home-02/01.jpg" alt="">
            </div>
          </div>
        </div> -->
      </div>
      </section>
      <!--=================================
      Get Charging-->
      <!--=================================
      Our Services-->
      <section class="space-ptb">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-lg-6">
              <div class="section-title left-divider mb-0">
                <span>{{'Route Planning' | translate}}</span>
                <h2>{{'Route Planning Content' | translate}}</h2>
              </div>
            </div>
            <div class="col-lg-6 text-start text-lg-end mt-4 mt-lg-0">
              <a href="/rota-planlama" class="btn btn-primary">{{'Get Charging' | translate}}</a>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 col-lg-6">
              <div class="service-item service-info-style-01">
                <div class="service-img">
                  <div class="service-img-inner">
                    <img class="img-fluid" src="assets/images/service/ucuz-sarj.webp" alt="ucuz elektrikli araç şarj">
                  </div>
                </div>
                <div class="service-content">
                  <h3 class="service-title">{{'Focused on Cheap Charge' | translate}}</h3>
                  <p>{{'Focused on Cheap Charge Content' | translate}}</p>
                  <a href="javascript:;">{{'Lets talk' | translate}}<i class="fa-solid fa-arrow-right-long ps-2"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="service-item service-info-style-01">
                <div class="service-img">
                  <div class="service-img-inner">
                    <img class="img-fluid" src="assets/images/service/kritik-sarj.webp" alt="kritik elektrikli araç şarj düzeyi">
                  </div>
                </div>
                <div class="service-content">
                  <h3 class="service-title">{{'Critical Charge Level' | translate}}</h3>
                  <p>{{'Critical Charge Level Content' | translate}}</p>
                  <a href="javascript:;">{{'Lets talk' | translate}}<i class="fa-solid fa-arrow-right-long ps-2"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="service-item service-info-style-01 mb-lg-0">
                <div class="service-img">
                  <div class="service-img-inner">
                    <img class="img-fluid" src="assets/images/service/favori-rota.webp" alt="favori rota">
                  </div>
                </div>
                <div class="service-content">
                  <h3 class="service-title">{{'Favorite Routes' | translate}}</h3>
                  <p>{{'Favorite Routes Content' | translate}}</p>
                  <a href="javascript:;">{{'Lets talk' | translate}}<i class="fa-solid fa-arrow-right-long ps-2"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="service-item service-info-style-01 mb-0">
                <div class="service-img">
                  <div class="service-img-inner">
                    <img class="img-fluid" src="assets/images/service/istasyon-bulucu-rezervasyon.webp" alt="istasyon bulucu rezervasyon">
                  </div>
                </div>
                <div class="service-content">
                  <h3 class="service-title">{{'Station Finder / Reservation' | translate}}</h3>
                  <p>{{'Station Finder / Reservation Content' | translate}}</p>
                  <a href="javascript:;">{{'Lets talk' | translate}}<i class="fa-solid fa-arrow-right-long ps-2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--=================================
      Our Services-->
      <!--=================================
      Section-->
      <!-- <section class="bg-light position-relative">
        <div class="container">
          <div class="row justify-content-end">
              <div class="col-lg-6 position-absolute start-0 top-0 h-100 p-0 pe-5 d-none d-lg-flex" >
                 <div class="img-fluid h-100 w-100" style="background-image: url(assets/images/home-03/electric_vs_fuelOil.png); background-size:contain;background-repeat:no-repeat;background-position:center;">
                 </div>
              </div>
            <div class="col-lg-6 justify-content-start align-items-center d-flex">
              <div class="space-ptb">
                <div class="section-title">
                  <h2>{{'When Buying a New Vehicle' | translate}}</h2>
                  <br>
                  <h2>{{'Electric vs. Fuel Oil' | translate}}</h2>
                  <p>{{'Electric vs. Fuel Oil Content' | translate}}</p>
                </div>
                <div class="list d-sm-flex mb-3">
                  <ul class="list-unstyled me-5">
                    <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Electric vs. Fuel Oil li-one' | translate}}</li>
                    <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Electric vs. Fuel Oil li-two' | translate}}</li>
                    <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Electric vs. Fuel Oil li-three' | translate}}</li>
                  </ul>
                  <ul class="list-unstyled">
                    <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Electric vs. Fuel Oil li-four' | translate}}</li>
                    <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Electric vs. Fuel Oil li-five' | translate}}</li>
                    <li><i class="fa-solid fa-check pe-lg-3 pe-2"></i>{{'Electric vs. Fuel Oil li-six' | translate}}</li>
                  </ul>
                </div>
                <a href="/compare" class="btn btn-primary mt-2">{{'Compare' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!--=================================
      Section-->
      <!--=================================
      Product List -->
      <!-- <section class="space-ptb workplaces-product-list">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7">
              <div class="section-title left-divider text-center">
                <span>Product</span>
                <h2>Charger List</h2>
                <p>As such, success really has nothing to do with luck, coincidence or fate. It really comes down to understanding the steps in the process.</p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="product-list mb-2">
                <div class="product-info bg-light">
                  <div class="product-img">
                    <img class="img-fluid" src="images/product-list/01.jpg" alt="image">
                  </div>
                  <div class="product-content">
                    <h6>Tarang Fast Charging</h6>
                    <p>Commitment is something that comes from understanding that everything has its price and then willingness to pay that price.</p>
                    <ul class="list-unstyled">
                      <li class="me-2"><a href="#"><object type="image/svg+xml" data="images/svg/charging-01.svg" class="icon pe-1"></object>3 hour charging Range 405 mi</a></li>
                      <li><a href="#"><object type="image/svg+xml" data="images/svg/charging-02.svg" class="icon pe-1"></object>Supercharging Max 250 kW</a></li>
                    </ul>
                  </div>
                  <div class="pricing-price">
                    <span><sup>$</sup><strong>99</strong> /day</span>
                  </div>
                  <button type="submit" class="btn btn-primary">Purchase now</button>
                </div>
              </div>
              <div class="product-list mb-2">
                <div class="product-info bg-light">
                  <div class="product-img">
                    <img class="img-fluid" src="images/product-list/02.jpg" alt="image">
                  </div>
                  <div class="product-content">
                    <h6>DC 001 EV Charger CCS</h6>
                    <p>This is important because nobody wants to put significant effort into something, only to find out after that the price was too high.</p>
                    <ul class="list-unstyled">
                      <li class="me-2"><a href="#"><object type="image/svg+xml" data="images/svg/charging-01.svg" class="icon pe-1"></object>1 hour charging Range 405 mi</a></li>
                      <li><a href="#"><object type="image/svg+xml" data="images/svg/charging-02.svg" class="icon pe-1"></object>Supercharging Max 380 kW</a></li>
                    </ul>
                  </div>
                  <div class="pricing-price">
                    <span><sup>$</sup><strong>89</strong> /day</span>
                  </div>
                  <button type="submit" class="btn btn-primary">Purchase now</button>
                </div>
              </div>
              <div class="product-list">
                <div class="product-info bg-light">
                  <div class="product-img">
                    <img class="img-fluid" src="images/product-list/03.jpg" alt="image">
                  </div>
                  <div class="product-content">
                    <h6>Rian Automatic Phase Changer</h6>
                    <p>Execution is the single biggest factor in achievement, so the faster and better your execution, the quicker you will get to the goals!</p>
                    <ul class="list-unstyled">
                      <li class="me-2"><a href="#"><object type="image/svg+xml" data="images/svg/charging-01.svg" class="icon pe-1"></object>2 hour charging Range 405 mi</a></li>
                      <li><a href="#"><object type="image/svg+xml" data="images/svg/charging-02.svg" class="icon pe-1"></object>Supercharging Max 230 kW</a></li>
                    </ul>
                  </div>
                  <div class="pricing-price">
                    <span><sup>$</sup><strong>79</strong> /day</span>
                  </div>
                  <button type="submit" class="btn btn-primary">Purchase now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!--=================================
      Product List -->
      <!--=================================
      Testimonial -->
      <!-- <section class="position-relative testimonial-dark-bg bg-overlay-black-5 space-pt bg-holder" style="background-image: url(./images/testimonial/bg-01.jpg);">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="section-title left-divider">
                <span>Happy Clients</span>
                <h2 class="text-white">What our happy clients say about us</h2>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="testimonial-description">
                <div class="owl-carousel owl-nav-bottom-center ps-0" data-nav-dots="false" data-nav-arrow="true" data-items="1" data-lg-items="1" data-md-items="1" data-sm-items="1"  data-space="30" data-autoheight="true">
                  <div class="item">
                    <div class="testimonial">
                      <div class="testimonial-quote-icon">
                        <img class="img-fluid" src="images/testimonial/quote.svg" alt="">
                      </div>
                      <div class="testimonial-content">
                        <p class="mb-0"><i>The sad thing is the majority of people have no clue about what they truly want. They have no clarity. When asked the question, responses will be superficial at best.</i> </p>
                      </div>
                      <div class="testimonial-author info-right">
                        <div class="testimonial-avatar avatar">
                          <img class="img-fluid" src="images/avatar/02.jpg" alt="">
                        </div>
                        <div class="testimonial-name d-flex align-items-center">
                          <h6 class="author-tittle">Alice Williams</h6>
                          <span class="text-white">Product Designer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="testimonial">
                      <div class="testimonial-quote-icon">
                        <img class="img-fluid" src="images/testimonial/quote.svg" alt="">
                      </div>
                      <div class="testimonial-content">
                        <p class="mb-0 "><i>So, how can we stay on course with all the distractions in our lives? Willpower is a good start, the distractions are everywhere and endless but it’s very difficult to stay on track simply through willpower.</i> </p>
                      </div>
                      <div class="testimonial-author info-right">
                        <div class="testimonial-avatar avatar">
                          <img class="img-fluid" src="images/avatar/01.jpg" alt="">
                        </div>
                        <div class="testimonial-name d-flex align-items-center">
                          <h6 class="author-tittle text-white">Harry Russell</h6>
                          <span class="text-white">Advisors</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!--=================================
      Testimonial -->
      <!--=================================
      Blog -->


      
      <!-- <section class="space-pb station-blog">
        <div class="container">
          <div class="row d-flex align-items-center mb-2 mb-lg-5">
            <div class="col-lg-5">
              <div class="section-title left-divider mb-0">
                <span>Recent Articles</span>
                <h2>Recent articles and news</h2>
              </div>
            </div>
            <div class="col-lg-7 text-lg-end mt-4 mt-lg-0">
              <a href="#" class="btn btn-link btn-lg text-secondary">Get Charging <i class="fa-solid fa-arrow-right-long ms-2"></i></a>
            </div>
          </div>
        </div>
        <div class="container-fluid px-5">
          <div class="row">
            <div class="owl-carousel owl-nav-bottom-center p-0" data-nav-dots="false" data-nav-arrow="false" data-items="4" data-lg-items="4" data-md-items="3" data-sm-items="2"  data-space="30" data-autoheight="true">
              <div class="item" *ngFor="let blog of allBlogs">
                <div class="blog-style-01">
                  <div class="blog-img">
                    <img class="img-fluid" src="" alt="{{blog.title}}">
                    <div class="blog-badge">{{blog.category.name}}</div>
                    <div class="blog-info">
                      <p>{{blog.dateTime}}</p>
                      <h4><a href="#">{{blog.title}}</a></h4>
                      <p class="blog-content pt-3">
                        {{blog.description}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </section> -->

      <section class="space-pb station-blog">
        <div class="container">
          <div class="row d-flex align-items-center mb-2 mb-lg-5">
            <div class="col-lg-5">
              <div class="section-title left-divider mb-0">
                <span>{{'Recent Articles' | translate}}</span>
                <h2>{{'Recent Articles and News' | translate}}</h2>
              </div>
            </div>
            <!-- <div class="col-lg-7 text-lg-end mt-4 mt-lg-0">
              <a href="#" class="btn btn-link btn-lg text-secondary">{{'Get Charging' | translate}}<i class="fa-solid fa-arrow-right-long ms-2"></i></a>
            </div> -->
          </div>
        </div>
        <div *ngIf="allBlogs" class="container-fluid px-5">
          <div class="row">
            <div class="owl-carousel owl-nav-bottom-center p-0" data-nav-dots="false" data-nav-arrow="false" data-items="4" data-lg-items="4" data-md-items="3" data-sm-items="2"  data-space="30" data-autoheight="true" style="display: flex; justify-content: space-evenly;">
             
              <div class="item" *ngFor="let blog of allBlogs" style="display: inline-block;">
                <div class="blog-style-01">
                  <div class="blog-img">
                    <img class="img-fluid" src="{{blog.image}}" alt="{{blog.title}}" style="width: 500px; height: 500px;">
                    <div class="blog-badge">{{'Service' | translate}}</div>
                    <div class="blog-info">
                      <p> {{blog.dateTime}}</p>
                      <h4><a [routerLink]="['/blog-detay', blog.id]">{{blog.key+"-title" | translate}}</a></h4>
                      <p class="blog-content pt-3">
                        {{blog.key+"-description" | translate}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- <div class="item" >
                <div class="blog-style-01">
                  <div class="blog-img">
                    <img class="img-fluid" src="assets/images/blog/sürdürülebilir.png" alt="#">
                    <div class="blog-badge">Service</div>
                    <div class="blog-info">
                      <p> February 4, 2022</p>
                      <h4><a href="#">A path to a cleaner future powered by you</a></h4>
                      <p class="blog-content pt-3">
                        Focus is having the unwavering attention to complete what you set out to do. There are a million distractions in every facet of our lives.
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->

        

            </div>
          </div>
        </div>
      </section>
      <!--=================================
      Blog -->
      <!--=================================
      App -->
      <!-- <section class="space-p bg-light">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 mt-5 mt-lg-0">
              <div class="section-title left-divider">
                <span>{{'Download APP' | translate}}</span>
                <h2>{{'Download APP Header' | translate}}</h2>
                <p>{{'Download APP Content' | translate}}</p>
              </div>
              <div class="d-block d-xs-flex mt-5">
                  <a class="btn-app me-2" routerLink="/coming-soon" href="javascript:;">
                    <i class="fab fa-apple"></i>
                    <div class="btn-text text-start">
                      <small class="small-text">{{'Download on the' | translate}}</small>
                      <span class="mb-0 d-block">App store</span>
                    </div>
                  </a>
                  <a class="btn-app" routerLink="/coming-soon" href="javascript:;">
                    <i class="fab fa-google-play"></i>
                    <div class="btn-text text-start">
                      <small class="small-text">{{'Download on the' | translate}}</small>
                      <span class="mb-0 d-block">Google Play</span>
                    </div>
                  </a>
                </div>
            </div>
            <div class="col-lg-6 text-center mt-4 mt-lg-5">
              <img class="img-fluid" src="assets/images/home-03/mobile.png" alt="#">
            </div>
          </div>
        </div>
      </section> -->

      <!-- <div class="modal fade" id="popupModal" tabindex="-1" aria-labelledby="popupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
          
            <div class="modal-body" style="cursor: pointer;">
              <img src="assets/images/home-03/yarisma.png" alt="Popup Resmi" style="width: 100%;" (click)="resmeTiklandi()">
            </div>
          </div>
        </div>
      </div> -->