import { Component } from '@angular/core';

@Component({
  selector: 'app-compare-electric-fuel',
  templateUrl: './compare-electric-fuel.component.html',
  styleUrls: ['./compare-electric-fuel.component.css']
})
export class CompareElectricFuelComponent {

}
