import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from 'src/environments/environment';
import { ContactModel } from '../models/contact/contact-model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http:HttpClient)
  { }
  // apiUrl="https://localhost:7071/api/ChargingStation/"
  apiUrl = APP_CONFIG.apiUrl + 'api/Profile/';

  sendMail(contactModel:ContactModel): Observable<any> {    
        return this.http.post<any>(this.apiUrl + 'userHelpSendMailForWeb', contactModel);
    }
}
