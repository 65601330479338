<div class="mb-10">
  <form (submit)="submitForm()">

    <div class="container d-flex justify-content-center align-items-center">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col">
          <div class="card card-registration my-4">
            <div class="row g-0">
              <div class="col-xl-6 d-flex flex-column justify-content-center align-items-center">
                <img src="assets/images/home-01/ss-logo.webp" alt="Sample photo" class="img-fluid w-50"
                  style="background-color: white;" />
              </div>
              <div class="col-xl-6">
                <div class="card-body p-md-5 text-black">
                  <h3 class="mb-5 text-uppercase" style="font-size: 500; font-size: 24px;">{{'Register' | translate}}
                  </h3>

                  <div class="row">
                    <div class="col-8">
                      <div class="custom-control custom-radio custom-control-inline">
                        <mat-radio-group [(ngModel)]="registerModel.isPersonal" name="isPersonal">
                          <mat-radio-button [value]="true" id="customRadioInline1" checked>
                            <label class="custom-control-label" for="customRadioInline1">{{'Individual' |
                              translate}}</label>
                          </mat-radio-button>

                          <mat-radio-button [value]="false" id="customRadioInline2">
                            <label class="custom-control-label" for="customRadioInline2">{{'Institutional' |
                              translate}}</label>
                          </mat-radio-button>
                        </mat-radio-group>

                      </div>

                    </div>




                    <div class="col-md-6 mb-4" *ngIf="registerModel.isPersonal">
                      <div class="form-outline">
                        <input type="text" id="form3Example1m" class="form-control form-control-lg"
                          placeholder="{{'Name' | translate}}" [(ngModel)]="registerModel.firstName" name="firstName" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="registerModel.isPersonal">
                      <div class="form-outline">
                        <input type="text" id="form3Example1n" class="form-control form-control-lg"
                          placeholder="{{'Surname' | translate}}" [(ngModel)]="registerModel.lastName"
                          name="lastName" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!registerModel.isPersonal">
                      <div class="form-outline">
                        <input type="text" id="form3Example1n" class="form-control form-control-lg"
                          placeholder="{{'Company Name' | translate}}" [(ngModel)]="registerModel.companyName"
                          name="companyName" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!registerModel.isPersonal">
                      <div class="form-outline">
                        <input type="text" id="form3Example1n" class="form-control form-control-lg"
                          placeholder="{{'Tax Number' | translate}}" [(ngModel)]="registerModel.taxNo" name="taxNo" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!registerModel.isPersonal">
                      <div class="form-outline">
                        <input type="text" id="form3Example1n" class="form-control form-control-lg"
                          placeholder="{{'Tax Administration' | translate}}" [(ngModel)]="registerModel.taxOffice"
                          name="taxOffice" />
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-sm-6 mb-4">
                      <div class="form-outline">
                        <input type="text" id="form3Example1m1" class="form-control form-control-lg"
                          placeholder="{{'Email' | translate}}" [(ngModel)]="registerModel.email" name="email" />

                      </div>

                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <input type="text" id="form3Example1n1" class="form-control form-control-lg"
                          placeholder="{{'Post code' | translate}}" name="zipCode" id="zip" />
                      </div>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="form-outline">

                        <select class="form-select" (change)="onCitySelected($event)">
                          <option value="">{{'Select City' | translate}}</option>
                          <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="form-outline">

                        <select class="form-select" (change)="onDistrictSelected($event)">
                          <option value="">{{'Select District' | translate}}</option>
                          <option *ngFor="let district of districts" [value]="district.id">{{ district.name }}</option>
                        </select>
                      </div>
                    </div>

                    
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">

                        <select class="form-select" (change)="onElectricTypeSelected($event)">
                          <option selected disabled value="">Elektrik Tipi Seç</option>
                          <option *ngFor="let option of electricTypes" [value]="option.electricTypeKey">{{ option.name }}</option>

                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 mb-4">
                      <div class="form-outline">

                        <select class="form-select" (change)="onElectricGroupSelected($event)">
                          <option selected disabled value="">Elektrik Grup Seç</option>
                          <option *ngFor="let option of electricGroups" [value]="option.electricGroupKey">{{ option.name }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-12 mb-4">
                      <div class="form-outline">
                        <!-- <label>{{'Vehicle' | translate}}</label> -->
                        <select class="form-select" (change)="onElectricVehicleSelected($event)">
                          <option selected disabled value="">Araç Seç</option>
                          <option *ngFor="let option of electricVehicles" [value]="option.id">{{ option.brand }} {{
                            option.model }}</option>
                          </select>
                        </div>
                        
                      </div>


                    </div>
                    <div>



                      <div class="form-outline mb-4">
                        <input type="text" id="form3Example8" class="form-control form-control-lg"
                          placeholder="{{'Address' | translate}}" [(ngModel)]="registerModel.address" name="address" />
                      </div>



                      <div class="form-outline mb-4">
                        <input type="text" id="form3Example9" class="form-control form-control-lg"
                          placeholder="{{'Phone' | translate}}" [(ngModel)]="registerModel.telNo" name="telNo" />

                      </div>

                      <div class="form-outline mb-4">
                        <input type="password" id="form3Example90" class="form-control form-control-lg"
                          placeholder="{{'Password' | translate}}" [(ngModel)]="registerModel.password"
                          name="password" />

                      </div>

                      <div class="form-outline mb-4">
                        <input type="password" id="form3Example99" class="form-control form-control-lg"
                          placeholder="{{'Password Repeat' | translate}}" [(ngModel)]="registerModel.passwordConfirm"
                          name="passwordConfirm" />
                      </div>


                      <div class="d-flex justify-content-end pt-3">
                        <button type="submit" class="btn btn-success btn-lg ms-2">{{'Register' | translate}}</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </form>
</div>