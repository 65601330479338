<div class="container">
    <div *ngIf="pdfDownloaded; else notDownloaded" class="message success">
      PDF indirildi.
    </div>
    <ng-template #notDownloaded>
      <div class="message warning">
        PDF henüz indirilmediyse, butona tıklayarak indirebilirsiniz.
      </div>
      <button (click)="downloadPDF()">PDF İndir</button>
    </ng-template>
  </div>
  