import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressesModel } from '../models/register/addresses-model';
import { ElectricVehicleModel } from '../models/register/electric-vehicle-model';
import { RegisterModel } from '../models/register/register-model';
import { ResponseModel } from '../models/responseModel';
import { LoginDTo } from '../models/auth/loginDTo';
import { SingleResponseModel } from '../models/singleResponseModel';
import { TokenModel } from '../models/auth/tokenModel';
import { APP_CONFIG } from 'src/environments/environment';
import { ElectricGroupModel } from '../models/register/electric-group-model';
import { ElectricTypeModel } from '../models/register/electric-type-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  private redirectUrl: string = "/" // Yönlendirilecek URL'yi saklamak için bir özellik ekleyelim
  // apiUrl = 'https://localhost:7071/api/Auth/';
  apiUrl = APP_CONFIG.apiUrl + 'api/Auth/';
  apiUrlforProfile = APP_CONFIG.apiUrl + 'api/Profile/';

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }

  login(loginDTo: LoginDTo): Observable<SingleResponseModel<TokenModel>> {

    return this.http.post<SingleResponseModel<TokenModel>>(this.apiUrl + 'login', loginDTo);
  }

  logout() {

    return this.http.get(this.apiUrl + 'logout');
  }

  isAuthenticated() {
    if (localStorage.getItem("token")) {

      return true;
    }
    else {
      return false;
    }
  }


  getElectricVehicles(): Observable<ElectricVehicleModel[]>{
    return this.http.get<ElectricVehicleModel[]>(this.apiUrlforProfile + 'getElectricVehicles')
  }

  
  getElectricGroups(): Observable<ElectricGroupModel[]>{
    return this.http.get<ElectricGroupModel[]>(this.apiUrlforProfile + 'getElectricGroups')
  }

  getElectricTypes(): Observable<ElectricTypeModel[]>{
    return this.http.get<ElectricTypeModel[]>(this.apiUrlforProfile + 'getElectricTypes')
  }
  
  
  register(registerModel: RegisterModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(this.apiUrl + 'register', registerModel);
  }

  refreshToken(refreshToken: string): Observable<SingleResponseModel<TokenModel>>{
    return this.http.get<any>(`${this.apiUrl}refreshTokenLogin?refreshToken=${refreshToken}`);
  }
}