import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CostManagementComponent } from './cost-management.component';
import { LoginGuard } from 'src/app/guards/login.guard';

const routes: Routes = [
  {
    path: 'maliyet',
    component: CostManagementComponent
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CostManagementRoutingModule {}
