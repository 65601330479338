import { Component } from '@angular/core';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent {
  activeTab = 'monthly';

  selectTab(tab: string): void {

      console.log(tab);
      this.activeTab = tab;
  }
}
