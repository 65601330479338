export type ChargingStationKey = 'ZES' | 'eşarj' | 'VOLTRUN' | 'Trugo' | 'SHARZ.NET' | 'Otojet' | 'ASTOR' | 'beefull' | 'EN YAKIT' | 'oncharge' | 'POWERŞARJ' | 'tunçmatik' | 'SHELL' | 'efish' | 'Otopriz' | 'NEVA ŞARJ' | 'Q Charge' | 'borenco' | 'enertürk rhg enerji' | 'WAT' | 'D-Charge' | 'CV Enerji' | 'default' | 'MESKEN' | 'TICARETHANE' | 'PROGRESS';

export interface ChargingStationImages {
  [key: string]: string;
}

const chargingStationImages: ChargingStationImages = {
  "ZES": "../assets/images/chargingStations/zes.webp",
  "eşarj": "../assets/images/chargingStations/esarj.webp",
  "VOLTRUN": "../assets/images/chargingStations/voltrun.webp",
  "Trugo": "../assets/images/chargingStations/trugo.webp",
  "SHARZ.NET": "../assets/images/chargingStations/sharznet.webp",
  "Otojet": "../assets/images/chargingStations/otojet.webp",
  "ASTOR": "../assets/images/chargingStations/astor.webp",
  "beefull": "../assets/images/chargingStations/beefull.webp",
  "EN YAKIT": "../assets/images/chargingStations/enyakit.webp",
  "oncharge": "../assets/images/chargingStations/oncharge.webp",
  "POWERŞARJ": "../assets/images/chargingStations/powersarj.webp",
  "tunçmatik": "../assets/images/chargingStations/tuncmatik.webp",
  "SHELL": "../assets/images/chargingStations/shell.webp",
  "efish": "../assets/images/chargingStations/efish.webp",
  "Otopriz": "../assets/images/chargingStations/otopriz.webp",
  "NEVA ŞARJ": "../assets/images/chargingStations/nevasarj.webp",
  "Q Charge": "../assets/images/chargingStations/qcharge.webp",
  "borenco": "../assets/images/chargingStations/borenco.webp",
  "enertürk rhg enerji": "../assets/images/chargingStations/rhgenerjiturk.webp",
  "WAT": "../assets/images/chargingStations/wat.webp",
  "D-Charge": "../assets/images/chargingStations/d-charge.webp",
  "CV Enerji": "../assets/images/chargingStations/cvenerji.webp",
  "default": "../assets/images/chargingStations/charging_station2.webp",
  "MESKEN": "../assets/images/chargingStations/home.webp",
  "TICARETHANE": "../assets/images/chargingStations/business.webp",
  "PROGRESS": "../assets/images/progress.webp",
};

export default chargingStationImages;
