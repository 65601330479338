import { Component ,OnInit} from '@angular/core';
import * as L from 'leaflet';
import { MapService } from 'src/app/services/map.service';
import {StationForCardModel } from 'src/app/models/map/station-for-card-model';
import 'leaflet.markercluster'
import {LoadingComponent} from 'src/app/components/loading/loading.component';
import { LoadingService } from 'src/app/services/loading.service';
import chargingStationImages from 'src/app/models/chargingStationImages/chargingStationImages';
import { ChargingStationImages, ChargingStationKey } from 'src/app/models/chargingStationImages/chargingStationImages';
declare module 'leaflet' {
  interface MarkerOptions {
      userData?: any;  
  }
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements OnInit{
  loading=true;
  images: ChargingStationImages = chargingStationImages;
  private map: L.Map;
  private markerClusterGroup: L.MarkerClusterGroup;
  open=true;
  data:any;
  private nearbymarkers:L.Layer []=[];
  private stations:StationForCardModel[]=[]

  showPopup=false;
  
  openPopup() {
    if(!this.loading){      
    this.showPopup = true;
    }
  }
  private initMap(){
    
    this.kullaniciKonum().then(([latitude, longitude]) => {
      this.loadingService.requestStarted();
      this.markerClusterGroup = L.markerClusterGroup();
      this.map = L.map('map').setView([latitude,longitude],11);
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',{
        maxZoom:19,
        attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(this.map);

      
      
    }).catch(error => {
      console.error("Konum alınamadı");
      this.loadingService.requestStarted();
      this.markerClusterGroup = L.markerClusterGroup();
      this.map = L.map('map').setView([39.000,35.000],6);
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',{
        maxZoom:19,
        attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(this.map);

      this.create_markers(this.map);
      
      this.map.on('moveend',(e:L.LeafletEvent)=>{  
        this.create_markers(this.map);
      })
    });
 }
  private kullaniciKonum(): Promise<[number, number]> {
    return new Promise<[number, number]>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                resolve([latitude, longitude]);
            },
            error => {
                reject(error);
            }
        );
    });
  }
  
  private create_markers(map:L.Map):void{
      var northEastLat=44.50;
      var northEastLng=46.36;
      var southWestLat=31.96;
      var southWestLng=24.10;
      const latitudeDelta = (northEastLat - southWestLat)/2;
      const longitudeDelta = (northEastLng - southWestLng)/2;
      this.getLocationByRegion(38.50,35.23,latitudeDelta,longitudeDelta);
  }
  removeMarker(nearby:L.Layer[]):void{
    nearby.forEach(x=>{
      this.map.removeLayer(x)
      this.markerClusterGroup.removeLayer(x);
      this.nearbymarkers=[]
    })
  }
  getImageSrc(brand:string): string {
      return this.images[brand] || this.images['default'];
  }
  getLocation(latitude:number,longitude:number): void {
    this.charging.getAll(latitude,longitude).subscribe((response:StationForCardModel[] )=>{
      this.stations=response;
      this.removeMarker(this.nearbymarkers)
      this.stations.forEach(x=>{
        var marker=L.marker([x.coordinate.latitude,x.coordinate.longitude]).addTo(this.map).bindPopup('Saved Marker at latitude = '+x.coordinate.latitude+' / longitude = '+x.coordinate.longitude);
        this.nearbymarkers.push(marker);
      })
    })
  }
  getLocationByRegion(latitude:number,longitude:number,latitudeDelta:number,longitudeDelta:number): void {
    this.charging.getAllByRegion(latitude,longitude,latitudeDelta,longitudeDelta).subscribe((response:StationForCardModel[] )=>{
      this.stations=response;
      this.removeMarker(this.nearbymarkers)
      this.stations.forEach(x => {
        var marker = L.marker([x.coordinate.latitude, x.coordinate.longitude], {
            userData: {
                stationName: x.stationName,
                rating: x.rating,
                name:x.name,
                typeOfService: x.typeOfService,
                address: x.stationAddress,
                totalStationDC: x.totalStationsDC,
                totalStationAC: x.totalStationsAC,
                image: x.stationImage,
                maps:"https://www.google.com/maps/dir//"+x.coordinate.latitude+","+x.coordinate.longitude+"/@"+x.coordinate.latitude+","+x.coordinate.longitude+",17.29z?entry=ttu"
            }
        });
        this.nearbymarkers.push(marker);
        this.markerClusterGroup.addLayer(marker);
        L.Icon.Default.mergeOptions({
          iconRetinaUrl: '../assets/images/marker-charge.webp',
          iconUrl: '../assets/images/marker-charge.webp',
          shadowUrl: '../assets/images/marker-shadow.webp',
          iconSize: [37, 41], 
          iconAnchor: [12, 41], 
          popupAnchor: [1, -34], 
          tooltipAnchor: [16, -28], 
          shadowSize: [41, 41] 
        });
        marker.on('click', (e) => {
          this.data=marker.options.userData;
          this.open=false;
      });
    });
    
      this.map.addLayer(this.markerClusterGroup);    
      
    this.loadingService.requestEnded();
    this.loading=false;
    })
  }
  ToggleMenu():void{
    if(this.data != null){
      this.open=!this.open;
    }
    else{
      
    this.open=true;
    }
  }
  constructor(private charging:MapService,private loadingService:LoadingService){}
  ngOnInit():void{
    
    this.initMap();
    this.create_markers(this.map);
    
    }
  }
