<div class="container">
    <h3>Kişisel Verilerin Korunması Kanunu Kapsamında Üye ve Ziyaretçi Aydınlatma Metni</h3>

    <p>
        Bu Kişisel Verilerin Korunması Kanunu Kapsamında Üye ve Ziyaretçi Aydınlatma Metni (“Aydınlatma Metni”) kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile uyacakları usul ve esasları düzenleyen 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve Aydınlatma Yükümlülüğün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ (Tebliğ) kapsamında United Yazılım Teknoloji A.Ş. tarafından yayınlanan Sarjsepeti uygulaması(“Şarjsepeti” veya “Şirket”) tarafından hazırlanmıştır.
    </p>

    <p>
        Uygulamalara ilişkin Genel Gizlilik Politikamız, bu Aydınlatma Metninden ayrıdır, buna dair detayları Gizlilik Politikamızdan inceyebilirsiniz.
    </p>

    <p>
        Bu Aydınlatma Metni’nin amacı, Şarjsepeti tarafından yönetilmekte olan www.sarjsepeti.com alan adı altındaki internet sitesinin ve sanal mağazalarda yer alan mobil aplikasyonun (Site ve mobil aplikasyon birlikte veya ayrı ayrı “Uygulamalar”) Üye ve Ziyaretçiler tarafından kullanımı sırasında elde edilen kişisel verilere ilişkin; Üye ve Ziyaretçileri aydınlatmaktır. Bu metinde;
    </p>

    <ul>
        <li>Üye(ler): Uygulamalar üzerinden üyelik oluşturan ve United ile arasında Uygulamalar Kullanım Sözleşmesi bulunan kişileri;</li>
        <li>Ziyaretçi(ler): Uygulamalara herhangi bir üyelik kaydı gerçekleştirmeksizin kullanan kişiyi,</li>
        <li>İlgili Kişi/ler: Kişisel verileri işlenen Üye veya Ziyaretçi’nin ayrı ayrı birini veya ikisini birden ifade eder.</li>
    </ul>

    <p>
        Kişisel veriler, kimliği belirli ya da belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder. Bu sebeple, bu Aydınlatma Metni’nde yer alan kişisel verilere ilişkin düzenlemeler, ilgili bilgilerin bir gerçek kişiye ait olması durumunda uygulanacaktır. İlgili bilgilerin tüzel kişilere ait olması halinde bu Politika’da yer alan kişisel verilere ilişkin düzenlemeler dışındaki düzenlemeler uygulanır. Ayrıca “kişisel verilerin işlenmesi” kavramı; kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, saklanması, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi ifade etmektedir.
    </p>

    <p>
        Şarjsepeti, verilerin gizliliğine önem verir ve verilerin saklanması konusunda şeffaf olmaya özen gösterir. Bu Politika, hangi tür verilerin elde edildiğine, bu verilerin nasıl kullanıldığına, gerekmesi halinde bu bilgilerin kimlerle paylaşıldığına, kişisel verilere ilişkin hakların neler olduğuna ve bu hakların nasıl kullanılabileceğine yönelik aydınlatmayı ve Şarjsepeti’nin gizliliğe ilişkin benimsediği ilkeleri içerir. Kişisel veriler, Kanun’da düzenlendiği üzere aşağıda yer alan temel ilkeler doğrultusunda işlenir:
    </p>

    <ul>
        <li>Hukuka ve dürüstlük kurallarına uygun olma,</li>
        <li>Doğru ve gerektiğinde güncel olma,</li>
        <li>Belirli, açık ve meşru amaçlar için işlenme,</li>
        <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma,</li>
        <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli süre kadar muhafaza.</li>
    </ul>

    <p>
        Şarjsepeti, Kanun uyarınca kişisel verilerin elde edilmesi sırasında, verisi işlenecek olan gerçek kişileri Kanun’un 10’uncu maddesi uyarınca aydınlatmak/bilgilendirmek ile yükümlüdür. Bu aydınlatma yükümlülüğün kapsamı aşağıdaki gibidir:
    </p>

    <ul>
        <li>Veri Sorumlusu’nun ve varsa temsilcisinin kimliği,</li>
        <li>Kişisel verilerin hangi amaçla işleneceği,</li>
        <li>İşlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği,</li>
        <li>Kişisel veri toplamanın yönetimi ve hukuki sebebi,</li>
        <li>İlgili Kişi’nin hakları.</li>
    </ul>

    <h5>Taraflar</h5>
    <p>
        İşbu Bireysel Hesap Sözleşmesi (bundan böyle “Hesap Sözleşmesi” olarak anılacaktır); “Kavacık Mah. Öge Sk. No: 20/17 Beykoz / İstanbul” adresinde mukim UNITED YAZILIM TEKNOLOJİLERİ A.Ş. markası hizmet sağlayıcı (bundan böyle kısaca "ŞARJSEPETİ" olarak anılacaktır) ile www.sarjsepeti.com Portalda hesap açmak için işbu Hesap Sözleşmesi ile ayrılmaz parçası olan eklerine ve sarjsepeti.com Portalında yer alan şartlar ile kurallara onay veren "Hesap Sahibi" arasında elektronik ortamda akdedilerek yürürlüğe girmiştir.
    </p>

    <h5>Tanımlar</h5>
    <p>
        <strong>“Portal”:</strong> www.sarjsepeti.com isimli alan adından ve bu alan adına bağlı alt alan adlarından oluşan “ŞARJSEPETİ”’in hizmetlerini sunduğu internet sitesi ve/veya mobil uygulamalar.
    </p>
    <p>
        <strong>“Kullanıcı”:</strong> “Portal”a erişen her gerçek veya tüzel kişi.
    </p>
    <p>
        <strong>“Hesap Sahibi”:</strong> “Portal”da hesap açan ve "Portal" dahilinde sunulan hizmetlerden belirlenen koşullar dahilinde yararlanan “Kullanıcı”.
    </p>
    <p>
        <strong>“Bireysel Hesap Sahibi”:</strong> “Portal”da hesap oluşturan, mesleki veya ticari amaç gütmeksizin "Portal" dahilinde sunulan hizmetlerden belirlenen koşullar dahilinde yararlanan “Kullanıcı”.
    </p>
    <p>
        <strong>“Bireysel Hesap” (“Hesap”):</strong> “Bireysel Hesap” oluşturmak isteyen “Kullanıcı”nın, “Portal”daki Bireysel Hesap Formu’nu doğru ve gerçek bilgilerle eksiksiz olarak doldurması üzerine “ŞARJSEPETİ” tarafından yapılan bildirim ile kazanılan statüdür. Hesap işlemleri tamamlanmadan “Bireysel Hesap Sahibi” olma hak ve yetkisine sahip olunamaz. “Bireysel Hesap Sahibi”nin hak ve yükümlülükleri, başvuruda bulunana ait olan, kısmen veya tamamen herhangi bir üçüncü şahsa devredilemeyen hak ve yükümlülüklerdir. Hesap Oluşturma başvurusu herhangi bir sebep gösterilmeksizin “ŞARJSEPETİ” tarafından reddedilebilir veya ek şart ve koşullar talep edilebilir. “ŞARJSEPETİ” gerekli görmesi halinde “Bireysel Hesap Sahibi”nin hesabını kapatabilir, hesabı herhangi bir sebeple kapatılanın yapacağı hesap açma talebini kabul etmeyebilir.
    </p>
    <p>
        <strong>"Hesap Sözleşmesi":</strong> “Portal”da yer alan şartlar ve koşullar ile eklerden oluşan, kullanıcının “Hesap Sözleşmesi”ni anladığına ve kabul ettiğine ilişkin elektronik olarak verdiği onay neticesinde elektronik ortamda akdedilen, ayrılmaz parçası olan ekler ile “Portal”da yer alan şart ve kurallarla birlikte bir bütün olan elektronik sözleşme.
    </p>
    <p>
        <strong>"ŞARJSEPETİ Hesabı":</strong> “Hesap Sahibi”nin "Portal" içerisinde sunulan hizmetlerden yararlanmak için gerekli olan iş ve işlemleri gerçekleştirdiği, "Hesap” ile ilgili konularda "ŞARJSEPETİ”ne talepte bulunduğu, "Hesap bilgilerini güncelleyip, sunulan hizmetlerle ilgili raporlamaları görüntüleyebildiği, kendisinin belirlediği ve münhasıran kendisi tarafından kullanılacağını taahhüt ettiği "kullanıcı adı" ve "şifre" ile "Portal" üzerinden eriştiği "Hesap Sahibi"ne özel internet sayfaları bütünü.
    </p>
    <p>
        <strong>“ŞARJSEPETİ Hizmetleri” ("Hizmetler"):</strong> "Portal" içerisinde "Hesap Sahibi"nin işbu “Hesap Sözleşmesi” içerisinde tanımlı olan iş ve işlemlerini gerçekleştirmelerini sağlamak amacıyla “ŞARJSEPETİ” tarafından sunulan uygulamalardır. "ŞARJSEPETİ", "Portal" içerisinde sunulan "Hizmetler"inde dilediği zaman değişiklikler ve/veya uyarlamalar yapabilir. Yapılan değişiklikler ve/veya uyarlamalarla ilgili "Hesap Sahibi"nin uymakla yükümlü olduğu kural ve koşullar “Portal”dan "Hesap Sahibi"ne duyurulur, açıklanan şartlar ve koşullar “Portal”da yayımlandığı tarihte yürürlüğe girer.
    </p>
    <p>
        <strong>“İçerik”:</strong> “Portal”da yayınlanan ve erişimi mümkün olan her türlü bilgi, yazı, dosya, resim, video, rakam vb. görsel, yazımsal ve işitsel imgeler.
    </p>
    <p>
        <strong>"ŞARJSEPETİ Arayüzü":</strong> ŞARJSEPETİ ve "Hesap Sahibi” tarafından oluşturulan içeriğin "Kullanıcı”lar tarafından görüntülenebilmesi ve "ŞARJSEPETİ Veri tabanı"ından sorgulanabilmesi amacıyla "Kullanıcı”lar tarafından kullanılan; 5846 Sayılı Fikir ve Sanat Eserleri Kanunu kapsamında korunan ve tüm fikri hakları “ŞARJSEPETİ”ne ait olan tasarımlar içerisinde “Portal” üzerinden yapılabilecek her türlü işlemin gerçekleştirilmesi için bilgisayar programına komut veren internet sayfaları ve mobil uygulama ekranları/sayfalarıdır.
    </p>
    <p>
        <strong>“ŞARJSEPETİ Veri Tabanı”:</strong> “Portal” dahilinde erişilen içeriklerin depolandığı, tasnif edildiği, sorgulanabildiği ve erişilebildiği “ŞARJSEPETİ”ne ait olan 5846 Sayılı Fikir ve Sanat Eserleri Kanunu gereğince korunan veri tabanıdır.
    </p>
    <p>
        <strong>“Bana Özel” sayfası:</strong> "Hesap Sahibi"nin "Portal"da yer alan çeşitli uygulamalardan ve "Hizmetler”den yararlanabilmesi için gerekli işlemleri gerçekleştirebildiği, kişisel bilgilerini, tercihlerini, uygulama bazında kendisinden talep eden bilgileri girdiği, sadece "Hesap Sahibi" tarafından belirlenen kullanıcı adı ve şifre ile erişilebilen "Hesap Sahibi"ne özel sayfa.
    </p>
    <p>
        <strong>“Kişisel Veri”:</strong> 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanun’un (KVKK) 3/d maddesi uyarınca, kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi ile KVKK m.6/1’de sayılan özel nitelikli kişisel verilerdir.
    </p>
    <p>
        <strong>“Özel nitelikli kişisel veri”:</strong> Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.
    </p>

    <h5>İlgili Kişi, Veri Kategorileri ve Veri Türleri</h5>
    <p>
        İlgili Kişi Üye ve Ziyaretçiler, Uygulamalarda üyelikleri oluşturulurken ve/veya Uygulamalara erişirken veya Şarjsepeti hizmetlerinden yararlanırken uygulamalarımıza gönderdiği, paylaştığı ve/veya erişilebilir formda sağladığı veya Uygulamalar vasıtasıyla otomatik yollarla toplanan bilgiler kişisel veri kapsamına girebilir. Bu kapsamda, Şirketin sunduğu hizmetlerden yararlanırsanız veya Uygulamalara erişim sağlarsanız, sizinle ve çeşitli kaynaklardan aşağıdaki tabloda belirtilmiş olan bilgileri toplayabiliriz:
    </p>

    <ul>
        <li><strong>Kayıt bilgileri:</strong> Şarjsepeti hizmetlerinden yararlanabilmek için, uygulamalarımıza Üye olmanız gerekebilir. Kaydolmak için kullanıcı adınızı, parolanızı ve e-posta adresinizi girmelisiniz. Üçüncü şahıs hesaplarıyla (Google ya da Facebook hesabınız gibi) kaydolmak istiyorsanız, 'Üçüncü şahıslardan bilgi' başlıklı bölümünü dikkate almalısınız.</li>
        <li><strong>Finansal Bilgiler:</strong> Şirkete ödeme yaparken isim, adres, e-posta adresi ve faturada yer alması zorunlu ayrıntıları sağlamanız gerekmektedir. Ödeme gerçekleştirirken sağladığınız kredi kartı bilgileri Şarjsepeti altyapısına kaydedilmez; sadece Şarjsepeti sistemi üzerine kurulu 3D Secure güvenlikli ödeme altyapısı tarafından işlemler gerçekleştirilirken kullanılır.</li>
        <li><strong>Kullanım verileri:</strong> Üye ve Ziyaretçi olması fark etmeksizin; Şarjsepeti hizmetlerinden her yararlandığınızda veya yararlanmaksızın Uygulamalara erişim sağladığınızda, hakkınızda kullanım verileri toplanır. Uygulamaları ziyaret ettiğiniz, neleri tıkladığınız, bu eylemleri ne zaman gerçekleştirdiğiniz, vb. veriler buna dâhil olabilir. Ayrıca, kaynak IP adresleri, internet hizmeti sağlayıcıları, Uygulamalarda görüntülenen dosyalar (örn. HTML sayfalar, grafikler, vb.), işletim sistemi sürümleri ve zaman damgaları gibi her bir erişimin mahiyetine ilişkin verileri içerir.</li>
        <li><strong>Aygıt verileri:</strong> Hizmetlerimize erişmek için kullandığınız aygıt ve uygulamalardan, IP adresiniz, işletim sistemi sürümünüz, cihaz türünüz, sistem ve performans bilgileriniz ve tarayıcı türünüz gibi veriler toplanır.</li>
        <li><strong>Üçüncü şahıslardan bilgi:</strong> Üçüncü şahısların bilgilerinizi bizimle paylaşmasına izin verirseniz, o üçüncü şahıslardan sizinle ilgili kişisel bilgileri ya da verileri toplayabiliriz. Örneğin; Şarjsepeti’ne Google hesap detaylarınızla kayıt olma ve giriş yapma seçeneğini kullanırsanız giriş bilgileriniz Google tarafından doğrulanır; biz sadece Şarjsepeti hesabınızın Google hesabınıza bağlanmasına izin vererek Google hesabınız hakkında bizimle paylaşmayı açıkça kabul ettiğiniz bilgileri toplarız.</li>
    </ul>

    <h5>İlgili Kişi	Veri Kategorileri	Veri Türleri</h5>
    <table border="1">
        <tr>
            <th>İlgili Kişi</th>
            <th>Veri Kategorileri</th>
            <th>Veri Türleri</th>
        </tr>
        <tr>
            <td>Üye</td>
            <td>Kimlik</td>
            <td>Ad-soyad, kimlik numarası</td>
        </tr>
        <tr>
            <td></td>
            <td>İletişim</td>
            <td>E-Posta Adresi, Adres</td>
        </tr>
        <tr>
            <td></td>
            <td>Finans</td>
            <td>Fatura düzenlenmesi amacıyla paylaşılan bilgiler, IBAN ve temel hesap bilgileri.</td>
        </tr>
        <tr>
            <td></td>
            <td>Müşteri İşlem</td>
            <td>Araç şarjınıza dair bilgiler, Uygulamalarda yer alan chat (yazışma) uygulamaları vasıtasıyla bizimle iletişime geçtiğinizde paylaştığınız diğer bilgiler ve ilettiğiniz talepler.</td>
        </tr>
        <tr>
            <td></td>
            <td>İşlem Güvenliği</td>
            <td>İşletim Sistemi Sürümünüz, Cihaz Türünüz, Sistem Bilgileriniz, Tarayıcı Türünüz, konum bilgileri, internet trafik verileri (ağ hareketleri, IP adresi, ziyaret verileri, zaman ve tarih bilgileri)</td>
        </tr>
        <tr>
            <td>Ziyaretçi</td>
            <td>Müşteri İşlem</td>
            <td>Uygulamalarda yer alan chat (yazışma) uygulamaları vasıtasıyla bizimle iletişime geçtiğinizde paylaştığınız bilgiler ve ilettiğiniz talepler.</td>
        </tr>
        <tr>
            <td></td>
            <td>İşlem Güvenliği</td>
            <td>İşletim Sistemi Sürümünüz, Cihaz Türünüz, Sistem Bilgileriniz, Tarayıcı Türünüz, konum bilgileri, internet trafik verileri (ağ hareketleri, IP adresi, ziyaret verileri, zaman ve tarih bilgileri)</td>
        </tr>
    </table>

    <h5>Hukuki Sebepler</h5>
    <p>
        Yukarda belirtilen kişisel verileriniz, bu verileri Şirket ile paylaşmanıza konu olan amaçlarla, Kanun’un ilgili düzenlemelerine uygun olarak aşağıdaki hukuki sebeplere dayanarak işlenmektedir.
    </p>

    <p>
        Üyelerimizin kimlik, iletişim, finans ve müşteri işlem kişisel verileri ile ziyaretçilerimizin müşteri işlem verileri, bir sözleşme ilişkisi kurmamız veya bu sözleşmeden kaynaklanan ifa yükümlülüğümüz ile doğrudan doğruya ilgili olması kaydıyla, sizlere ait kişisel verilerin işlenmesinin gerekli olması, verilerinizin sizin tarafınızdan alenileştirilmiş olması hukuki sebepleriyle işlenmektedir.
    </p>

    <p>
        İşlem güvenliği kişisel verileri ise kanunlarda kişisel verilerinizi işlediğimiz sürecin açıkça öngörülmesi ve veri işlemenin hukuki yükümlülüğümüzü yerine getirebilmek için zorunlu olması sebebiyle işlenmektedir.
    </p>

    <h5>2. Kişisel Verilerin Hangi Amaçlarla İşleneceği</h5>
    <p>
        Kişisel veriler, mevzuatta öngörülen usul ve esaslara uygun olarak Kanun’un 5. Ve 6. Maddelerinde öngörülen kişisel verilerin işlenme şartları ve 4. Maddede sıralanan ilkeler doğrultusunda aşağıda yer alan amaçlar ile işlenecektir:
    </p>

    <h5>İlgili Kişi Veri Kategorileri İşleme Amaçları</h5>
    <table border="1">
        <tr>
            <th>İlgili Kişi</th>
            <th>Veri Kategorileri</th>
            <th>İşleme Amaçları</th>
        </tr>
        <tr>
            <td>Üye</td>
            <td>Kimlik</td>
            <td rowspan="3">
                <ul>
                    <li>İletişim Faaliyetlerinin Yürütülmesi</li>
                    <li>İş Faaliyetlerinin Yürütülmesi/Denetimi</li>
                    <li>Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi</li>
                    <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>
                    <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
                    <li>Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</li>
                    <li>Organizasyon ve Etkinlik Yönetimi</li>
                    <li>Talep / Şikayetlerin Takibi</li>
                    <li>Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td></td>
            <td>İletişim</td>
        </tr>
        <tr>
            <td></td>
            <td>Finans</td>
        </tr>
        <tr>
            <td></td>
            <td>Müşteri İşlem</td>
        </tr>
        <tr>
            <td>Üye ve Ziyaretçi</td>
            <td>İşlem Güvenliği</td>
            <td>
                <ul>
                    <li>Faaliyetlerin mevzuata uygun yürütülmesi</li>
                    <li>Bilgi güvenliği süreçlerinin yürütülmesi</li>
                    <li>Denetim/etik faaliyetlerinin yürütülmesi</li>
                    <li>Erişim yetkilerinin yürütülmesi</li>
                    <li>İş faaliyetlerinin yürütülmesi/ denetimi</li>
                    <li>İş sürekliliğinin sağlanması faaliyetlerinin yürütülmesi</li>
                    <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Ziyaretçi</td>
            <td>Müşteri İşlem</td>
            <td>
                <ul>
                    <li>Talep / Şikayetlerin Takibi</li>
                    <li>Mal / Hizmet Satış Süreçlerinin Yürütülmesi</li>
                    <li>İletişim Faaliyetlerinin Yürütülmesi</li>
                </ul>
            </td>
        </tr>
    </table>

    <h5>3. Kişisel Verilerinizin Aktarıldığı Taraflar ve Aktarım Amaçları</h5>
    <p>
        Şarjsepeti, Kişisel Verilerinizi “bilme gereği” ve “kullanma gereği” ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir. İş ortaklığı süreçlerinin niteliği, mal ve hizmet temin süreçlerinin gereklilikleri, tedarik zinciri yönetim süreçlerinin yönetimi gibi süreçlerinin yürütülmesi veya denetimi, dijital altyapıların işletilmesi farklı paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak durumundayız.
    </p>

    <p>
        Toplanan kişisel verileriniz zaman zaman ve faaliyetin gerektirdiği ölçüde, Şirket işleyişimizi yürütebilmesi temel amacı çerçevesinde ve açık rızanızı almak suretiyle, bu verilerin depolanması veya bu metinde yer alan hukuka uygun amaçlarla, yurt dışındaki sunuculara aktarılabilir, işleme alınabilir ve depolanabilir. Şarjsepeti, kişisel veriler dâhil olmak üzere ilgili kişilerin bilgilerini, bulundukları yargı bölgesindeki veri koruma yasalarının aynısına/benzerine sahip olmayan başka bir ülke ve yargı bölgesine aktarabilir. Bu kapsamda Şarjsepeti, hizmet aldığı mesleki danışmanlar ve muhasebeciler ve denetçilerle, bilgi teknolojileri ve arşiv hizmeti veren şirketlerle, Şirket’in ticari, idari veya sözleşmesel ilişki içinde bulunduğu kurumlarla, bankalarla, iç ilişkimizin yürütülmesi amacıyla Şirket’in hâkim ortağı olan şirket ve onun grup şirketleri gibi üçüncü kişilerle kişisel verileri paylaşabilir. Bunun yanında Şirket, işlem güvenliği bilgilerinin talep edilmesi halinde yetkili kurumlarla; yasal takip yollarına başvurulması halinde kişisel verilerin adli makamlar ile kişisel verileri paylaşabilir.
    </p>

    <p>
        Şarjsepeti, herhangi bir şekilde kullanıcılar/ziyaretçilerin kişisel bilgilerinin başkalarına veya bağlı olmayan şirketlere (örneğin doğrudan pazarlama amaçlarıyla) kiralanmasına, satılmasına ya da paylaşılmasına neden olmaz. Ancak, Şarjsepeti, kişisel olmayan (anonim) toplu bilgileri üçüncü taraflarla paylaşabilir.
    </p>

    <p>
        Şirketin yasalar karşısındaki yükümlülüklerini ifa etmesi amacıyla (suçla mücadele ve devlet ve kamu güvenliğinin tehdidi benzeri bilgi verme yükümlülüğünün mevcut olduğu durumlarda) kişisel verileri talep etmeye yetkili olan kamu kurum ve kuruluşları ile kişisel verileriniz paylaşılabilir. Şarjsepeti, kendi şart ve koşullarını gerçekleştirmek ya da işlem veya kullanıcı güvenliğini sağlamak için, Kanun kapsamında yetkisi bulunan durumlarda, makul bir açıklamanın gerekli olduğunu kabul ederse, bilgileri üçüncü kişilerle paylaşabilir.
    </p>

    <p>
        Şarjsepeti, veri sorumlusu olduğu kişisel veri işleme süreçlerine ilişkin, kişisel verileri aktardığı üçüncü kişilerin Üye’ye karşı, sadece ilgili aktarma amacıyla sınırlı şekilde veri sorumluluğu yükümlülüğü üstlenir. Veri aktarılan taraf, veri sorumlusu olarak kişisel veri işlediği süreçlere ilişkin münferiden sorumluluğa sahiptir.
    </p>

    <h5>4. Çerezler</h5>
    <p>
        Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla bilgisayarınıza (ya da akıllı telefon veya tablet gibi diğer cihazlarınıza) kaydedilen ve genelde harf ve rakamlardan oluşan çok küçük metin dosyalarıdır. Çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi kişisel verileri içermezler.
    </p>

    <p>
        Çerezler, ziyaret ettiğiniz web sitesini yöneten sunucular tarafından oluşturulurlar. Böylelikle ziyaretçi aynı siteyi ziyaret ettiğinde sunucu bunu anlayabilir. Çerezler, web sitesi sahiplerine aynı ziyaretçinin siteyi yeniden ziyaret ettiğini gösteren kimlik kartlarına benzetilebilir.
    </p>

    <p>
        Çerezleri kullanırken kişisel verilerinizin korunmasına önem veriyoruz. Uygulamalarda kullanılan çerezlere ilişkin Kanun kapsamındaki yükümlülüklerimize ve bilgilendirmelerimize dair detaylı bilgiye Çerez Politikamızdan ulaşabilirsiniz.
    </p>

    <h5>5. Kişisel Veri İlgilisinin Hakları</h5>
    <p>
        Kanun’un 11. maddesi uyarınca ilgili kişinin veri sorumlusuna başvurarak bilgileri talep etme hakkına sahip olduğu veriler Şirket tarafından sağlanacaktır. İlgili Kişiler olarak, haklarınıza ilişkin taleplerinizi belirtmek ve kişisel verileriniz üzerindeki haklarınızı kullanmak amacıyla; Şirket’in resmi e-posta adresi sarjsepeti.com veya Şirket’in adresine gerekli değişiklik, güncelleme ve/veya silme gibi işlemleri ve ilgili talepleri; Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'de yer alan asgari başvuru şartlarını sağlayacak şekilde ileterek; gerekli değişiklik, güncelleme ve/veya silme gibi işlemleri ve ilgili talepleri gerçekleştirebilirsiniz.
    </p>

    <p>
        İlgili Kişiler olarak sahip olduğunuz ve yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor iseniz bu konuda özel olarak yetkili olmanız ve yetkinizi belgelendirilmesi, başvurunun Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'de yer alan asgari başvuru şartlarını taşıması gerekmektedir.
    </p>

    <p>
        Belirtilen yöntemlerle taleplerinizi bize iletmeniz durumunda Şarjsepeti, talebinizin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Şarjsepeti tarafından Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücret alınacaktır.
    </p>

    <p>
        Şirket Unvanı: United Yazılım Teknoloji Anonim Şirketi
    </p>
</div>