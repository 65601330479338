import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { HistoryComponent } from "./history.component";
import { CommonModule } from "@angular/common";

const routes:Routes =[
    {
        path:'gecmis',
        component:HistoryComponent,
    }
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class HistoryRoutingModule {}