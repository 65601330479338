      <!--=================================
      Banner -->

      <!--=================================
      Contact-Info -->
      <!-- <section class="space-ptb">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="feature-item feature-info-style-02 feature-info-center">
                  <div class="feature-icon">
                    <object type="image/svg+xml" data="assets/images/svg/shipping.svg" class="icon "></object>
                  </div>
                  <div class="feature-content">
                    <h4 class="feature-title">Free Shipping</h4>
                    <p class="mb-0">
                      Free shipping on all Local Area order above $200
                    </p>
                  </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="feature-item feature-info-style-02 feature-info-center">
                  <div class="feature-icon">
                    <object type="image/svg+xml" data="assets/images/svg/support.svg" class="icon "></object>
                  </div>
                  <div class="feature-content">
                    <h4 class="feature-title">24/7 Support</h4>
                    <p class="mb-0">
                      Our Customer Support Team is ready and available to help.
                    </p>
                  </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="feature-item feature-info-style-02 feature-info-center mb-0">
                  <div class="feature-icon">
                    <object type="image/svg+xml" data="assets/images/svg/return.svg" class="icon "></object>
                  </div>
                  <div class="feature-content">
                    <h4 class="feature-title">30 Days Return</h4>
                    <p class="mb-0">
                      Product any fault within 30 days for an exchange.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section> -->
      <!--=================================
      Contact-Info -->

      <!--=================================
      Contact-Info -->
      <br>
      <br>
      <br>
      <section class="space-pb">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="message-info box-shadow">
                <div class="section-title">
                <h2>{{'Contact us' | translate}}</h2>
                <p class="mb-0">{{'Contact us paragraph' | translate}}</p>
                <p>UNITED YAZILIM TEKNOLOJİLERİ ANONİM ŞİRKETİ</p>
              </div>

                <div class="feature-item feature-info-style-03">
                  <div class="feature-icon">
                    <object type="image/svg+xml" data="assets/images/svg/email.svg" class="icon "></object>
                  </div>
                  <div class="feature-content">
                    <h6 class="feature-title">{{'Address' | translate}}</h6>
                    <p class="mb-0">
                        Kavacık Mah. Öge Sk. No: 20/17, Beykoz/İstanbul, Türkiye
                    </p>
                  </div>
                </div>

                <div class="feature-item feature-info-style-03">
                  <div class="feature-icon">
                    <object type="image/svg+xml" data="assets/images/svg/phone.svg" class="icon "></object>
                  </div>
                  <div class="feature-content">
                    <h6 class="feature-title">{{'Phone' | translate}}</h6>
                    <span>+90 850 307 92 77</span>
                  </div>
                </div>

                <div class="feature-item feature-info-style-03 mb-0">
                  <div class="feature-icon">
                    <object type="image/svg+xml" data="assets/images/svg/location.svg" class="icon "></object>
                  </div>
                  <div class="feature-content">
                    <h6 class="feature-title">Email</h6>
                    <span>destek@sarjsepeti.com</span>
                    <!-- <span>letstalk@vehicle.com</span> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-7">
              <div class="contact-us-form">
              <div class="section-title">
                <h2>{{'Contact Form Header' | translate}}</h2>
                <p>{{'Contact Form Paragraph' | translate}}</p>
              </div>
              <form>
                <div class="row">
                  <div class="mb-3 col-md-6">
                    <input type="text" class="form-control" id="first-name" [(ngModel)]="contactModel.name" name="name" placeholder="{{'Your Name' | translate}}">
                  </div>
                  <div class="mb-3 col-md-6">
                    <input type="email" class="form-control" id="email" [(ngModel)]="contactModel.mail" name="mail" placeholder="{{'Your Email' | translate}}">
                  </div>
                  <div class="mb-3 col-md-12">
                    <input type="text" class="form-control" id="phone" [(ngModel)]="contactModel.phone" name="phone" placeholder="{{'Phone Number' | translate}}">
                  </div>
                  <div class="col-lg-12">
                    <textarea class="form-control" rows="5" [(ngModel)]="contactModel.message" name="message" placeholder="{{'Message' | translate}}"></textarea>
                  </div>
                </div>
                <div class="mt-4">
                 <button type="submit" (click)="sendMail()" class="btn btn-primary">{{'Send Message' | translate}}</button>
                 </div>
              </form>
            </div>
           </div>
          </div>
        </div>
      </section>
      <!--=================================
      Contact-Info -->

       <!--=================================
    Map -->
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 p-0">
            <iframe class="w-100 border-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1018.0089442315209!2d29.082922234449526!3d41.094247849773616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caca37c6e72607%3A0xcea51b055b4b289d!2zS2F2YWPEsWssIMOWxJ9lIFNrLiBObzoyMCwgMzQ4MTAgQmV5a296L8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1679997289861!5m2!1str!2str" style="border:0; width: 100%; height: 500px;" allowfullscreen=""></iframe>

          </div>
        </div>
      </div>
    </section>
    <!--=================================
    Map -->
    <section>
      <div>
        <a href="https://wa.me/908503079277" target="_blank" class="whatsapp"><i class="fab fa-whatsapp"></i></a>
        <p class="help-text">{{'Help-Text' | translate}}</p>
      </div>
    </section>
