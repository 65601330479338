import { Component } from '@angular/core';

@Component({
  selector: 'app-kvkk',
  templateUrl: './kvkk.component.html',
  styleUrls: ['./kvkk.component.css']
})
export class KvkkComponent {

}
