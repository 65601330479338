import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, catchError, throwError } from 'rxjs';
import { APP_CONFIG } from 'src/environments/environment';
import { BlogCategoryModel, BlogModel } from '../models/blog/blog-model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) { }
  apiUrl = APP_CONFIG.apiUrl + 'api/Blog/';


  getAll(): Observable<BlogModel[]> {
    return this.http.get<BlogModel[]>(this.apiUrl + 'getAll')
  }

  getCategories(): Observable<BlogCategoryModel[]> {
    return this.http.get<BlogCategoryModel[]>(this.apiUrl + 'getCategories')
  }

  getBlog(id:number): Observable<BlogModel> {
    return this.http.get<BlogModel>(this.apiUrl + 'getBlog?id=' + id)
  }
}
