import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { InvestorComponent } from './investor.component';

const routes:Routes =[
    {
        path:'investor',
        component:InvestorComponent,
    },
    { 
        path: 'yatirimci', 
        component: InvestorComponent 
    }
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class InvestorRoutingModule {}