import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoggedInGuard } from 'src/app/guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'giris',
    component: LoginComponent,
    canActivate: [LoggedInGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
