import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
   showSpinner =false;

   constructor(private loadingService: LoadingService,private cdRef:ChangeDetectorRef ){
    
   }
   ngOnInit(){
    this.init();
   }
   init(){
    this.loadingService.getLoadingObserver().subscribe((status)=>{
      this.showSpinner =(status =='start');
      this.cdRef.detectChanges();
    })
   }
}
