export class CityModel{
    id:number;
    name:string;
}

export class DistrictModel{
    id:number;
    name:string;
    cityId:number;
}

export class AddressesModel{
    cities:CityModel[];
    districts:DistrictModel[];
}