import { Component, OnInit } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BlogService } from 'src/app/services/blog.service';
import { BlogModel } from 'src/app/models/blog/blog-model';
import { Router } from '@angular/router';
declare var $: any; // jQuery için

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
  ]
})
export class HomeComponent implements OnInit {
  allBlogs: BlogModel[] = [];

  constructor(private http: HttpClient,  private blogService: BlogService,private router: Router) {}


  ngOnInit(): void {
    this.getBlogList();
    // $(document).ready(() => {
    //   $('#popupModal').modal('show');
    // });

    $(document).ready(function(){
      $(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1000: {
            items: 5
          }
        }
      });
    });
  }

  // resmeTiklandi(): void {
  //   this.router.navigateByUrl('/yarisma'); // Yönlendirme URL'iniz
  //   // Modalı programatik olarak kapatma
  //   $('#popupModal').modal('hide');
  // }
  getBlogList(): void {
    this.blogService
      .getAll()
      .subscribe((response: BlogModel[]) => {
        this.allBlogs = response;
        this.allBlogs.forEach(element => {
          element.dateTime=element.dateTime.split('T')[0];
          if(element.title=="Elektrikli Araba ve Şarj Sistemlerinin Sağlayacağı Faydalar"){
            element.image="/assets/images/blog/blog.webp"
          }
        });
        console.log(this.allBlogs);
      });
  }

}
